import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Chip, Pagination } from "@mui/material";
import "./style.css";
import { useEffect } from "react";
import { fetchProducerProduct } from "api/api";
import { useState } from "react";
import { useParams } from "react-router";
import Loader from "ui-component/Loader";

export default function ViewProductTable({ setOpen, setIsValid, isValid }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const params = useParams();
  const [data, setData] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);

  const getProductproductData = async () => {
    setLoader(true);
    try {
      const response = await fetchProducerProduct(
        page - 1,
        pageSize,
        params.id
      );
      console.log("res", response.data);
      setData(response.data.data);
      setTotalCount(Math.ceil(response.data.counter / 10));
    } catch (error) {
      console.log("error", error);
    }
    setLoader(false);
  };

  const handlePageChange = (event, pageData) => {
    setPage(pageData);
  };

  useEffect(() => {
    getProductproductData();
  }, [page]);
  useEffect(() => {
    setPage(1);
  }, []);

  return (
    <>
      {loader === true && <Loader />}
      <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Name/Model</TableCell>
              <TableCell align="center">Version/Code</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Tags</TableCell>
              {/* <TableCell align="center">Status</TableCell>
            <TableCell align="center">View Product</TableCell> */}
              {/* <TableCell align="center">View Errors</TableCell> */}
              {/* <TableCell align="right">image_two</TableCell>
            <TableCell align="right">image_three</TableCell>
            <TableCell align="right">image_four</TableCell>
            <TableCell align="right">image_five</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, id) => (
              <TableRow
                key={id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}

                // #ff6969
              >
                <TableCell component="th" scope="row">
                  {id + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.model}</TableCell>
                <TableCell align="center">{row?.category}</TableCell>
                <TableCell align="center">
                  {row?.tags?.map((tag) => (
                    <Chip label={tag} sx={{ marginLeft: "10px" }} />
                  ))}
                </TableCell>
                {/* <TableCell align="center">{"Pending"}</TableCell> */}
                {/* <TableCell align="center">
                <Button variant="outlined" onClick={() => setOpen(true)}>
                  View
                </Button>
              </TableCell> */}
                {/* <TableCell align="right">{row.model}</TableCell>
              <TableCell align="right">{row.model}</TableCell>
              <TableCell align="right">{row.model}</TableCell>
              <TableCell align="right">{row.model}</TableCell> */}
                {/* <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "20px",
        }}
      >
        <Pagination
          count={totalcount}
          color="primary"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
}
