import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import Sample from "views/Sample";
import ConnectionModeration from "views/ConnectionModeration";
import ExportImport from "views/Export-import";
import MessageModeration from "views/MessageModeration";
import ImsFlow from "views/ImsFlow";
import ProductManagement from "views/Product-management";
import ImsProduct from "views/ImsFlow/ImsProduct";
import UnverifiedProducerIndex from "views/Unverifies-producer";
import Request from "views/Export-import/Request";
import EventCreate from "views/Event-management/EventCreate";
import OrganizationCreate from "views/Event-management/OrganizationCreate";
import UpdateEvent from "views/Event-management/UpdateEvent";
import UserLogs from "views/Logs/UserLogs";
import OrganizationLogs from "views/Logs/OrganizationLogs";
import OrgDetails from "views/OrganizationDetails";
import FaqComponent from "views/Faq";
import ProductCategoryUrlList from "views/ProductCategoryUrl";
import ErrorLogs from "views/ErrorLogs";
import ProductImportbyProducer from "views/ProductImport/ProductImportbyProducer";
import SettingIndex from "views/Setting";
import RosterImportcomponent from "views/RosterImport";
import BlockIndex from "views/Block";
import NewswirePostManage from "views/NewswirePost";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));
const UserSetting = Loadable(lazy(() => import("views/user-setting")));
const EventManagement = Loadable(lazy(() => import("views/Event-management")));
const CategoryManagement = Loadable(
  lazy(() => import("views/Category-managemnet"))
);
const FeatureVendorList = Loadable(
  lazy(() => import("views/Feature-vendor-list"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/dashboard",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardDefault />,
    },
    {
      path: "",
      children: [
        {
          path: "",
          element: <DashboardDefault />,
        },
      ],
    },
    // {
    //     path: 'utils',
    //     children: [
    //         {
    //             path: 'util-typography',
    //             element: <UtilsTypography />
    //         }
    //     ]
    // },
    // {
    //     path: 'utils',
    //     children: [
    //         {
    //             path: 'util-color',
    //             element: <UtilsColor />
    //         }
    //     ]
    // },
    // {
    //     path: 'utils',
    //     children: [
    //         {
    //             path: 'util-shadow',
    //             element: <UtilsShadow />
    //         }
    //     ]
    // },
    // {
    //     path: 'icons',
    //     children: [
    //         {
    //             path: 'tabler-icons',
    //             element: <UtilsTablerIcons />
    //         }
    //     ]
    // },
    {
      path: "icons",
      children: [
        {
          path: "material-icons",
          element: <UtilsMaterialIcons />,
        },
      ],
    },
    {
      path: "organization-list",
      element: <SamplePage />,
    },
    {
      path: "user-setting/:userid",
      element: <UserSetting />,
    },
    {
      path: "feature-vendor-list",
      element: <FeatureVendorList />,
    },
    {
      path: "category-management",
      element: <CategoryManagement />,
    },
    {
      path: "organization-import",
      element: <ExportImport />,
    },
    // {
    //   path: "organization-management",
    //   element: <Sample />,
    // },
    {
      path: "message-moderation",
      element: <MessageModeration />,
    },
    // {
    //   path: "user-permission",
    //   element: <Sample />,
    // },
    {
      path: "connection-moderation",
      element: <ConnectionModeration />,
    },
    {
      path: "event/management",
      element: <EventManagement />,
    },
    // {
    //   path: "client/management",
    //   element: <Sample />,
    // },
    // {
    //   path: "vendor/management",
    //   element: <Sample />,
    // },
    {
      path: "product/management",
      element: <ProductManagement />,
    },
    {
      path: "ims-flow-management",
      element: <ImsFlow />,
    },
    {
      path: "ims-product-management",
      element: <ImsProduct />,
    },
    {
      path: "unverified-producer",
      element: <UnverifiedProducerIndex />,
    },
    {
      path: "request",
      element: <Request />,
    },
    {
      path: "event/create",
      element: <EventCreate />,
    },
    {
      path: "event/update",
      element: <UpdateEvent />,
    },
    {
      path: "organization/create",
      element: <OrganizationCreate />,
    },
    {
      path: "logs/user",
      element: <UserLogs />,
    },
    {
      path: "logs/organization",
      element: <OrganizationLogs />,
    },
    {
      path: "organization/details/:id",
      element: <OrgDetails />,
    },
    {
      path: "faq",
      element: <FaqComponent />,
    },
    {
      path: "product/category-url/:orgid",
      element: <ProductCategoryUrlList />,
    },
    {
      path: "Error/logs",
      element: <ErrorLogs />,
    },
    {
      path: "setting",
      element: <SettingIndex />,
    },
    {
      path: "block",
      element: <BlockIndex />,
    },
    {
      path: "product/import/:id",
      element: <ProductImportbyProducer />,
    },
    {
      path: "import/location/product/:id",
      element: <RosterImportcomponent />,
    },
    {
      path: "newswire/manage/post",
      element: <NewswirePostManage />,
    },
  ],
};

export default MainRoutes;
