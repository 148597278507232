import * as React from "react";
import Box from "@mui/material/Box";

import Loader from "../../ui-component/Loader";
import { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

import {
  Avatar,
  ListItemAvatar,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { get_connection } from "api/api";
import { useEffect } from "react";
import "../../ui-component/style.css";

//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(255,105,0)",
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "aliceblue",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ConnectionModerationConnectionListShown({
  setConnectionModal,
  connectionsModal,
  organizationId,
  random,
  page,
  setPage,
}) {
  const [loader, setLodaer] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const [connectionData, setConnectionData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setConnectionModal(false);
    setPage(0);
    setConnectionData([]);
  };

  const handlePagination = (e, v) => {
    setPage(v);
  };

  const getConnectionList = async () => {
    setLodaer(true);
    await get_connection(organizationId, page, pageSize)
      .then((res) => {
        setLodaer(false);
        setConnectionData(res.data.result);
        setCount(Math.ceil(res.data.total_results / 5));
      })
      .catch((err) => {
        setLodaer(false);
      });
  };

  const handleDisconnect = async () => {};

  useEffect(() => {
    // getConnectionList();
  }, [organizationId, page, random]);

  return (
    <div>
      {loader === true && <Loader />}
      <Box>
        {/* <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            variant="h4"
            id="user-detail-modal"
            align="center"
            color={"orange"}
            onClick={() => handleClose()}
            style={{ cursor: "pointer", color: "red", width: "auto" }}
          >
            Close
          </Typography>
        </div> */}
        {/* <h2 align="center">Connection list</h2> */}
        <div className={"paper"}>
          <div className={"section table"}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Image</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Organization Name</StyledTableCell>
                    <StyledTableCell>Mobile Number</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell>Disconnect</StyledTableCell>
                    {/* Add more TableCell components for additional columns */}
                  </TableRow>
                </TableHead>
                <TableBody style={{ overflow: "scroll" }}>
                  {!loader && (
                    <>
                      {connectionData?.map((connection, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            <ListItemAvatar>
                              <Avatar
                                src={connection.image}
                                alt={connection.name}
                              />
                            </ListItemAvatar>
                          </StyledTableCell>
                          <StyledTableCell>{connection.email}</StyledTableCell>
                          <StyledTableCell>
                            {connection.first_name} {connection.last_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {connection.organization_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {connection.mobileNumber}
                          </StyledTableCell>
                          <StyledTableCell>
                            {connection.role_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "inherit",
                                cursor: "pointer",
                                color: "red",
                                fontSize: "15px",
                              }}
                              onClick={() => setOpen(true)}
                            >
                              Disconnect
                            </button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  )}
                </TableBody>
                {/* Add TableBody and TableRow components for table body and data */}
              </Table>
            </TableContainer>
            {!loader && connectionData.length === 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "20%",
                  marginTop: "20px",
                }}
              >
                <span style={{ fontSize: "18px" }}>
                  No connection details found
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Pagination
            count={count}
            color="primary"
            onChange={handlePagination}
          />
        </div>
      </Box>
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle> */}
          <DialogContent style={{ padding: "30px 50px" }}>
            <DialogContentText
              style={{ fontSize: "18px" }}
              id="alert-dialog-description"
            >
              Are you sure to disconnect ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              style={{ color: "red" }}
              onClick={() => handleDisconnect()}
              autoFocus
            >
              Disconnect
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
}
