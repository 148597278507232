import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import Button from "@mui/material/Button";

import "./style.css";
import {
  faqCreate,
  faqQuestionUpdate,
  get_featur_vendor_data,
  update_featur_vendor_date,
} from "api/api";
import Featuretable from "./FeatureTable";
import Loader from "./Loader";
import { toast } from "react-toastify";
import moment from "moment";
import FaqInputFieldComponent from "./FaqInput";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "70%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  overflowX: "hidden",
  overflowY: "hidden",
};

export default function FaqModal({
  setUpdateCreateModalOpen,
  createUpdateModalOpen,
  width,
  modalType,
  id,
  data,
  getFaqData,
}) {
  const [organizationData, setOrganizationData] = React.useState([]);
  const [editDisable, setEditDisable] = React.useState(true);
  const [loader, setLodaer] = React.useState(false);

  const [update, setUpdate] = React.useState(false);

  const [orgType, setOrgType] = React.useState("");

  const [startvalue, setStartValue] = React.useState();
  const [endvalue, setEndValue] = React.useState();

  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  const [priority, setPriority] = React.useState("");

  const handleClose = () => {
    setUpdate(!update);
    setUpdateCreateModalOpen(false);
    setEditDisable(true);
  };

  const getFeaturVendorData = async (organizationid) => {
    setLodaer(true);
    await get_featur_vendor_data(organizationid)
      .then((res) => {
        setOrganizationData(res.data);
        setStartValue(res.data?.startDate);
        setEndValue(res.data?.endDate);
        setOrgType(res.data.type);
      })
      .catch((err) => {});
    setLodaer(false);
  };

  const handleCreateAndUpdateFaq = async () => {
    setLodaer(true);
    if (!question || !answer || !priority) {
      return;
    }

    if (modalType === "create") {
      await faqCreate(question, answer, priority)
        .then((res) => {
          getFaqData();
          toast.success("FAQ question Added", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateCreateModalOpen(false);
        })
        .catch((err) => {
          setLodaer(false);
        });
    }
    if (modalType === "update") {
      await faqQuestionUpdate(id, question, answer, priority)
        .then((res) => {
          getFaqData();

          toast.success("Question Updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateCreateModalOpen(false);
        })
        .catch((err) => {});
    }

    setLodaer(false);
  };

  React.useEffect(() => {
    setOrganizationData(data);
    if (modalType === "update") {
      setQuestion(data?.question);
      setAnswer(data?.answer);
      setPriority(data?.priority ? data?.priority : "");
    }
    if (modalType === "create") {
      setQuestion("");
      setAnswer("");
      setPriority("");
    }
  }, [data, modalType, update]);

  return (
    <div>
      {loader === true && <Loader />}
      <Modal
        open={createUpdateModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h2"
              id="user-detail-modal"
              align="center"
              //   color={"orange"}
              onClick={() => handleClose()}
              style={{
                cursor: "pointer",
                // color: "red",
                width: "auto",
                textTransform: "capitalize",
              }}
            >
              {modalType}
            </Typography>
            <Typography
              variant="h4"
              id="user-detail-modal"
              align="center"
              color={"orange"}
              onClick={() => handleClose()}
              style={{ cursor: "pointer", color: "red", width: "auto" }}
            >
              Close
            </Typography>
          </div>
          <div className={"paper"}>
            <div className={"section table"}>
              {!loader ? (
                <>
                  <FaqInputFieldComponent
                    organizationData={data}
                    setQuestion={setQuestion}
                    setPriority={setPriority}
                    setAnswer={setAnswer}
                    question={question}
                    answer={answer}
                    priority={priority}
                    // setOrgType={setOrgType}
                    // orgType={orgType}
                    type={modalType}
                    width="100%"
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{
                        width: "40%",
                        backgroundColor: "rgb(255,102,0)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      disabled={
                        !question || !answer || !priority ? true : false
                      }
                      onClick={() => handleCreateAndUpdateFaq(id, modalType)}
                    >
                      {modalType === "create" ? "Create" : "Update"}
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <h3>Loading...</h3>
                </div>
              )}

              {/* <div
                style={{
                  width: "100%",
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                }}
              >
                <span>Work In progress</span>
              </div> */}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
