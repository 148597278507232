import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import {
  CategoryUrlproductCreate,
  getProductCategoryUrlData,
  productUpdate,
} from "api/api";
import { useState } from "react";

import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

import { useParams } from "react-router";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  //   height: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  //   columnGap: "10px",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
}));
export default function ProductCategoryCreateModal({
  createModalOpen,
  setCreateModal,
  updated,
  setUpdated,
  orgName,
}) {
  const params = useParams();
  const [imageChange, setChangeImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectCategoryData, setCategorySelectData] = useState();
  const [createData, setProductCategoryurlData] = useState({
    url: "",
    categoryId: "",
  });

  const [image, setImage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProductCategoryurlData({ ...createData, image: file });
    setChangeImage(true);
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage("");
    }
  };

  const handleClose = () => {
    setProductCategoryurlData({
      url: "",
      categoryId: "",
    });
    setUpdated(!updated);
    setCreateModal(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await CategoryUrlproductCreate(createData, params?.orgid);
      toast.success("Product Category Url Created");
      handleClose();
    } catch (error) {
      toast.error("Something went wrong please try again!");
    }
    setLoading(false);
  };
  const getSelectData = async (e) => {
    // e.preventDefault();
    // setLoading(true);
    try {
      const dataa = await getProductCategoryUrlData(params?.orgid);
      console.log("category select data", dataa);
      setCategorySelectData(dataa.data);
      //   setUpdated(!updated);
      //   toast.success("Product Category Url updated");
      //   handleClose();
    } catch (error) {
      toast.error("Something went wrong please try again!");
    }
    // setLoading(false);
  };

  useEffect(() => {
    getSelectData();
  }, [updated, params?.orgid]);

  return (
    <>
      <Modal
        open={createModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          component="form"
          autoComplete="off"
          onSubmit={(e) => handleEditSubmit(e)}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2" color={"grey"}>
              Create
            </Typography>
            <Typography
              variant="h4"
              id="user-detail-modal"
              align="center"
              color={"orange"}
              onClick={() => handleClose()}
              style={{ cursor: "pointer", color: "red", width: "auto" }}
            >
              Close
            </Typography>
          </div>

          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "20px",
              }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  width: "100%",
                }}
              >
                <Grid item xs={12}>
                  <Item>
                    <Typography variant="h4" style={{ marginRight: "10px" }}>
                      {"Organization Name :"}
                    </Typography>
                    <Typography variant="h5">
                      {orgName ? orgName : ""}
                    </Typography>
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "start",
                    }}
                  >
                    <FormLabel
                      htmlFor="catgry"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        fontFamily: "roboto",
                        marginBottom: "10px",
                      }}
                    >
                      Category*
                    </FormLabel>
                    <Select
                      sx={{ marginBottom: "15px" }}
                      labelId="catgry"
                      id="catgry"
                      required
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        setProductCategoryurlData({
                          ...createData,
                          categoryId: e.target.value,
                        })
                      }
                    >
                      {selectCategoryData?.map((category) => (
                        <MenuItem value={category.categoryId}>
                          {category.categoryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Item>
                </Grid>

                <Grid item xs={12}>
                  <Item>
                    <TextField
                      id="outlined-basic"
                      label="URL"
                      variant="outlined"
                      type="url"
                      required
                      value={createData?.url}
                      onChange={(e) =>
                        setProductCategoryurlData({
                          ...createData,
                          url: e.target.value,
                        })
                      }
                      style={{ width: "100%" }}
                    />
                  </Item>
                </Grid>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      marginTop: "30px",
                      color: "white",
                      width: "200px",
                    }}
                  >
                    {loading ? "Creating..." : "Create"}
                  </Button>
                </div>
              </Grid>
            </div>
          </>
        </Box>
      </Modal>
    </>
  );
}
