import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Divider, List, ListItem, ListItemText } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LocationErrorViewModal({
  open,
  setOpen,
  singleProductData,
  errorId,
}) {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Error List"}</DialogTitle>
        <DialogContent style={{ width: "100%" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ width: "100%" }}
          >
            <List style={{ listStyleType: "none" }}>
              {singleProductData?.errorList?.map((errorList, id) => (
                <>
                  <ListItem key={id}>
                    <ListItemText primary={errorList} />
                  </ListItem>
                  <Divider component="li" variant="middle" />
                </>
              ))}
              {errorId && (
                <>
                  <ListItem>
                    <ListItemText primary={errorId?.message} />
                  </ListItem>
                  <Divider component="li" variant="middle" />
                </>
              )}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setOpen(false)}>Disagree</Button> */}
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
