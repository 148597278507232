import React from "react";
import * as XLSX from "xlsx";
import DataViewtable from "./DataViewtable";
import { Alert, Button } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";
import { useState } from "react";
import LocationErrorViewModal from "./LocationErrorViewModal";
import {
  importConsumerLocationData,
  validationForLocationSheet,
} from "api/api";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import { v1 as uuidv1 } from "uuid";
import { useEffect } from "react";
import Loader from "ui-component/Loader";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export default function LocationImport({ data }) {
  const [allData, setAllData] = useState([]);
  const [missingHeadersData, setErrorheaderlist] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [excelValid, setExcelValid] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const params = useParams();
  // modal
  const [open, setModalOpen] = useState(false);
  const [chunkSize, setChunkSize] = useState(20);
  const [rowData, setRowData] = useState([]);

  const [errorId, setErrorId] = useState(null);

  const [loading, setLoading] = useState(false);

  const checkLocationImport = async (data) => {
    setLoading(true);
    await validationForLocationSheet(data, params.id)
      .then((res) => {
        setExcelValid(true);
      })
      .catch((err) => {
        setIsValid(false);
        setExcelValid(false);
        setErrorId(err?.response?.data);
      });
    setLoading(false);
  };

  const handleFileupload = (e) => {
    setLoading(true);
    setErrorheaderlist([]);
    setResponseData([]);
    setErrorId(null);
    setSubmit(false);
    setIsValid(true);
    setExcelValid(false);

    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const parsedData = XLSX.utils.sheet_to_json(sheet);
      // setAllData(parsedData);

      let headers = [];
      let allvalidData = [];

      for (let key in sheet) {
        if (key[0] === "!") continue;
        // parse out the column, row, and value
        const row = parseInt(key.substring(1));
        const value = sheet[key].v;

        // Store the header values
        if (row === 1) {
          headers.push(value);
        }
      }

      const defaultHeaders = [
        "LocationName",
        "ParentLocation",
        "PrimaryContact",
        "CountryCode",
        "PhoneNumber",
        "Address",
        "Direction",
        "DoorCode",
      ];

      const missingHeaders = defaultHeaders.filter(
        (header) => !headers.includes(header)
      );
      if (missingHeaders.length === 0) {
        for (const data of parsedData) {
          let errorList = [];
          let error = false;

          const checkLocationname = () => {
            if (!data.LocationName) {
              errorList.push("Location name required");
              error = true;
              setIsValid(false);
            }
            if (!data.Address) {
              errorList.push("Address is  required");
              error = true;
              setIsValid(false);
            }
            if (!data.PrimaryContact) {
              errorList.push("Primary Contact is  required");
              error = true;
              setIsValid(false);
            }
            if (data?.LocationName?.includes("/")) {
              errorList.push("Remove / from location name");
              error = true;
              setIsValid(false);
            }
            if (data.PhoneNumber) {
              if (isNaN(data.PhoneNumber)) {
                errorList.push("Phone Number is not valid");
                error = true;
                setIsValid(false);
              }
              if (!data.CountryCode) {
                errorList.push("country code missing");
                error = true;
                setIsValid(false);
              }
            }
            if (data.CountryCode) {
              if (!data.PhoneNumber) {
                errorList.push("Phone number missing");
                error = true;
                setIsValid(false);
              }
            }
          };
          checkLocationname();

          allvalidData.push({
            id: uuidv1(),
            name: data?.LocationName ? data?.LocationName : "",
            parentLocation: data.ParentLocation
              ? data.ParentLocation === "none"
                ? ""
                : data.ParentLocation
              : "",
            direction: data.Direction ? data.Direction : "",
            doorCode: data.DoorCode ? data.DoorCode : "",
            primaryContact: data.PrimaryContact ? data.PrimaryContact : "",
            address: data.Address ? data.Address : "",
            CountryCode: data.CountryCode ? data.CountryCode : "",
            phone: data.PhoneNumber
              ? data.CountryCode
                ? typeof data.CountryCode === "string"
                  ? "+" +
                    data.CountryCode?.replace(/['"]+/g, "") +
                    data.PhoneNumber
                  : "+" + data.CountryCode + data.PhoneNumber
                : data.PhoneNumber
              : "",
            errorList: errorList,
            error: error,
          });
        }

        setAllData(allvalidData);
        checkLocationImport(allvalidData);
      } else {
        setErrorheaderlist(missingHeaders);
      }
    };
    setLoading(false);
  };

  const fileSubmit = async (chunkSize) => {
    setSubmit(true);
    // setProgress(0);

    const totalChunks = Math.ceil(allData?.length / chunkSize);
    const responses = [];

    let chunksSent = 0; // Variable to track the number of chunks sent successfully

    // Split data into chunks and send each chunk to the server
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = (i + 1) * chunkSize;
      const chunk = allData.slice(start, end);

      try {
        // Send chunk to the server using Axios
        const response = await importConsumerLocationData(params.id, chunk);
        responses.push(response.data);
        // chunksSent++;
        // const progress = ((i++ / totalChunks) * 100).toFixed(0);
        // console.log("progress progress", progress);
        // setProgress(progress);
      } catch (error) {
        // Handle error if request fails
        console.error("Error sending chunk:", error.message);
        // responses.push({ error: error.message });
      }
    }

    if (responses.length > 0) {
      toast.success("Location Import Success");
    } else {
      toast.error("Location Import failed please try again.");
    }

    setSubmit(false);
    setResponseData(responses.flat(1));
    return responses;
  };

  return (
    <div>
      {submit && <Loader />}
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          padding: "30px",
          rowGap: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            rowGap: "10px",
            columnGap: "10px",
            // flexDirection: "column",
          }}
        >
          {!excelValid ||
          allData?.length === 0 ||
          !isValid ||
          responseData.length > 0 ? (
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              style={{ width: "25%" }}
              color="info"
            >
              Upload Location file
              <VisuallyHiddenInput
                type="file"
                accept=".xls,.xlsx,.csv"
                onChange={handleFileupload}
              />
            </Button>
          ) : !submit && isValid ? (
            <>
              {excelValid && (
                <>
                  <Button
                    component="label1"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<RestartAltIcon />}
                    style={{ width: "20%" }}
                    color="error"
                    onClick={() => setAllData([])}
                  >
                    Reset
                  </Button>
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    style={{ width: "20%" }}
                    color="success"
                    onClick={() => fileSubmit(chunkSize)}
                  >
                    Submit
                  </Button>
                </>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        {responseData.length > 0 && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Here is a gentle confirmation that your action was successful.
          </Alert>
        )}
        {/* {data?.length > 0 && !isValid && (
              <div style={{ marginTop: "10px" }}>
                <Alert severity="error">
                  This Excel sheet has some error please resolve and submit
                  again.
                </Alert>
              </div>
            )}
          */}
        {missingHeadersData.length > 0 && (
          <div style={{ marginTop: "10px" }}>
            <Alert severity="error">
              Headers missing{" "}
              {missingHeadersData?.map((error) => (
                <strong>{error} ,</strong>
              ))}
              please correct and submit again.
            </Alert>
          </div>
        )}
        {errorId && (
          <div style={{ marginTop: "10px" }}>
            <Alert severity="error">{errorId?.message}</Alert>
          </div>
        )}
        {/* {submit && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={progress}
                    variant="buffer"
                    valueBuffer={10}
                  />
                </Box>
              </div>
            )} */}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        {/* <h4>Total : {rawDataCount}</h4>
            <div style={{ display: "flex", width: "40%", columnGap: "10px" }}>
              {duplicateData?.length > 0 && (
                <>
                  <h4 style={{ color: "#ff6666" }}>
                    Already Exists : {duplicateData?.length}
                  </h4>
                  <h4 style={{ color: "#3fec3f" }}>
                    New : {rawDataCount - duplicateData?.length}
                  </h4>
                </>
              )}
            </div> */}
      </div>

      <DataViewtable
        data={allData}
        setModalOpen={setModalOpen}
        setRowData={setRowData}
        loading={loading}
        errorId={errorId}
      />

      <LocationErrorViewModal
        open={open}
        setOpen={setModalOpen}
        singleProductData={rowData}
        errorId={errorId}
      />
    </div>
  );
}
