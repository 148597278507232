import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
export default function DateTimeForCreateEvent({
  label,
  setStartDateAndtime,
  start,
  end,
  setEndDateAndtime,
  editDisabled,
  defaultValue,
  disablePast,
  required,
  startDate,
  minStartDate,
  setMinDateForCreate,
}) {
  const [value, setValue] = React.useState();

  const HandleDateAndTime = (e, value) => {
    console.log("handle change ", e);
    const utc = new Date(e).toJSON();

    //
    const dateString = e.$d;

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var localDateString = utc.toLocaleString("en-US", { timeZone: timeZone }); // Change 'America/New_York' to your local time zone

    const dateObject = new Date(dateString).toISOString();

    if (start) {
      setMinDateForCreate(dateString);
      console.log("start Date set", dateString);
      setStartDateAndtime(dateString);
    }
    if (end) {
      setEndDateAndtime(dateString);
    }
  };
  console.log("startDate", startDate);

  useEffect(() => {
    if (end && defaultValue) {
      setValue(dayjs(defaultValue));
    }
  }, [defaultValue]);
  console.log("minStartDate", minStartDate);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker"]}>
        {start && (
          <DateTimePicker
            //   disabled={defaultValue ? false : end ? true : false}
            value={value}
            minDate={dayjs(new Date())}
            // value={start || end ? null : value}
            onChange={(e) => HandleDateAndTime(e)}
            label={label}

            // InputProps={{ inputProps: { type: "datetime-local" } }}
          />
        )}
        {end && (
          <DateTimePicker
            disabled={minStartDate ? false : true}
            value={value}
            minDate={dayjs(minStartDate)}
            // value={start || end ? null : value}
            onChange={(e) => HandleDateAndTime(e)}
            label={label}

            // InputProps={{ inputProps: { type: "datetime-local" } }}
          />
        )}
      </DemoContainer>
    </LocalizationProvider>
  );
}
