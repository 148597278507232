import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Loader from "./Loader";
import { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

import {
  Alert,
  Avatar,
  ListItemAvatar,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { get_connection } from "api/api";
import { useEffect } from "react";
import "./style.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  overflowX: "hidden",
  overflowY: "hidden",
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(255,105,0)",
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "aliceblue",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ConnectionList({
  setConnectionModal,
  connectionsModal,
  organizationId,
  random,
}) {
  const [loader, setLodaer] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(5);
  const [connectionData, setConnectionData] = React.useState([]);
  const [count, setCount] = React.useState(0);

  const handleClose = () => {
    setConnectionModal(false);
    setPage(1);
    setConnectionData([]);
  };

  const handlePagination = (e, v) => {
    setPage(v);
  };

  const getConnectionList = async () => {
    setLodaer(true);
    await get_connection(organizationId, page, pageSize)
      .then((res) => {
        setLodaer(false);
        setConnectionData(res.data.data);
        setCount(Math.ceil(res.data.count / 5));
      })
      .catch((err) => {
        setLodaer(false);
      });
  };

  useEffect(() => {
    if (organizationId) {
      getConnectionList();
    }
  }, [organizationId, page, random]);

  return (
    <div>
      {loader === true && <Loader />}
      <Modal
        open={connectionsModal}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              variant="h4"
              id="user-detail-modal"
              align="center"
              color={"orange"}
              onClick={() => handleClose()}
              style={{ cursor: "pointer", color: "red", width: "auto" }}
            >
              Close
            </Typography>
          </div>
          <h2 align="center">Connection list</h2>
          <div className={"paper"}>
            <div className={"section table"}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Image</StyledTableCell>
                      {/* <StyledTableCell>Email</StyledTableCell> */}
                      <StyledTableCell>Name</StyledTableCell>
                      {/* <StyledTableCell>Organization Name</StyledTableCell> */}
                      <StyledTableCell>Active</StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      {/* Add more TableCell components for additional columns */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loader && (
                      <>
                        {connectionData?.map((connection, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              <ListItemAvatar>
                                <Avatar
                                  src={connection.image}
                                  alt={"profile"}
                                />
                              </ListItemAvatar>
                            </StyledTableCell>
                            {/* <StyledTableCell>
                              {connection.email}
                            </StyledTableCell> */}
                            <StyledTableCell>{connection.name}</StyledTableCell>
                            {/* <StyledTableCell>
                              {connection.organization_name}
                            </StyledTableCell> */}
                            <StyledTableCell>
                              {connection.active === true ? "True" : "False"}
                            </StyledTableCell>
                            <StyledTableCell>{connection.role}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                  {/* Add TableBody and TableRow components for table body and data */}
                </Table>
                {connectionData.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Pagination
                      count={count}
                      color="primary"
                      onChange={handlePagination}
                    />
                  </div>
                )}
              </TableContainer>
              {!loader && connectionData.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "20%",
                  }}
                >
                  <span style={{ fontSize: "18px" }}>
                    No connection details found
                  </span>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
