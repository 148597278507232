import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Divider, List, ListItem, ListItemText } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductViewModal({ open, setOpen, singleProductData }) {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  console.log("singleProductData", singleProductData);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Error List"}</DialogTitle>
        <DialogContent style={{ width: "100%" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ width: "100%" }}
          >
            <List style={{ listStyleType: "none" }}>
              {singleProductData?.errorList?.map((errorList, id) => (
                <>
                  <ListItem key={id}>
                    <ListItemText primary={errorList} />
                  </ListItem>
                  <Divider component="li" variant="middle" />
                </>
              ))}
              {/* {singleProductData?.errorList?.map((errorList) => ( */}
              <>
                {singleProductData?.tags?.length === 0 && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={`Select at least one of the following: 
                        Installs, Sells,Rents, Makes, Integrates,
                         Repairs, or Consults`}
                        color="error"
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                  </>
                )}
                {(singleProductData?.name?.length < 2 ||
                  !singleProductData?.name) && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={"Name/Model must be 2 characters "}
                        color="error"
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                  </>
                )}
                {singleProductData?.name?.length > 50 && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={"Name/Model not more than 50 characters "}
                        color="error"
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                  </>
                )}
                {singleProductData?.modelNumber?.length === undefined && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={"Version/Code must be 2 characters "}
                        color="error"
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                  </>
                )}
                {singleProductData?.modelNumber?.length > 50 && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={"Version/Code not more than 50 characters "}
                        color="error"
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                  </>
                )}
                {singleProductData?.description?.length > 2500 && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={"Description not more than 2500 characters "}
                        color="error"
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                  </>
                )}
                {/* {!singleProductData?.description && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={"Description not more than 2500 characters "}
                        color="error"
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                  </>
                )} */}
                {singleProductData?.summary?.length > 100 && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={"Summary not more than 100 characters "}
                        color="error"
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                  </>
                )}
                {/* {!singleProductData?.summary && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={"Summary not more than 100 characters "}
                        color="error"
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                  </>
                )} */}
              </>
              {/* ))} */}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setOpen(false)}>Disagree</Button> */}
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
