import { TextField } from "@mui/material";
import { getUnverifiedProducer } from "api/api";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const headers = [
  { label: "Event Name", key: "eventName" },
  { label: "Event Category", key: "eventCategory" },
  { label: "Event Description", key: "eventDescription" },
  { label: "Start Date", key: "startDateTime" },
  { label: "End Date", key: "endDateTime" },
  { label: "Timezone", key: "eventTimeZone" },
  { label: "Event Privacy", key: "eventPrivacy" },
  { label: "Event Mode", key: "eventMode" },
];

export default function UnverifiedProducerTable({
  setOpenModal,
  setEventId,
  setEditModal,
  updated,
  setUpdated,
  //
  getIMSproductlookupData,
  setUnverifiedId,
  setVerifyModal,
}) {
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);

  const [pending, setPending] = React.useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [searcgOption, setSearchOption] = useState([]);
  const [searcValueByuser, setSearchValueByuser] = useState("");

  const SearchHandle = async (e) => {
    if (e !== 0) {
      await getIMSproductlookupData(e)
        .then((res) => {
          setSearchOption(res.data);
        })
        .catch((err) => {});
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const HandleApplyFilter = async () => {
    setPending(true);

    await getUnverifiedProducer(searcValueByuser, page, perPage)
      .then((res) => {
        setData(res.data.data);
        setTotalRows(res.data.count);
      })
      .catch((err) => {});
    setPending(false);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div
        style={
          {
            //   width: "100%",
            //   display: "flex",
            //   //   justifyContent: "space-between",
            //   alignItems: "center",
          }
        }
      >
        <div
          style={{ display: "flex", columnGap: "30px", alignItems: "center" }}
        >
          {/* <Input
            placeholder="Search by name"
            style={{
              display: "flex",
              width: "400px",
              justifyContent: "flex-start",
            }}
          /> */}
          <TextField
            id="outlined-basic"
            label="Search by organization name"
            variant="outlined"
            style={{
              display: "flex",
              width: "400px",
              justifyContent: "flex-start",
            }}
            onChange={(e) => {
              setSearchValueByuser(e.target.value);
            }}
          />

          {/* onChange={(e) => {
              setSearchValueByuser(e.target.outerText);
            }} */}
          {/* style={{
              display: "flex",
              width: "400px",
              justifyContent: "flex-start",
            }} */}

          <Button
            variant="contained"
            onClick={(searcValueByuser) => HandleApplyFilter()}
            style={{ backgroundColor: "rgb(255, 102, 0)", color: "white" }}
          >
            Search
          </Button>
        </div>
      </div>
    );
  }, [SearchHandle, searcValueByuser]);

  const handleDateTime = (value) => {
    // const date = new Date(value);
    // const date = moment.tz(value, moment.tz.guess());

    const date = moment(value);
    const formattedDate = date.utc().format("DD-MMM-YY HH:mm");

    return formattedDate;
    // return moment(date).format("DD-MMM-YY HH:mm");
  };

  const actionsMemo = React.useMemo(() => {
    return (
      <CSVLink data={data} headers={headers} filename={"events"}>
        <Button
          variant="outlined"
          style={{
            padding: "5px 20px",
            // border: "none",
            backgroundColor: "",
          }}
          // disabled={
          //   loader ? true : exportData.length > 0 ? false : true
          // }
        >
          Export Events
        </Button>
      </CSVLink>
    );
  }, []);

  const ImageShow = (url) => {
    return (
      <div>
        <img
          src={url}
          alt="unverified-producer"
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />
      </div>
    );
  };

  useEffect(() => {
    setColumns([
      {
        name: "Image",
        selector: (row) => ImageShow(row.organizationProfileImage),
        sortable: false,
      },
      {
        name: "Organization Name",
        selector: (row) => row.organizationName,
        sortable: true,
      },

      {
        name: "Created At",
        selector: (row) => handleDateTime(row.createdAt),
        sortable: true,
      },

      {
        name: "View",
        cell: (row) => (
          // <Button
          //   variant="outlined"
          //   onClick={() => {
          //     setOpenModal(true);
          //     setUnverifiedId(row.organizationId);
          //   }}
          // >
          <RemoveRedEyeIcon
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenModal(true);
              setUnverifiedId(row.organizationId);
            }}
          />
          // </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: "Accept to verify",
        cell: (row) => (
          <Button
            variant="contained"
            color="success"
            style={{ color: "white" }}
            onClick={() => {
              // setOpenModal(true);
              setUnverifiedId(row.organizationId);
              setVerifyModal(true);
            }}
          >
            Accept
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },

      //   {
      //     name: "Edit",
      //     cell: (row) => (
      //       <Button
      //         variant="contained"
      //         onClick={() => {
      //           setEditOpenModal(true);
      //           setUnverifiedId(row.imsProductId);
      //         }}
      //       >
      //         Edit
      //       </Button>
      //     ),
      //     ignoreRowClick: true,
      //     allowOverflow: true,
      //     button: true,
      //   },
    ]);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    HandleApplyFilter();
  }, [page, perPage, updated]);

  return (
    <>
      <DataTable
        title={
          <p style={{ fontFamily: "Roboto,sans-serif", fontWeight: "400" }}>
            {"Unverified Producer "}
          </p>
        }
        columns={columns}
        data={data}
        progressPending={pending}
        pagination
        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        fixedHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        // actions={actionsMemo}
      />
    </>
  );
}
