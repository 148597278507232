import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { fetchConsumerLocationData } from "api/api";
import { useParams } from "react-router";
import { useState } from "react";
import Loader from "ui-component/Loader";

export default function LocationViewtable({ data, setModalOpen, setRowData }) {
  const params = useParams();

  const [locationData, setLocationData] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [loader, setLoader] = useState(false);

  const handleModalOpen = (rowdata) => {
    setRowData(rowdata);
    setModalOpen(true);
  };

  const getLocationData = async () => {
    setLoader(true);
    try {
      const response = await fetchConsumerLocationData(
        params.id,
        page - 1,
        pageSize
      );
      console.log("location view data", response.data);

      setLocationData(response.data.consumerProduct);
      setTotalCount(Math.ceil(response.data.counter / pageSize));
    } catch (error) {
      console.log("location view error", error);
    }
    setLoader(false);
  };

  const handlePageChange = (event, pageData) => {
    setPage(pageData);
  };

  useEffect(() => {
    getLocationData();
  }, [params.id, page]);

  return (
    <>
      {loader && <Loader />}
      <>
        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Parent Location Name</TableCell>
                <TableCell>Location Name</TableCell>
                <TableCell align="center">Address</TableCell>
                {/* <TableCell align="center">Contact</TableCell> */}
                <TableCell align="center">Direction</TableCell>
                {/* <TableCell align="center">Country Code</TableCell> */}
                <TableCell align="center">Phone Number</TableCell>
                <TableCell align="center">Door Code</TableCell>
                {/* <TableCell align="center">Valid/Error</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {locationData &&
                locationData?.map((row, id) => (
                  <TableRow
                    key={id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {id + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span style={{ marginTop: "15px" }}>
                        {row?.parentLocation ? row?.parentLocation : "none"}
                        {/* ?.replace(/\$/g, "") */}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span style={{ marginTop: "15px" }}>
                        {row?.locationName?.locationName}
                        {/* ?.replace(/\$/g, "") */}
                      </span>
                    </TableCell>
                    <TableCell align="center">{row?.address}</TableCell>
                    {/* <TableCell align="center">
                        {row?.primaryContact ? row?.primaryContact : "Null"}
                      </TableCell> */}
                    <TableCell align="center">{row?.direction}</TableCell>
                    {/* <TableCell align="center">
                  {row.CountryCode?.replace(/['"]+/g, "")}
                </TableCell> */}
                    <TableCell align="center">{row?.phone}</TableCell>
                    <TableCell align="center">{row?.doorCode}</TableCell>
                    <TableCell align="center">
                      {/* {row.error ? (
                      <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleModalOpen(row)}
                      >
                      View Error
                      </Button>
                    ) : (
                      <span style={{ color: "green" }}>Valid</span>
                )} */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <Pagination
            count={totalcount}
            color="primary"
            onChange={handlePageChange}
          />
        </div>
      </>
    </>
  );
}
