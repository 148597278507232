import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, Grid } from "@mui/material";
import { useEffect } from "react";
import { getUnverifiedProducerViewData } from "api/api";
import { useState } from "react";

import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "80%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  overflowX: "hidden",
  overflowY: "scroll",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  columnGap: "10px",
  justifyContent: "flex-start",
  alignItems: "center",
}));
export default function UnverifiedViewModal({
  setOpenModal,
  openModal,
  unverifiedId,
}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
    setLoading(false);
  };

  const getEventDetailsById = async (unverifiedId) => {
    setLoading(true);
    await getUnverifiedProducerViewData(unverifiedId)
      .then((res) => {
        setData(res.data.organization);
      })
      .catch((err) => {});
    setLoading(false);
  };

  const handleDateTime = (value) => {
    const date = moment(value);
    const formattedDate = date.format("DD-MMM-YY");
    // const formattedDate = date.utc().format("DD-MMM-YY HH:mm");
    return formattedDate;
  };

  useEffect(() => {
    if (openModal) {
      getEventDetailsById(unverifiedId);
    }
  }, [openModal]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" autoComplete="off">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Unverified Producer Details</h2>
            <Typography
              variant="h4"
              id="user-detail-modal"
              align="center"
              color={"orange"}
              onClick={() => handleClose()}
              style={{ cursor: "pointer", color: "red", width: "auto" }}
            >
              Close
            </Typography>
          </div>
          {!loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",

                columnGap: "10px",
              }}
            >
              <div style={{ width: "40%" }}>
                <img
                  src={data?.profileImage}
                  alt="org"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <Grid container style={{ marginTop: "20px" }}>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Organization Name : </p>
                    <p>{data?.name}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Role : </p>
                    <p>{data?.role}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Verified : </p>
                    <p>{data?.verified === true ? "True" : "False"}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> Created : </p>
                    <p>{handleDateTime(data?.createdAt)}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> About : </p>
                    <p>{data?.about ? data?.about : "Not Specified"}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> Employee Size : </p>
                    <p>
                      {data?.employeeSize
                        ? data?.employeeSize
                        : "Not Specified"}
                    </p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> Sub type : </p>
                    <p>{data?.subtype ? data?.subtype : "Not Specified"}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> Year In Business : </p>
                    <p>
                      {data?.yearInBusiness
                        ? data?.yearInBusiness
                        : "Not Specified"}
                    </p>
                  </Item>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}
