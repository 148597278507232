import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export default function ListConnectionModeration({
  type,
  data,
  clearData,
  setLabelOne,
  setLabelTwo,
  setSelectValueOne,
  setSelectValueTwo,
}) {
  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",
        maxHeight: 300,
        "& ul": { padding: 0 },
        top: "55px",
        zIndex: "9999",
      }}
      subheader={<li />}
    >
      {type === "first" ? (
        data.length > 0 ? (
          data?.map((item, sectionId) => (
            <li key={`section-${sectionId}`}>
              {console.log("item value", item)}
              <ul>
                <ListItem key={`item-${sectionId}`}>
                  <ListItemText
                    style={{ width: "100%", cursor: "pointer" }}
                    primary={`${item.organizationName}`}
                    onClick={() => {
                      setSelectValueOne(item);
                      setLabelOne(item.organizationName);
                      clearData([]);
                    }}
                  />
                </ListItem>
              </ul>
            </li>
          ))
        ) : (
          <li>
            <ul>
              <ListItem>
                <ListItemText primary={`No Organization found`} />
              </ListItem>
            </ul>
          </li>
        )
      ) : type === "second" ? (
        data.length > 0 ? (
          data?.map((item, sectionId) => (
            <li key={`section-${sectionId}`}>
              <ul>
                <ListItem key={`item-${sectionId}`}>
                  <ListItemText
                    style={{ width: "100%", cursor: "pointer" }}
                    primary={`${item.organizationName}`}
                    onClick={() => {
                      setSelectValueTwo(item);
                      setLabelTwo(item.organizationName);
                      clearData([]);
                    }}
                  />
                </ListItem>
              </ul>
            </li>
          ))
        ) : (
          <li>
            <ul>
              <ListItem>
                <ListItemText primary={`No Organization found`} />
              </ListItem>
            </ul>
          </li>
        )
      ) : null}
    </List>
  );
}
