import React from "react";
import { useState } from "react";
import RequestAcceptModal from "ui-component/OrgainzationImport/AcceptModal";
import RequestTable from "ui-component/OrgainzationImport/ReuestTable";
import UnverifiedProducerTable from "ui-component/UnverifiedProducer/Table";
import UnverifiedViewModal from "ui-component/UnverifiedProducer/UnverifiedViewModal";
import VerifyAccept from "ui-component/UnverifiedProducer/VerifyAccept";

export default function Request() {
  const [openModal, setOpenModal] = useState(false);
  const [unverifiedId, setUnverifiedId] = useState();
  const [updated, setUpdated] = useState(false);
  const [openVerifyModal, setVerifyModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  return (
    <div>
      <RequestTable
        openModal={openModal}
        setOpenModal={setOpenModal}
        updated={updated}
        setUnverifiedId={setUnverifiedId}
        setVerifyModal={setVerifyModal}
        setUserEmail={setUserEmail}
      />

      {/* <UnverifiedViewModal
        unverifiedId={unverifiedId}
        openModal={openModal}
        setOpenModal={setOpenModal}
      /> */}

      <RequestAcceptModal
        openVerifyModal={openVerifyModal}
        setVerifyModal={setVerifyModal}
        id={unverifiedId}
        updated={updated}
        setUpdated={setUpdated}
        userEmail={userEmail}
      />
    </div>
  );
}
