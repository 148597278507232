import React from "react";

import "./orgStyle.css";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { FetchOrgDetailsList } from "api/api";
import { useState } from "react";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function OrgDetails() {
  const params = useParams();
  const [orgData, setOrgData] = useState();
  const { state } = useLocation();

  console.log("data", state);

  const profileImage =
    "https://www.pngall.com/wp-content/uploads/5/Profile-PNG-Images.png";

  const fetchOrgDetails = async () => {
    await FetchOrgDetailsList(params.id)
      .then((res) => {
        setOrgData(res.data);
        console.log("fetch org details response", res);
      })
      .catch((err) => {
        console.log("fetch org details err", err);
      });
  };

  useEffect(() => {
    fetchOrgDetails();
  }, [params.id]);

  return (
    <div>
      <div className="backDiv">
        <Link
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
          to={`${state?.url}`}
        >
          <KeyboardBackspaceIcon /> Back
        </Link>
      </div>
      <div class="container">
        <div className="leftContainer">
          <div className="imageContainer">
            <Avatar
              src={orgData?.profileImage}
              className="OrgImage"
              alt={"profile"}
            />
            {/* <img
              src={orgData?.profileImage ? orgData?.profileImage : profileImage}
              className="OrgImage"
              alt="org"
            /> */}
          </div>
        </div>
        <div className="rightContainer">
          <div className="OrgheaderContainer">
            <h1 className="orgHeader">{orgData?.name}</h1>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <div className="OrgDetailsContainerLeft">
              <div class="section">
                <div class="section-title">
                  <span className="subheader">Name :</span>
                  <span class="value">{orgData?.name}</span>
                </div>
                {/* <div class="details">
              <ul className="orgUL">
                <li className="orgLI">Veder: Rahul Thor</li>
                <li className="orgLI">Pending USGT: 2</li>
              </ul>
            </div> */}
              </div>
              <div class="section">
                <div class="section-title">
                  <span className="subheader">Role :</span>
                  <span class="value">{orgData?.role}</span>
                </div>
                {/* <div class="details">
              <span>Verified</span>
            </div> */}
              </div>
              <div class="section">
                <div class="section-title">
                  <span className="subheader">Connections :</span>
                  <span class="value">{orgData?.totalConnections}</span>
                </div>
              </div>
              <div class="section">
                <div class="section-title">
                  <span className="subheader">Events :</span>
                  <span class="value">{orgData?.totalEvent}</span>
                </div>
              </div>
              <div class="section">
                <div class="section-title">
                  <span className="subheader">Users :</span>
                  <span class="value">{orgData?.totalUsers}</span>
                </div>
              </div>
              <div class="section">
                <div class="section-title">
                  <span className="subheader">OwnerShip :</span>
                  <span class="value">{orgData?.ownership}</span>
                </div>
              </div>

              <div class="section">
                <div class="section-title">
                  <span className="subheader">Sponsor :</span>
                  <span class="value">
                    {orgData?.isSponsor === true ? "True" : "False"}
                  </span>
                </div>
              </div>
            </div>
            <div className="OrgDetailsContainerRight">
              <div class="section">
                <div class="section-title">
                  <span className="subheader">Verified :</span>
                  <span class="value">
                    {orgData?.verified === true ? "True" : "False"}
                  </span>
                </div>
                {/* <div class="details">
              <ul className="orgUL">
                <li className="orgLI">Veder: Rahul Thor</li>
                <li className="orgLI">Pending USGT: 2</li>
              </ul>
            </div> */}
              </div>
              <div class="section">
                <div class="section-title">
                  <span className="subheader">Employee Size :</span>
                  <span class="value">{orgData?.employeeSize}</span>
                </div>
              </div>
              <div class="section">
                <div class="section-title">
                  <span className="subheader">Year In Business :</span>
                  <span class="value">{orgData?.yearInBusiness}</span>
                </div>
                {/* <div class="details">
              <span>Verified</span>
            </div> */}
              </div>

              <div class="section">
                <div class="section-title">
                  <span className="subheader">Postal Code :</span>
                  <span class="value">{orgData?.postalCode}</span>
                </div>
              </div>
              <div class="section">
                <div class="section-title">
                  <span className="subheader">Phone Number :</span>
                  <span class="value">{orgData?.mainPhoneNumber}</span>
                </div>
              </div>

              <div class="section">
                <div class="section-title">
                  <span className="subheader">City :</span>
                  <span class="value">{orgData?.city}</span>
                </div>
              </div>

              <div class="section">
                <div class="section-title">
                  <span className="subheader">Country :</span>
                  <span class="value">{orgData?.country}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgDetails;
