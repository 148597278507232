// project imports
import config from "config";

// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  sells: {
    name: null,
    categoryIdList: [],
  },
  consults: { name: null, categoryIdList: [] },
  makes: { name: null, categoryIdList: [] },
  repairs: { name: null, categoryIdList: [] },
  integrates: { name: null, categoryIdList: [] },
  installs: { name: null, categoryIdList: [] },
  rents: { name: null, categoryIdList: [] },
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const CategoryEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Sells":
      // id = action.id;
      return {
        ...state,
        // isOpen: [id],
        sells: {
          ...state.sells,
          name: "Sells",
          categoryIdList: action.personName?.map((d) => d.id),
        },
      };
    case "Consults":
      return {
        ...state,
        consults: {
          ...state.consults,
          name: "Consults",
          categoryIdList: action.personName?.map((d) => d.id),
        },
      };
    case "Makes":
      return {
        ...state,
        makes: {
          ...state.makes,
          name: "Makes",
          categoryIdList: action.personName?.map((d) => d.id),
        },
      };
    case "Repairs":
      return {
        ...state,
        repairs: {
          ...state.repairs,
          name: "Repairs",
          categoryIdList: action.personName?.map((d) => d.id),
        },
      };
    case "Installs":
      return {
        ...state,
        installs: {
          ...state.installs,
          name: "Installs",
          categoryIdList: action.personName?.map((d) => d.id),
        },
      };
    case "Integrates":
      return {
        ...state,
        integrates: {
          ...state.integrates,
          name: "Integrates",
          categoryIdList: action.personName?.map((d) => d.id),
        },
      };
    case "Rents":
      return {
        ...state,
        rents: {
          ...state.rents,
          name: "Rents",
          categoryIdList: action.personName?.map((d) => d.id),
        },
      };
    default:
      return state;
  }
};

export default CategoryEditReducer;
