import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import LoaderforRoster from "views/ProductImport/LoaderforRoster";
import { useState } from "react";
import { useEffect } from "react";

export default function DataViewtable({
  data,
  setModalOpen,
  setRowData,
  loading,
  errorId,
}) {
  const [sortedData, setSortedData] = useState([]);

  const handleModalOpen = (rowdata) => {
    setRowData(rowdata);
    setModalOpen(true);
  };

  const sortData = (data) => {
    return data.sort((a, b) => {
      return (b.error ? 1 : 0) - (a.error ? 1 : 0);
    });
  };

  useEffect(() => {
    if (data) {
      const sorted = sortData(data);
      setSortedData(sorted);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <LoaderforRoster />
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Parent Location Name</TableCell>
                <TableCell>Location Name</TableCell>
                <TableCell align="center">Address</TableCell>
                <TableCell align="center">Contact</TableCell>
                <TableCell align="center">Direction</TableCell>
                {/* <TableCell align="center">Country Code</TableCell> */}
                <TableCell align="center">Phone Number</TableCell>
                <TableCell align="center">Door Code</TableCell>
                <TableCell align="center">Valid/Error</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.map((row, id) => (
                <TableRow
                  key={id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {id + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <span style={{ marginTop: "15px" }}>
                      {row.parentLocation === "none"
                        ? "none"
                        : row.parentLocation === ""
                        ? "none"
                        : row.parentLocation?.replace(/\$/g, "")}
                    </span>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <span style={{ marginTop: "15px" }}>
                      {row.name}
                      {/* ?.replace(/\$/g, "") */}
                    </span>
                  </TableCell>
                  <TableCell align="center">{row.address}</TableCell>
                  <TableCell align="center">{row.primaryContact}</TableCell>
                  <TableCell align="center">{row.direction}</TableCell>
                  {/* <TableCell align="center">
                {row.CountryCode?.replace(/['"]+/g, "")}
              </TableCell> */}
                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">{row.doorCode}</TableCell>
                  <TableCell align="center">
                    {row.error || errorId?.id === row.id ? (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleModalOpen(row, errorId)}
                      >
                        View Error
                      </Button>
                    ) : (
                      <span style={{ color: "green" }}>Valid</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
