import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormControl";

import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import {
  EventTimeZone,
  createEventByAdmin,
  getContactNameData,
  getCountryData,
  getOrganizationNameBySearch,
  uploadEventImage,
} from "api/api";
import { useEffect } from "react";
import { useState } from "react";
import DatePickerValue from "ui-component/DatePicker";
import TimePickerComponents from "ui-component/TimePicker";
import DateAndTimePicker from "ui-component/DateAndTimePicker";
import { Autocomplete } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment-timezone";

import { countryData } from "./country";
import { stateData } from "./state";
import { styled } from "@mui/material/styles";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const UpdateEvent = () => {
  const [countryAllData, setCountryAllData] = useState(countryData);
  const [stateAllData, setStateAllData] = useState();
  const [countrySelectData, setCountrySelect] = useState("");
  const [selectStateByuser, setStateSelectByUser] = useState("");
  const [image, setImage] = useState();

  //
  const [timezones, setTimeZones] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  // const [currentTimeZone,setCurrentTimeZone] = useState("");
  const [eventMode, setEventMode] = useState("Public");
  const [eventType, setEventType] = useState("Online");
  const [platform, setPlatform] = useState("");
  const [passcode, setPasscode] = useState("");
  const [location, setLocation] = useState("");
  const [organizationName, setOrganizationName] = useState([]);

  const [contactName, setContactName] = useState([]);
  const [contactValue, setContactValue] = useState("");
  const [previewImage, setPreviewImage] = useState();

  const [organizationNameSelectByuser, setOrganizationNameSelectedByUser] =
    useState();

  const [eventName, setEventName] = useState("");
  const [evnetShortDesc, setEventShortDesc] = useState("");
  const [eventprivacy, setEventPrivacy] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [startTimeAndDate, setStartDateAndtime] = useState("");
  const [endTimeAndDate, setEndDateAndtime] = useState("");
  const [eventOnlineLink, setEventOnlineLink] = useState("");
  const [conatctNumber, setConatactNumber] = useState("");
  const [conatctName, setConatactName] = useState("");
  const [eventEmail, setEventEmail] = useState("");
  const [evnetWebsiteLink, setEventWebsiteLink] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventCategoryOthers, setEventOtherCategory] = useState("");
  const [eventSubline, setEventSubline] = useState("");
  const [startDateTime, setStartTimeAndDate] = useState("");
  const [selectOffset, setSelectoffset] = useState();
  const [selectType, setType] = useState("start");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [addressThree, setAddressThree] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");

  const getTimeZone = async () => {
    await EventTimeZone()
      .then((res) => {
        console.log("timezone api", res.data);
        setTimeZones(res.data);
      })
      .catch((err) => {
        console.log("time zone api error", err);
      });
  };

  const handleCountrySelect = (e) => {
    setStateAllData();
    const value = e.target.value;
    const filterCountryCode = countryAllData?.filter(
      (country) => country.isoCode === value
    );
    setCountrySelect(filterCountryCode[0]?.name);

    const filterState = stateData?.filter(
      (state) => state.countryCode === value
    );
    const finalStateData = filterState
      ?.slice()
      .sort((a, b) => a.name.localeCompare(b.name));
    setStateAllData(finalStateData);
  };

  const handleStateSelect = (e) => {
    console.log("state select by user", e.target.value);
    setStateSelectByUser(e.target.value);
  };

  function convertToUTC(dateString, timezone) {
    const localTime = moment.tz(dateString, "MM/DD/YYYY HH:mm", timezone);
    return localTime.utc().format();
  }

  function getTimeZoneById(id) {
    for (const timeZone of timezones) {
      if (timeZone.id === id) {
        return timeZone.timeZone;
      }
    }
  }

  const _eventCategoryList = [
    "Trade Show / Conference",
    "Product Release",
    "Product Training",
    "Product Showcase",
    "Project Showcase / Site Tour",
    "RPF Meeting /  Site Walk-Through",
    "Party / Social Event",
    "Other",
  ];

  function convertDateFormat(inputDateString) {
    const originalDate = new Date(inputDateString);

    const day = originalDate.getDate().toString().padStart(2, "0");
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const year = originalDate.getFullYear();

    const hours = originalDate.getHours().toString().padStart(2, "0");
    const minutes = originalDate.getMinutes().toString().padStart(2, "0");
    const seconds = originalDate.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${month}/${day}/${year}, ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  const formSubmit = async (
    imageUrl,
    startTimeAndDateConverted,
    endTimeAndDateConverted
  ) => {
    await createEventByAdmin(
      eventMode,
      eventprivacy,
      eventType,
      eventAddress,
      eventOnlineLink,
      eventName,
      eventCategory,
      eventCategoryOthers,
      startTimeAndDateConverted,
      endTimeAndDateConverted,
      selectedTimezone,
      conatctName,
      eventEmail,
      conatctNumber,
      eventDescription,
      evnetWebsiteLink,
      eventSubline,
      organizationNameSelectByuser,
      imageUrl,
      contactValue,
      addressOne,
      addressTwo,
      addressThree,
      postalCode,
      city,
      countrySelectData,
      selectStateByuser,
      platform,
      passcode,
      location
    )
      .then((res) => {
        setStartDateAndtime();
        setEndDateAndtime();
        setImage();
        toast.success("Event Created");

        // ClearStateHandle();
      })
      .catch((err) => {
        console.log("event create error", err.response.data.message);
        toast.error(err.response.data.message[0]);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (startTimeAndDate && endTimeAndDate) {
      const currentTimeZone = getTimeZoneById(selectedTimezone);

      const startTimeAndDateConverted = convertToUTC(
        convertDateFormat(startTimeAndDate),
        currentTimeZone
      );
      const endTimeAndDateConverted = convertToUTC(
        convertDateFormat(endTimeAndDate),
        currentTimeZone
      );

      if (image) {
        await uploadEventImage(image)
          .then((res) => {
            console.log("response image upload", res.data.filepath);
            formSubmit(
              res.data.filepath,
              startTimeAndDateConverted,
              endTimeAndDateConverted
            );
          })
          .catch((err) => {
            console.log("image upload error", err);
          });
      } else {
        const value = "";
        formSubmit(value, startTimeAndDateConverted, endTimeAndDateConverted);
      }
    } else {
      alert("Please enter date and time");
    }
  };

  // const ClearStateHandle = () => {
  //   setConatactName("");
  //   setEventName("");
  //   setEventPrivacy("");
  //   setEventCategory("");
  //   setEventOnlineLink("");
  //   setConatactNumber("");
  //   setEventEmail("");
  //   setEventWebsiteLink("");
  //   setEventDescription("");
  //   setEventAddress("");
  //   setEventOtherCategory("");
  //   setEventSubline("");
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSelectChange = (event, newValue) => {
    setOrganizationNameSelectedByUser(newValue.organizationId);
  };
  const handleSelectContactName = (event, newValue) => {
    setConatactName(newValue.id);
  };

  const OrganizationLookupApiHandle = async (e) => {
    await getOrganizationNameBySearch(e.target.value)
      .then((res) => {
        setOrganizationName(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const ContactNameLookup = async (e) => {
    setContactValue(e.target.value);
    await getContactNameData(e.target.value)
      .then((res) => {
        console.log("contact anme", res.data);

        setContactName(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleTimeZoneSelect = (e) => {
    // console.log("selected ttt", e);
    // const filterOffset = timezones?.filter(
    //   (timezone) => timezone.timeZone === e.target.value
    // );
    setSelectedTimezone(e.target.value);
    // if (type === "start") {
    //   setSelectedTimezone(e.target.value);
    //   setSelectoffset(filterOffset[0]?.utcOffset);
    // }
    // if (type === "end") {
    //   setSelectedTimezone(e.target.value);
    //   setSelectoffset(filterOffset[0]?.utcOffset);
    // }
    // const options = { timeZone: selectedTimeZone };
    // const formattedDate = inputDate.toLocaleString('en-US', options);
  };

  // const getDateANdTime = (timezone, dateAndTime) => {
  //   if (!timezone || timezone.isEmpty) {
  //     return new Date().toISOString();
  //   }

  //   const hour = parseInt(timezone.utcOffset);
  //   const minute = timezone.utcOffset - hour === 0.5 ? 30 : 0;

  //   const newDate = new Date(dateAndTime);

  //   if (timezone.utcOffset > 0) {
  //     newDate.setUTCHours(newDate.getUTCHours() + hour);
  //     newDate.setUTCMinutes(newDate.getUTCMinutes() + minute);
  //   } else if (timezone.utcOffset < 0) {
  //     newDate.setUTCHours(newDate.getUTCHours() - hour);
  //     newDate.setUTCMinutes(newDate.getUTCMinutes() - minute);
  //   }
  //   // Given timestamp
  //   const timestampStr = newDate.toISOString();
  //   const timestamp = new Date(timestampStr);

  //   // Add +5.5 hours

  //   // Get the current time zone offset in minutes
  //   const currentOffset = timestamp.getTimezoneOffset();

  //   // Add or subtract the desired offset (e.g., +5.5 or -10)
  //   const targetOffset = timezone.utcOffset * 60; // +5.5 hours in minutes
  //   const newTime = new Date(
  //     timestamp.getTime() + (targetOffset - currentOffset) * 60 * 1000
  //   );

  //   // Format the date and time
  //   const formattedTime = newTime.toISOString();

  //   console.log("Original UTC time:", timestamp.toISOString());
  //   console.log("Time with + hours hours:", formattedTime);
  //   return timestamp.toISOString();
  // };

  useEffect(() => {
    // console.log("start time and date", startTimeAndDate);
    // // console.log("selected timezone", selectedTimezone);
    // const dateAndTime = startTimeAndDate.toLocaleString("en-US", {
    //   timeZone: selectedTimezone,
    // });
    // const date = moment.tz(startTimeAndDate, selectedTimezone);
    // // const dates = dayjs.tz(startTimeAndDate, selectedTimezone);
    // console.log("date", date);
    // console.log("date to json", date.toJSON());
    // console.log("datesssss", dates);
    // console.log(
    //   "timzeon onchange",
    //   // moment.utc(startTimeAndDate).tz(e.target.value)
    //   startTimeAndDate.toLocaleString("en-US", {
    //     timeZone: selectedTimezone,
    //   })
    // );
    // const finalDate = new Date(dateAndTime).toISOString();
    // console.log("final date json", finalDate);
    // console.log("date and time", new Date(dateAndTime).toISOString());
    // if (selectOffset) {
    //   const appTimeZone = { utcOffset: selectOffset };
    //   const dataaa = getDateANdTime(appTimeZone, startTimeAndDate);
    //   console.log("dataaa", dataaa);
    //   setStartTimeAndDate(dataaa);
    // }
  }, [selectOffset]);

  useEffect(() => {
    getTimeZone();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        sx={{
          fontSize: "20px",
          fontFamily: "roboto",
          fontWeight: "600",
          marginBottom: "15px",
        }}
      >
        Update Event Details
      </Typography>
      {/* <FormGroup> */}
      <form onSubmit={(e) => handleSubmit(e)} autoComplete="false">
        <FormLabel
          id="event"
          sx={{ fontSize: "16px", fontWeight: "400", fontFamily: "roboto" }}
        >
          Event Mode*
        </FormLabel>
        <RadioGroup
          aria-labelledby="event"
          row
          defaultValue={eventMode}
          name="radio-buttons-group"
          onChange={(e) => {
            setEventMode(e.target.value);
            setEventPrivacy();
          }}
        >
          <FormControlLabel
            value="Private"
            control={<Radio />}
            label="Invite Only"
          />
          <FormControlLabel value="Public" control={<Radio />} label="Public" />
        </RadioGroup>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              htmlFor="nameEvent"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Name Of Event*
            </FormLabel>
            <TextField
              id="nameEvent"
              variant="outlined"
              sx={{ marginBottom: "15px" }}
              required
              onChange={(e) => setEventName(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              htmlFor="eveWeb"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Event Subline
            </FormLabel>
            <TextField
              id="eveWeb"
              variant="outlined"
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setEventSubline(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              htmlFor="desc"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Organization Name*
            </FormLabel>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={organizationName}
              sx={{ width: "100%" }}
              getOptionLabel={(option) => option.organizationName}
              onChange={handleSelectChange}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Search Organization name"
                  onChange={(e) => OrganizationLookupApiHandle(e)}
                />
              )}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              htmlFor="catgry"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Event Category*
            </FormLabel>
            <Select
              sx={{ marginBottom: "15px" }}
              labelId="catgry"
              id="catgry"
              value={eventCategory}
              required
              // label="aaaaaa"
              onChange={(e) => setEventCategory(e.target.value)}
            >
              {_eventCategoryList.map((eventC) => (
                <MenuItem value={eventC}>{eventC}</MenuItem>
              ))}
            </Select>
          </Box>
          {eventCategory === "Other" && (
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="catgry"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Event Category Others*
              </FormLabel>
              <TextField
                id="nameEvent"
                variant="outlined"
                sx={{ marginBottom: "15px" }}
                required
                onChange={(e) => setEventOtherCategory(e.target.value)}
              />
            </Box>
          )}

          {eventMode === "Private" && (
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="catgry"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Event Privacy*
              </FormLabel>
              <Select
                sx={{ marginBottom: "15px" }}
                labelId="catgry"
                id="catgry"
                value={eventprivacy}
                required
                // label="aaaaaa"
                onChange={(e) => setEventPrivacy(e.target.value)}
              >
                <MenuItem value={"OnlyMyContact"}>Only My Contact</MenuItem>
                <MenuItem value={"OnlyMyClient"}>Only My Client</MenuItem>
                <MenuItem value={"OnlyMyVendor"}>Only My Vendor</MenuItem>
              </Select>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", width: "24%" }}>
            <FormLabel
              htmlFor="stDate"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Start Date and Time*
            </FormLabel>
            <div style={{ marginTop: "-5px" }}>
              <DateAndTimePicker
                label={"Start Date and Time"}
                setStartDateAndtime={setStartDateAndtime}
                start
              />
              {/* <input type="datetime-local" /> */}
            </div>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", width: "24%" }}>
            <FormLabel
              htmlFor="endDate"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              End Date and Time*
            </FormLabel>
            <div style={{ marginTop: "-5px" }}>
              <DateAndTimePicker
                label={"End Date and Time"}
                end
                setEndDateAndtime={setEndDateAndtime}
                editDisabled={startTimeAndDate ? false : true}
              />
            </div>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              htmlFor="timezone"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Event Timezone*
            </FormLabel>
            <Select
              sx={{ marginBottom: "15px" }}
              labelId="timezone"
              id="timezone"
              required
              // label="Timezone"
              onChange={(e) => handleTimeZoneSelect(e)}
              value={selectedTimezone}
              MenuProps={{ style: { height: "200px" } }}
            >
              {timezones?.map((timezone) => (
                <MenuItem value={timezone.id}>{timezone.displayName}</MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
          <FormLabel
            id="eventType"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "roboto",
              marginTop: "20px",
            }}
          >
            Event Type*
          </FormLabel>
          <RadioGroup
            aria-labelledby="eventType"
            row
            defaultValue={eventType}
            name="eventType"
            onChange={(e) => setEventType(e.target.value)}
          >
            <FormControlLabel
              value="Online"
              control={<Radio />}
              label="Online"
            />
            <FormControlLabel
              value="In Person"
              control={<Radio />}
              label="In Person"
            />
          </RadioGroup>
        </Box>

        {eventType === "In Person" && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="countrySelect"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Country*
                </FormLabel>
                <Select
                  sx={{ marginBottom: "15px" }}
                  labelId="countrySelect"
                  id="countrySelect"
                  required
                  // label="Timezone"
                  onChange={handleCountrySelect}
                  defaultValue={countrySelectData}
                  MenuProps={{ style: { height: "200px" } }}
                >
                  {countryAllData?.map((country) => (
                    <MenuItem value={country.isoCode}>{country.name}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="location"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Location
                </FormLabel>
                <TextField
                  id="location"
                  variant="outlined"
                  onChange={(e) => setLocation(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                  required={eventType === "In Person" ? true : false}
                  disabled={eventType === "Online" ? true : false}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="stateSelect"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  State{stateAllData?.length > 0 ? "*" : ""}
                </FormLabel>
                <Select
                  sx={{ marginBottom: "15px" }}
                  labelId="stateSelect"
                  id="stateSelect"
                  required={stateAllData?.length > 0 ? true : false}
                  // label="Timezone"
                  onChange={handleStateSelect}
                  defaultValue={selectStateByuser}
                  MenuProps={{ style: { height: "200px" } }}
                >
                  {stateAllData?.map((state) => (
                    <MenuItem value={state.name}>{state.name}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="city"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  City*
                </FormLabel>
                <TextField
                  id="city"
                  variant="outlined"
                  onChange={(e) => setCity(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                  required={eventType === "In Person" ? true : false}
                  disabled={eventType === "Online" ? true : false}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="addressOne"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Address Line 1
                </FormLabel>
                <TextField
                  id="addressOne"
                  variant="outlined"
                  onChange={(e) => setAddressOne(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                  // required={eventType === "In Person" ? true : false}
                  disabled={eventType === "Online" ? true : false}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="addressTwo"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Address Line 2
                </FormLabel>
                <TextField
                  id="addressTwo"
                  variant="outlined"
                  onChange={(e) => setAddressTwo(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                  // required={eventType === "In Person" ? true : false}
                  disabled={eventType === "Online" ? true : false}
                />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="PostalCode"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Postal code
                </FormLabel>
                <TextField
                  id="PostalCode"
                  variant="outlined"
                  onChange={(e) => setPostalCode(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                  // required={eventType === "In Person" ? true : false}
                  disabled={eventType === "Online" ? true : false}
                />
              </Box>
            </Box>
          </>
        )}
        {eventType === "Online" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="nameOnlineLink"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Event Online Link{eventType === "Online" && "*"}
              </FormLabel>
              <TextField
                id="nameOnlineLink"
                variant="outlined"
                type="url"
                onChange={(e) => setEventOnlineLink(e.target.value)}
                sx={{ marginBottom: "15px" }}
                required={eventType === "Online" ? true : false}
                disabled={eventType === "Online" ? false : true}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="platform"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Platform{eventType === "Online" && "*"}
              </FormLabel>
              <TextField
                id="platform"
                variant="outlined"
                type="text"
                onChange={(e) => setPlatform(e.target.value)}
                sx={{ marginBottom: "15px" }}
                required={eventType === "Online" ? true : false}
                disabled={eventType === "Online" ? false : true}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="passcode"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Passcode
              </FormLabel>
              <TextField
                id="passcode"
                variant="outlined"
                type="text"
                onChange={(e) => setPasscode(e.target.value)}
                sx={{ marginBottom: "15px" }}
                // required={eventType === "Online" ? true : false}
                disabled={eventType === "Online" ? false : true}
              />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              htmlFor="ename"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Event Contact Name(Optional)
            </FormLabel>
            {/* <TextField
              id="ename"
              variant="outlined"
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setConatactName(e.target.value)}
            /> */}

            {/* <Autocomplete
              disablePortal
              id="eventConatctname"
              options={contactName}
              sx={{ width: "100%" }}
              getOptionLabel={(option) => option.userFullName}
              onChange={handleSelectContactName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search contact name"
                  onChange={(e) => ContactNameLookup(e)}
                />
              )}
            /> */}
            <Autocomplete
              id="eventConatctname"
              freeSolo
              options={contactName}
              getOptionLabel={(option) => option.userFullName}
              onChange={handleSelectContactName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label=""
                  onChange={(e) => ContactNameLookup(e)}
                />
              )}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              htmlFor="phone"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Contact Phone Number(Optional)
            </FormLabel>
            <TextField
              id="phone"
              variant="outlined"
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setConatactNumber(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              htmlFor="email"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Event Email(Optional)
            </FormLabel>
            <TextField
              id="email"
              variant="outlined"
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setEventEmail(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              htmlFor="eveWeb"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Event Website Link(Optional)
            </FormLabel>
            <TextField
              id="eveWeb"
              type="url"
              variant="outlined"
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setEventWebsiteLink(e.target.value)}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", width: "49%" }}>
            {previewImage && (
              <div style={{ width: "100%" }}>
                <img
                  src={previewImage}
                  style={{ width: "100px", height: "100px" }}
                  alt="preview"
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <FormLabel
                htmlFor="nameOnlineLink"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Add Image
              </FormLabel>

              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Select
                <VisuallyHiddenInput
                  type="file"
                  accept=".png,.jpg,.jpeg,.webp"
                  onChange={(event) => {
                    handleImageChange(event);
                    setImage(event.target.files[0]);
                  }}
                  title="Image"
                />
              </Button>
            </div>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "98%" }}>
          <FormLabel
            htmlFor="text"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "roboto",
              marginBottom: "10px",
            }}
          >
            Event Description*
          </FormLabel>
          <TextareaAutosize
            id="text"
            aria-label="minimum height"
            minRows={10}
            required
            onChange={(e) => setEventDescription(e.target.value)}
          />
        </Box>

        <Button
          variant="contained"
          sx={{
            width: "300px",
            alignSelf: "center",
            marginTop: "15px",
            backgroundColor: "#D9D9D9",
            color: "white",
          }}
          type="submit"
        >
          Update Event
        </Button>
      </form>
      {/* </FormGroup> */}
    </Box>
  );
};

export default UpdateEvent;
