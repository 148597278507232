import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import { productDelete } from "api/api";
import { toast } from "react-toastify";
import { faqQuestionDelete } from "api/api";

export default function FaqDeleteQuestion({
  deleteModalOpen,
  setDeleteModalOpen,
  setPage,
  id,
  getFaqData,
  setUserData,
}) {
  const handleClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDeleteFaq = async () => {
    await faqQuestionDelete(id)
      .then((res) => {
        setDeleteModalOpen(false);
        setPage(1);
        getFaqData();
        console.log("res", res);
        toast.success("FAQ question Deleted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong please try again!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDeleteModalOpen(false);
      });
  };

  return (
    <div>
      <Dialog
        open={deleteModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Question"}</DialogTitle>
        <DialogContent style={{ width: "400px" }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the question?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleDeleteFaq} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
