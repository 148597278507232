import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import { MENU_OPEN, SET_MENU } from "store/actions";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useState } from "react";
import NavCollapse from "../NavCollapse";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  let itemTarget = "_self";
  if (item.target) {
    // itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    setOpen(!open);
    console.log("idd", id);
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
  };
  console.log("itemitem", item);
  // menu collapse & item
  const menus = item.children?.map((item) => {
    console.log("item", item);
    switch (item.type) {
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  // active menu item on page load
  useEffect(() => {
    if (document.location.pathname === "/dashboard") {
      dispatch({ type: MENU_OPEN, id: "default" });
    }
    if (document.location.pathname === "/dashboard/organization-list") {
      dispatch({ type: MENU_OPEN, id: "sample-page" });
    }
    if (document.location.pathname === "/dashboard/user-export") {
      dispatch({ type: MENU_OPEN, id: "User Export" });
    }
    if (document.location.pathname === "/dashboard/feature-vendor-list") {
      dispatch({ type: MENU_OPEN, id: "Feature Vendor" });
    }
    if (document.location.pathname === "/dashboard/category-management") {
      dispatch({ type: MENU_OPEN, id: "Category Management" });
    }
    if (document.location.pathname === "/dashboard/message-moderation") {
      dispatch({ type: MENU_OPEN, id: "Message Moderation" });
    }
    if (document.location.pathname === "/dashboard/connection-moderation") {
      dispatch({ type: MENU_OPEN, id: "Connection Moderation" });
    }
    if (document.location.pathname === "/dashboard/event/management") {
      dispatch({ type: MENU_OPEN, id: "Event Management" });
    }
    if (document.location.pathname === "/dashboard/client/management") {
      dispatch({ type: MENU_OPEN, id: "Client Management" });
    }
    if (document.location.pathname === "/dashboard/vendor/management") {
      dispatch({ type: MENU_OPEN, id: "Vendor Management" });
    }
    if (document.location.pathname === "/dashboard/consultant/management") {
      dispatch({ type: MENU_OPEN, id: "Consultant Management" });
    }
    if (document.location.pathname === "/dashboard/ims-flow-management") {
      dispatch({ type: MENU_OPEN, id: "IMS Flow Management" });
    }
    // const currentIndex = document.location.pathname
    //   .toString()
    //   .split("/")
    //   .findIndex((id) => id === item.id);
    // if (currentIndex > -1) {
    //   dispatch({ type: MENU_OPEN, id: item.id });
    // }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`,
        }}
        selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
        onClick={() => itemHandler(item.id)}
      >
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          {itemIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant={
                customization.isOpen.findIndex((id) => id === item.id) > -1
                  ? "h5"
                  : "body1"
              }
              color="inherit"
            >
              {item.title}
            </Typography>
          }
          // secondary={
          //   item.caption && (
          //     <Typography
          //       variant="caption"
          //       sx={{ ...theme.typography.subMenuCaption }}
          //       display="block"
          //       gutterBottom
          //     >
          //       {item.caption}
          //     </Typography>
          //   )
          // }
        />
        {item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
      {/* <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              left: "32px",
              top: 0,
              height: "100%",
              width: "1px",
              opacity: 1,
              background: theme.palette.primary.light,
            },
          }}
        >
          {menus}
        </List>
      </Collapse> */}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
