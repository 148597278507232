import { SpeedDial } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import ProductCategoryURL from "ui-component/ProductCategoryUrl/Table";
import UnverifiedProducerTable from "ui-component/UnverifiedProducer/Table";
import UnverifiedViewModal from "ui-component/UnverifiedProducer/UnverifiedViewModal";
import VerifyAccept from "ui-component/UnverifiedProducer/VerifyAccept";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ProductCategoryModal from "ui-component/ProductCategoryUrl/Modal";
import ProductCategoryCreateModal from "ui-component/ProductCategoryUrl/CreateProductCategoryModal";

export default function ProductCategoryUrlList() {
  const [openModal, setOpenModal] = useState(false);
  const [createModalOpen, setCreateModal] = useState(false);
  const [unverifiedId, setUnverifiedId] = useState();
  const [updated, setUpdated] = useState(false);
  const [openVerifyModal, setVerifyModal] = useState(false);
  const [productcategoryData, setProductCategoryurlData] = useState(null);
  const [orgName, setOrgName] = useState(null);

  const params = useParams();

  useEffect(() => {
    setUnverifiedId(params?.orgid);
  }, [params, updated]);

  return (
    <div>
      <ProductCategoryURL
        openModal={openModal}
        setOpenModal={setOpenModal}
        updated={updated}
        setUnverifiedId={setUnverifiedId}
        setVerifyModal={setVerifyModal}
        unverifiedId={unverifiedId}
        setProductCategoryurlData={setProductCategoryurlData}
        productcategoryData={productcategoryData}
        setOrgName={setOrgName}
      />

      <ProductCategoryModal
        unverifiedId={unverifiedId}
        openModal={openModal}
        setOpenModal={setOpenModal}
        productcategoryData={productcategoryData}
        orgName={orgName}
        setUpdated={setUpdated}
        updated={updated}
      />

      <ProductCategoryCreateModal
        createModalOpen={createModalOpen}
        setCreateModal={setCreateModal}
        id={unverifiedId}
        updated={updated}
        setUpdated={setUpdated}
        orgName={orgName}
      />
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{
          position: "absolute",
          bottom: "2%",
          right: "3%",
        }}
        icon={<AddOutlinedIcon size={"small"} />}
        onClick={() => setCreateModal(true)}
      ></SpeedDial>
    </div>
  );
}
