import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import LoaderforRoster from "views/ProductImport/LoaderforRoster";

const defaultHeaders = [
  "No",
  "Product Category",
  "Location",
  "Manufacturer",
  "Product Name",
  "Excel Valid/Error",
  "View",
];

export default function ProductTable({
  data,
  setModalOpen,
  setRowData,
  setModalOpenView,
  loading,
}) {
  const handleModalOpen = (rowdata) => {
    setRowData(rowdata);
    setModalOpen(true);
  };
  const handleModalViewOpen = (rowdata) => {
    setRowData(rowdata);
    setModalOpenView(true);
  };

  const dateformatter = (date) => {
    let dates = new Date(date);
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let formattedDate = dates.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  return (
    <>
      {loading ? (
        <LoaderforRoster />
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {defaultHeaders.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, id) => (
                <TableRow
                  key={id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  {console.log("row", row)}
                  <TableCell component="th" scope="row">
                    {id + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.category}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.location}
                  </TableCell>
                  <TableCell align="center">
                    {row.manufacturerOrganization}
                  </TableCell>
                  <TableCell align="center">{row.productName}</TableCell>
                  {/* <TableCell align="center">{row.version}</TableCell>
              <TableCell align="center">{row.modelNumber}</TableCell>

              <TableCell align="center">{row.serialNumber}</TableCell>
              <TableCell align="center">
                {dateformatter(row.purchaseDate)}
              </TableCell>
              <TableCell align="center">
                {dateformatter(row.warrantyStartDate)}
              </TableCell> */}
                  {/* <TableCell align="center">
                {dateformatter(row.warrantyEndDate)}
              </TableCell>
              <TableCell align="center">
                {row.active === true ? "Yes" : "No"}
              </TableCell>
              <TableCell align="center">
                {row.status === "Select" ? "null" : row.status}
              </TableCell>
              <TableCell align="center">{row.purchasePriceType}</TableCell>
              <TableCell align="center">
                {row.purchasePrice ? row.purchasePrice : "null"}
              </TableCell>
              <TableCell align="center">
                {row.assetId ? row.assetId : "null"}
              </TableCell> */}
                  {/* <TableCell align="center">
                {row.consultantOrganization
                  ? row.consultantOrganization
                  : "null"}
              </TableCell>
              <TableCell align="center">
                {row.integratorOrganization
                  ? row.integratorOrganization
                  : "null"}
              </TableCell>
              <TableCell align="center">
                {row.vendorOrganization ? row.vendorOrganization : "null"}
              </TableCell> */}

                  {/*  */}
                  <TableCell align="center">
                    {row.error ? (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleModalOpen(row)}
                      >
                        View Error
                      </Button>
                    ) : (
                      <span style={{ color: "green" }}>Valid</span>
                    )}
                  </TableCell>
                  <TableCell align="start">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleModalViewOpen(row)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
