export const postType = [
  {
    name: "all",
    title: "All",
  },
  {
    name: "general",
    title: "General",
  },
  {
    name: "newProductAnnouncement",
    title: "NewProductAnnouncement",
  },
  {
    name: "upcomingProductTraining",
    title: "UpcomingProductTraining",
  },
  {
    name: "upcomingEventAnnouncement",
    title: "UpcomingEventAnnouncement",
  },
  {
    name: "eolAnnouncement",
    title: "EolAnnouncement",
  },
  {
    name: "upcomingProjectAnnouncement",
    title: "UpcomingProjectAnnouncement",
  },
  {
    name: "rfpInvite",
    title: "RfpInvite",
  },
  {
    name: "rfqInviteSiteWalkThroughInvite",
    title: "RfqInviteSiteWalkThroughInvite",
  },
  {
    name: "rfqInvite",
    title: "RfqInvite",
  },
  {
    name: "siteWalkThroughInvite",
    title: "SiteWalkThroughInvite",
  },
  {
    name: "other",
    title: "Other",
  },
];

export const PostVisibility = {
  public: "public",
  OnlyMyClients: "onlyMyClients", //connected all client
  OnlySelectedClients: "onlySelectedClients", //organization select list
  OnlyMyVendors: "onlyMyVendors", //connected all vendor
  OnlySelectedVendors: "onlySelectedVendors", //organization select list
  OnlyConsumers: "onlyConsumers", //all consumer
  OnlyProducers: "onlyProducers", //all producer
};

// export const BASE_URL = "http://localhost:3000/api/";
// export const BASE_URL = "https://stc-development.vercel.app/api/";
export const BASE_URL = "https://api.venuetechconnect.com/api/";
