import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { VerifyProducerByID } from "api/api";
import { toast } from "react-toastify";

export default function VerifyAccept({
  openVerifyModal,
  setVerifyModal,
  id,
  setUpdated,
  updated,
}) {
  const handleClose = () => {
    setVerifyModal(false);
  };
  const handleVerify = async () => {
    // setVerifyModal(false);
    try {
      await VerifyProducerByID(id);
      toast.success("Producer Verified");
      setUpdated(!updated);
      handleClose();
    } catch (error) {
      toast.error("Please try again!");
    }
  };

  return (
    <div>
      <Dialog
        open={openVerifyModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Accept Producer"}</DialogTitle>
        <DialogContent style={{ width: "400px" }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Accept the producer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={handleVerify} autoFocus color="success">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
