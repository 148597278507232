import BlockUserTable from "./blockUserTable";



export default function BlockIndex(){


    return (<>
    
        <BlockUserTable/>
    
    
    </>)
}