import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, Grid } from "@mui/material";
import { useEffect } from "react";
import { getIMSproductViewData } from "api/api";
import { useState } from "react";

import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  //   height: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  overflowX: "hidden",
  overflowY: "scroll",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  columnGap: "10px",
  justifyContent: "flex-start",
  alignItems: "center",
}));
export default function ImsProductViewModal({
  setOpenModal,
  openModal,
  eventId,
}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const getEventDetailsById = async (eventId) => {
    setLoading(true);
    await getIMSproductViewData(eventId)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
    setLoading(false);
  };

  const handleDateTime = (value) => {
    const date = moment(value);
    const formattedDate = date.format("DD-MMM-YY HH:mm");
    // const formattedDate = date.utc().format("DD-MMM-YY HH:mm");
    return formattedDate;
  };

  useEffect(() => {
    // getEventDetailsById(eventId);
  }, [eventId, openModal]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" autoComplete="off">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>IMS Product Details</h2>
            <Typography
              variant="h4"
              id="user-detail-modal"
              align="center"
              color={"orange"}
              onClick={() => handleClose()}
              style={{ cursor: "pointer", color: "red", width: "auto" }}
            >
              Close
            </Typography>
          </div>
          {!loading ? (
            <div
              style={{
                width: "100%",
                //   height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                // padding: "20px 0px",
                // flexDirection: "column",
                columnGap: "10px",
              }}
            >
              <Grid container>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Product Name : </p>
                    <p>{data?.productName}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Manufacturer Name : </p>
                    <p>{data?.manufacturerName}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Model Number : </p>
                    <p>{data?.modelNumber}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Category Name : </p>
                    <p>{data?.categoryName}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Organization Name : </p>
                    <p>{data?.organizationName}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Location Name : </p>
                    <p>{data?.locationName}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Serial Number : </p>
                    <p>{data?.serialNumber}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> Purchase Date :</p>
                    <p>{handleDateTime(data?.purchaseDate)}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> Warranty Start Date :</p>
                    <p>{handleDateTime(data?.warrantyStartDate)}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> Warranty End Date :</p>
                    <p>{handleDateTime(data?.warrantyEndDate)}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle">Purchase Price Type : </p>
                    <p>{data?.purchasePriceType}</p>
                  </Item>
                </Grid>
                <Grid item xs={6} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> Purchase Price :</p>
                    <p>{data?.purchasePrice}</p>
                  </Item>
                </Grid>

                <Grid item xs={12} style={{ borderBottom: "1px solid grey" }}>
                  <Item>
                    <p className="eventTitle"> AssetId Tag Number :</p>
                    <p>{data?.assetIdTagNumber || "null"}</p>
                  </Item>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}
