import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormControl";

import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import {
  EventTimeZone,
  createEventByAdmin,
  getContactNameData,
  getOrganizationNameBySearch,
  uploadEventImage,
} from "api/api";
import { useEffect } from "react";
import { useState } from "react";

// import { Autocomplete } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment-timezone";

import { Country, State, City } from "country-state-city";

import { styled } from "@mui/material/styles";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CircularProgress } from "@mui/material";
import validator from "validator";
import { useNavigate } from "react-router";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DateTimeForCreateEvent from "./DateTime";
import Loader from "ui-component/Loader";

const filter = createFilterOptions();

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const EventCreate = () => {
  const navigate = useNavigate();

  const [countryAllData, setCountryAllData] = useState(
    Country.getAllCountries()
  );
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  const [cityData, setAllCity] = useState();

  const [stateAllData, setStateAllData] = useState();
  const [countrySelectData, setCountrySelect] = useState("");
  const [selectStateByuser, setStateSelectByUser] = useState("");
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  const [minDate, setMinDateForCreate] = useState("");

  //
  const [timezones, setTimeZones] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [eventMode, setEventMode] = useState("Public");
  const [eventType, setEventType] = useState("Online");
  const [platform, setPlatform] = useState("");
  const [passcode, setPasscode] = useState("");
  const [location, setLocation] = useState("");
  const [organizationName, setOrganizationName] = useState([]);

  const [contactName, setContactName] = useState([]);
  const [contactValue, setContactValue] = useState("");
  const [previewImage, setPreviewImage] = useState();
  const [venueMapPDFImage, setVenueMapPDFImage] = useState();
  const [venueMapPDFImageURL, setVenueMapPDFImageURL] = useState();
  const [venueMapPDFImageName, setVenueMapPDFImageName] = useState("");

  const [organizationNameSelectByuser, setOrganizationNameSelectedByUser] =
    useState("");

  const [eventName, setEventName] = useState("");
  const [evnetShortDesc, setEventShortDesc] = useState("");
  const [eventprivacy, setEventPrivacy] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [startTimeAndDate, setStartDateAndtime] = useState("");
  const [endTimeAndDate, setEndDateAndtime] = useState("");
  const [eventOnlineLink, setEventOnlineLink] = useState("https://");
  const [conatctNumber, setConatactNumber] = useState("");
  const [conatctName, setConatactName] = useState("");
  const [eventEmail, setEventEmail] = useState("");
  const [evnetWebsiteLink, setEventWebsiteLink] = useState("https://");
  const [eventDescription, setEventDescription] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventCategoryOthers, setEventOtherCategory] = useState("");
  const [eventSubline, setEventSubline] = useState("");
  const [startDateTime, setStartTimeAndDate] = useState("");
  const [selectOffset, setSelectoffset] = useState();
  const [selectType, setType] = useState("start");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [addressThree, setAddressThree] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [customOrg, setCustomOrg] = useState("");
  const [startTimeForEnd, setStartTimeForEndDate] = useState();

  const [clearValue, setClearValue] = useState("");
  const [contactDisplayValue, setContactDisplayValue] = useState();

  const getTimeZone = async () => {
    await EventTimeZone()
      .then((res) => {
        setTimeZones(res.data);
      })
      .catch((err) => {});
  };

  const handleCountrySelect = (e) => {
    setStateAllData();
    const value = e.target.value;
    const filterCountryCode = Country.getCountryByCode(value);

    setCountrySelect(filterCountryCode?.name);
    setSelectedCountryCode(filterCountryCode?.isoCode);
    const filterState = State.getStatesOfCountry(value);
    setStateAllData(filterState);
  };

  const handleStateSelect = (e) => {
    const getStateCode = State.getStateByCodeAndCountry(
      e.target.value,
      selectedCountryCode
    );
    setStateSelectByUser(getStateCode?.name);
    const getAllcity = City.getCitiesOfState(
      selectedCountryCode,
      e.target.value
    );
    setAllCity(getAllcity);
  };

  function convertToUTC(dateString, timezone) {
    const localTime = moment.tz(dateString, "MM/DD/YYYY HH:mm", timezone);
    return localTime.utc().format();
  }

  function getTimeZoneById(id) {
    for (const timeZone of timezones) {
      if (timeZone.id === id) {
        return timeZone.timeZone;
      }
    }
  }

  const _eventCategoryList = [
    "Trade Show / Conference",
    "Product Release",
    "Product Training",
    "Product Showcase",
    "Project Showcase / Site Tour",
    "RPF Meeting /  Site Walk-Through",
    "Party / Social Event",
    "Workshop / Forum",
    "Other",
  ];

  function convertDateFormat(inputDateString) {
    const originalDate = new Date(inputDateString);

    const day = originalDate.getDate().toString().padStart(2, "0");
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const year = originalDate.getFullYear();

    const hours = originalDate.getHours().toString().padStart(2, "0");
    const minutes = originalDate.getMinutes().toString().padStart(2, "0");
    const seconds = originalDate.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${month}/${day}/${year}, ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  function isValidUrl(url) {
    return validator.isURL(url);
  }

  const formSubmit = async (
    images,
    startTimeAndDateConverted,
    endTimeAndDateConverted
  ) => {
    if (!organizationNameSelectByuser && !customOrg) {
      alert("Please add organization name");
      return false;
    }
    if (eventType === "Online") {
      if (!isValidUrl(eventOnlineLink)) {
        alert("Please enter valid url in 'Event Online'");
        return false;
      }
    }
    if (evnetWebsiteLink.length > 8) {
      if (!isValidUrl(evnetWebsiteLink)) {
        alert("Please enter valid url in 'Event Website'");
        return false;
      }
    }
    setLoading(true);
    let imageUrl = images?.imageUrl || null;
    let venueMapPDFLink = images?.venueMapPDFImageUrl || null;
    await createEventByAdmin(
      eventMode,
      eventprivacy,
      eventType,
      eventAddress,
      eventOnlineLink,
      eventName,
      eventCategory,
      eventCategoryOthers,
      startTimeAndDateConverted,
      endTimeAndDateConverted,
      selectedTimezone,
      conatctName,
      eventEmail,
      conatctNumber,
      eventDescription,
      evnetWebsiteLink,
      eventSubline,
      organizationNameSelectByuser,
      imageUrl,
      venueMapPDFLink,
      contactValue,
      addressOne,
      addressTwo,
      addressThree,
      postalCode,
      city,
      countrySelectData,
      selectStateByuser,
      platform,
      passcode,
      location,
      customOrg
    )
      .then((res) => {
        // setStartDateAndtime();
        // setEndDateAndtime();
        setImage();
        setEventName("");

        toast.success("Event Created");
        navigate("/dashboard/event/management");
      })
      .catch((err) => {
        console.log("event create error", err);
      });
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (startTimeAndDate && endTimeAndDate) {
      const currentTimeZone = getTimeZoneById(selectedTimezone);

      const startTimeAndDateConverted = convertToUTC(
        convertDateFormat(startTimeAndDate),
        currentTimeZone
      );

      setStartTimeForEndDate(startTimeAndDateConverted);
      const endTimeAndDateConverted = convertToUTC(
        convertDateFormat(endTimeAndDate),
        currentTimeZone
      );

      if (!organizationNameSelectByuser && !customOrg) {
        alert("Please add organization name");
        return false;
      }
      if (eventType === "Online") {
        if (!isValidUrl(eventOnlineLink)) {
          alert("Please enter valid url in 'Event Online'");
          return false;
        }
      }
      if (evnetWebsiteLink.length > 8) {
        if (!isValidUrl(evnetWebsiteLink)) {
          alert("Please enter valid url in 'Event Website'");
          return false;
        }
      }

      if (image || venueMapPDFImage) {
        let imageUrl = null;
        let venueMapPDFImageUrl = null;
        if (image) {
          setLoading(true);
          await uploadEventImage(image)
            .then((res) => {
              console.log("response image upload", res.data.filepath);
              imageUrl = res.data.filepath;
            })
            .catch((err) => {
              setLoading(false);
              console.log("image upload error", err);
            });
        }

        if (venueMapPDFImage) {
          setLoading(true);
          await uploadEventImage(venueMapPDFImage)
            .then((res) => {
              console.log("response image upload", res.data.filepath);
              venueMapPDFImageUrl = res.data.filepath;
            })
            .catch((err) => {
              setLoading(false);
              console.log("image upload error", err);
            });
        }

        const images = {
          imageUrl: imageUrl || null,
          venueMapPDFImageUrl: venueMapPDFImageUrl || null,
        };

        formSubmit(images, startTimeAndDateConverted, endTimeAndDateConverted);
      } else {
        const images = {
          imageUrl: null,
          venueMapPDFImageUrl: null,
        };
        formSubmit(images, startTimeAndDateConverted, endTimeAndDateConverted);
      }
    } else {
      alert("Please enter date and time");
    }
  };

  // const handleImageChangeVenueMapPDFImage = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       setVenueMapPreviewPDFImage(reader.result);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSelectContactName = (event, newValue) => {
    setConatactName(newValue);
    setContactDisplayValue(newValue);
  };

  const OrganizationLookupApiHandle = async (e) => {
    const searchText = e !== undefined ? e.target.value : "";

    setCustomOrg(searchText);
    setClearValue(searchText);
    if (searchText) {
      setOrganizationNameSelectedByUser("");
    }
    setContactName([]);
    setContactDisplayValue();
    await getOrganizationNameBySearch(searchText)
      .then((res) => {
        setOrganizationName(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const ContactNameLookup = async (e) => {
    setConatactName();

    setContactValue(e.target.value);
    if (organizationNameSelectByuser) {
      await getContactNameData(e.target.value, organizationNameSelectByuser)
        .then((res) => {
          setContactName(res.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleSelectChange = async (event, newValue) => {
    setConatactName();
    setContactName([]);

    setContactDisplayValue();
    setOrganizationNameSelectedByUser(newValue.organizationId);

    OrganizationLookupApiHandle();
  };

  const handleTimeZoneSelect = (e) => {
    // console.log("selected ttt", e);
    // const filterOffset = timezones?.filter(
    //   (timezone) => timezone.timeZone === e.target.value
    // );
    setSelectedTimezone(e.target.value);
    // if (type === "start") {
    //   setSelectedTimezone(e.target.value);
    //   setSelectoffset(filterOffset[0]?.utcOffset);
    // }
    // if (type === "end") {
    //   setSelectedTimezone(e.target.value);
    //   setSelectoffset(filterOffset[0]?.utcOffset);
    // }
    // const options = { timeZone: selectedTimeZone };
    // const formattedDate = inputDate.toLocaleString('en-US', options);
  };

  // const getDateANdTime = (timezone, dateAndTime) => {
  //   if (!timezone || timezone.isEmpty) {
  //     return new Date().toISOString();
  //   }

  //   const hour = parseInt(timezone.utcOffset);
  //   const minute = timezone.utcOffset - hour === 0.5 ? 30 : 0;

  //   const newDate = new Date(dateAndTime);

  //   if (timezone.utcOffset > 0) {
  //     newDate.setUTCHours(newDate.getUTCHours() + hour);
  //     newDate.setUTCMinutes(newDate.getUTCMinutes() + minute);
  //   } else if (timezone.utcOffset < 0) {
  //     newDate.setUTCHours(newDate.getUTCHours() - hour);
  //     newDate.setUTCMinutes(newDate.getUTCMinutes() - minute);
  //   }
  //   // Given timestamp
  //   const timestampStr = newDate.toISOString();
  //   const timestamp = new Date(timestampStr);

  //   // Add +5.5 hours

  //   // Get the current time zone offset in minutes
  //   const currentOffset = timestamp.getTimezoneOffset();

  //   // Add or subtract the desired offset (e.g., +5.5 or -10)
  //   const targetOffset = timezone.utcOffset * 60; // +5.5 hours in minutes
  //   const newTime = new Date(
  //     timestamp.getTime() + (targetOffset - currentOffset) * 60 * 1000
  //   );

  //   // Format the date and time
  //   const formattedTime = newTime.toISOString();

  //   console.log("Original UTC time:", timestamp.toISOString());
  //   console.log("Time with + hours hours:", formattedTime);
  //   return timestamp.toISOString();
  // };

  const onInputChangeOnAutoComplete = () => {
    setConatactName(null);
    setContactName(null);
    setContactDisplayValue(null);
  };

  useEffect(() => {
    getTimeZone();
  }, []);
  useEffect(() => {
    if (startTimeAndDate) {
      const currentTimeZone = getTimeZoneById(selectedTimezone);

      const startTimeAndDateConverted = convertToUTC(
        convertDateFormat(startTimeAndDate),
        currentTimeZone
      );

      setStartTimeForEndDate(startTimeAndDateConverted);
    }
  }, [startTimeAndDate]);

  return (
    <>
      {loading && <Loader />}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "roboto",
            fontWeight: "600",
            marginBottom: "15px",
          }}
        >
          Add Event Details
        </Typography>
        {/* <FormGroup> */}
        <form onSubmit={(e) => handleSubmit(e)} autoComplete="false">
          <FormLabel
            id="event"
            sx={{ fontSize: "16px", fontWeight: "400", fontFamily: "roboto" }}
          >
            Mode*
          </FormLabel>
          <RadioGroup
            aria-labelledby="event"
            row
            defaultValue={eventMode}
            name="radio-buttons-group"
            onChange={(e) => {
              setEventMode(e.target.value);
              setEventPrivacy();
            }}
          >
            <FormControlLabel
              value="Private"
              control={<Radio />}
              label="Invite Only"
            />
            <FormControlLabel
              value="Public"
              control={<Radio />}
              label="Public"
            />
          </RadioGroup>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="nameEvent"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Name Of Event*
              </FormLabel>
              <TextField
                id="nameEvent"
                variant="outlined"
                value={eventName}
                sx={{ marginBottom: "15px" }}
                required
                onChange={(e) => setEventName(e.target.value)}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="eveWeb"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Short Description *
              </FormLabel>
              <TextField
                id="eveWeb"
                variant="outlined"
                value={eventSubline}
                required
                sx={{ marginBottom: "15px" }}
                onChange={(e) => setEventSubline(e.target.value)}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="combo-box-demo"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Organization Name *
              </FormLabel>

              <Autocomplete
                id="combo-box-demo"
                freeSolo
                required
                options={organizationName}
                getOptionLabel={(option) => option.organizationName}
                onChange={handleSelectChange}
                onInputChange={onInputChangeOnAutoComplete}
                clearIcon={""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label=""
                    onChange={(e) => OrganizationLookupApiHandle(e)}
                  />
                )}
              />

              {/* <Autocomplete
              id="combo-box-demo"
              freeSolo
              options={organizationName}
              sx={{ width: "100%" }}
              getOptionLabel={(option) => option.organizationName}
              onChange={handleSelectChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Organization name"
                  onChange={(e) => OrganizationLookupApiHandle(e)}
                />
              )}
            /> */}
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="catgry"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Category*
              </FormLabel>
              <Select
                sx={{ marginBottom: "15px" }}
                labelId="catgry"
                id="catgry"
                value={eventCategory}
                required
                // label="aaaaaa"
                onChange={(e) => setEventCategory(e.target.value)}
              >
                {_eventCategoryList.map((eventC) => (
                  <MenuItem value={eventC}>{eventC}</MenuItem>
                ))}
              </Select>
            </Box>
            {eventCategory === "Other" && (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="catgry"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Category Others*
                </FormLabel>
                <TextField
                  id="nameEvent"
                  variant="outlined"
                  value={eventCategoryOthers}
                  sx={{ marginBottom: "15px" }}
                  required
                  onChange={(e) => setEventOtherCategory(e.target.value)}
                />
              </Box>
            )}

            {eventMode === "Private" && (
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="catgry"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Privacy*
                </FormLabel>
                <Select
                  sx={{ marginBottom: "15px" }}
                  labelId="catgry"
                  id="catgry"
                  value={eventprivacy}
                  required
                  // label="aaaaaa"
                  onChange={(e) => setEventPrivacy(e.target.value)}
                >
                  <MenuItem value={"OnlyMyContact"}>Only My Contact</MenuItem>
                  <MenuItem value={"OnlyMyClient"}>Only My Client</MenuItem>
                  <MenuItem value={"OnlyMyVendor"}>Only My Vendor</MenuItem>
                </Select>
              </Box>
            )}
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="stDate"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Start Date and Time*
              </FormLabel>
              <div style={{ marginTop: "-5px" }}>
                <DateTimeForCreateEvent
                  label={"Start Date and Time"}
                  disablePast
                  setStartDateAndtime={setStartDateAndtime}
                  start
                  setMinDateForCreate={setMinDateForCreate}
                />
                {/* <input type="datetime-local" /> */}
              </div>
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="endDate"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                End Date and Time*
              </FormLabel>
              {console.log("start time", startTimeAndDate)}
              <div style={{ marginTop: "-5px" }}>
                <DateTimeForCreateEvent
                  label={"End Date and Time"}
                  end
                  disablePast
                  defaultValue={startTimeAndDate}
                  setEndDateAndtime={setEndDateAndtime}
                  minStartDate={minDate}
                  // editDisabled={startTimeAndDate ? false : true}
                />
              </div>
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="timezone"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Timezone*
              </FormLabel>
              <Select
                sx={{ marginBottom: "15px" }}
                labelId="timezone"
                id="timezone"
                required
                // label="Timezone"
                onChange={(e) => handleTimeZoneSelect(e)}
                value={selectedTimezone}
                MenuProps={{ style: { height: "200px" } }}
              >
                {timezones?.map((timezone) => (
                  <MenuItem value={timezone.id}>
                    {timezone.displayName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "49%" }}>
            <FormLabel
              id="eventType"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginTop: "20px",
              }}
            >
              Type*
            </FormLabel>
            <RadioGroup
              aria-labelledby="eventType"
              row
              defaultValue={eventType}
              name="eventType"
              onChange={(e) => setEventType(e.target.value)}
            >
              <FormControlLabel
                value="Online"
                control={<Radio />}
                label="Online"
              />
              <FormControlLabel
                value="In Person"
                control={<Radio />}
                label="In Person"
              />
            </RadioGroup>
          </Box>

          {eventType === "In Person" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "49%",
                  }}
                >
                  <FormLabel
                    htmlFor="countrySelect"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                      marginBottom: "10px",
                    }}
                  >
                    Country*
                  </FormLabel>
                  <Select
                    sx={{ marginBottom: "15px" }}
                    labelId="countrySelect"
                    id="countrySelect"
                    required
                    // label="Timezone"
                    onChange={handleCountrySelect}
                    defaultValue={countrySelectData}
                    MenuProps={{ style: { height: "200px" } }}
                  >
                    {countryAllData?.map((country) => (
                      <MenuItem value={country.isoCode}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "49%",
                  }}
                >
                  <FormLabel
                    htmlFor="location"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                      marginBottom: "10px",
                    }}
                  >
                    Location
                  </FormLabel>

                  <TextField
                    id="location"
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => setLocation(e.target.value)}
                    sx={{ marginBottom: "15px" }}
                    // required={eventType === "In Person" ? true : false}
                    disabled={eventType === "Online" ? true : false}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "49%",
                  }}
                >
                  <FormLabel
                    htmlFor="stateSelect"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                      marginBottom: "10px",
                    }}
                  >
                    State{stateAllData?.length > 0 ? "*" : ""}
                  </FormLabel>
                  <Select
                    sx={{ marginBottom: "15px" }}
                    labelId="stateSelect"
                    id="stateSelect"
                    required={stateAllData?.length > 0 ? true : false}
                    // label="Timezone"
                    onChange={handleStateSelect}
                    defaultValue={selectStateByuser}
                    MenuProps={{ style: { height: "200px" } }}
                  >
                    {stateAllData?.map((state) => (
                      <MenuItem value={state.isoCode}>{state.name}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "49%",
                  }}
                >
                  <FormLabel
                    htmlFor="city"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                      marginBottom: "10px",
                    }}
                  >
                    City*
                  </FormLabel>
                  <Select
                    sx={{ marginBottom: "15px" }}
                    labelId="countrySelect"
                    id="countrySelect"
                    required
                    cityTEsting
                    // label="Timezone"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    MenuProps={{ style: { height: "200px" } }}
                  >
                    {cityData?.map((country) => (
                      <MenuItem value={country.name}>{country.name}</MenuItem>
                    ))}
                  </Select>

                  {/* <TextField
                  id="city"
                  variant="outlined"
                  onChange={(e) => setCity(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                  required={eventType === "In Person" ? true : false}
                  disabled={eventType === "Online" ? true : false}
                /> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "49%",
                  }}
                >
                  <FormLabel
                    htmlFor="addressOne"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                      marginBottom: "10px",
                    }}
                  >
                    Address Line 1
                  </FormLabel>
                  <TextField
                    id="addressOne"
                    variant="outlined"
                    onChange={(e) => setAddressOne(e.target.value)}
                    sx={{ marginBottom: "15px" }}
                    // required={eventType === "In Person" ? true : false}
                    disabled={eventType === "Online" ? true : false}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "49%",
                  }}
                >
                  <FormLabel
                    htmlFor="addressTwo"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                      marginBottom: "10px",
                    }}
                  >
                    Address Line 2
                  </FormLabel>
                  <TextField
                    id="addressTwo"
                    variant="outlined"
                    onChange={(e) => setAddressTwo(e.target.value)}
                    sx={{ marginBottom: "15px" }}
                    // required={eventType === "In Person" ? true : false}
                    disabled={eventType === "Online" ? true : false}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "49%",
                  }}
                >
                  <FormLabel
                    htmlFor="PostalCode"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                      marginBottom: "10px",
                    }}
                  >
                    Postal code
                  </FormLabel>
                  <TextField
                    id="PostalCode"
                    variant="outlined"
                    onChange={(e) => setPostalCode(e.target.value)}
                    sx={{ marginBottom: "15px" }}
                    // required={eventType === "In Person" ? true : false}
                    disabled={eventType === "Online" ? true : false}
                  />
                </Box>
              </Box>
            </>
          )}
          {eventType === "Online" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="nameOnlineLink"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Online Link{eventType === "Online" && "*"}
                </FormLabel>
                <TextField
                  id="nameOnlineLink"
                  variant="outlined"
                  type="url"
                  value={eventOnlineLink}
                  onChange={(e) => setEventOnlineLink(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                  required={
                    eventType === "Online" && eventOnlineLink.length <= 8
                      ? true
                      : false
                  }
                  disabled={eventType === "Online" ? false : true}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="platform"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Platform{eventType === "Online" && "*"}
                </FormLabel>
                <TextField
                  id="platform"
                  variant="outlined"
                  type="text"
                  onChange={(e) => setPlatform(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                  required={eventType === "Online" ? true : false}
                  disabled={eventType === "Online" ? false : true}
                />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "49%" }}
              >
                <FormLabel
                  htmlFor="passcode"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Passcode
                </FormLabel>
                <TextField
                  id="passcode"
                  variant="outlined"
                  type="text"
                  onChange={(e) => setPasscode(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                  // required={eventType === "Online" ? true : false}
                  disabled={eventType === "Online" ? false : true}
                />
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="ename"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                <span> Contact Name(Optional) </span>
              </FormLabel>
              {/* <TextField
              id="ename"
              variant="outlined"
              sx={{ marginBottom: "15px" }}
              onChange={(e) => setConatactName(e.target.value)}
            /> */}

              {/* <Autocomplete
              disablePortal
              id="eventConatctname"
              options={contactName}
              sx={{ width: "100%" }}
              getOptionLabel={(option) => option.userFullName}
              onChange={handleSelectContactName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search contact name"
                  onChange={(e) => ContactNameLookup(e)}
                />
              )}
            /> */}
              {/* <Autocomplete
              id="eventConatctname"
              clearText="true"
              options={contactName}
              freeSolo
              getOptionLabel={(option) => option.fullName}
              onChange={handleSelectContactName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={""}
                  onChange={(e) => ContactNameLookup(e)}
                />
              )}
            /> */}
              <Autocomplete
                value={conatctName}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setConatactName({
                      fullName: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setConatactName({
                      fullName: newValue.inputValue,
                    });
                  } else {
                    setConatactName(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.fullName
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      fullName: `Add contact name  "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={contactName}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.fullName;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.fullName}</li>
                )}
                sx={{ width: "100%" }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Free solo with text demo"
                    onChange={(e) => ContactNameLookup(e)}
                  />
                )}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="phone"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Contact Phone Number(Optional)
              </FormLabel>
              <TextField
                id="phone"
                variant="outlined"
                inputProps={{ maxLength: "15", minLength: "10" }}
                sx={{ marginBottom: "15px" }}
                onChange={(e) => setConatactNumber(e.target.value)}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="email"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Email(Optional)
              </FormLabel>
              <TextField
                id="email"
                variant="outlined"
                sx={{ marginBottom: "15px" }}
                onChange={(e) => setEventEmail(e.target.value)}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="eveWeb"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Website Link(Optional)
              </FormLabel>
              <TextField
                id="eveWeb"
                // type="url"
                value={evnetWebsiteLink}
                variant="outlined"
                sx={{ marginBottom: "15px" }}
                onChange={(e) => setEventWebsiteLink(e.target.value)}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", width: "49%" }}>
              {previewImage && (
                <div style={{ width: "100%" }}>
                  <img
                    src={previewImage}
                    style={{ width: "100px", height: "100px" }}
                    alt="preview"
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <FormLabel
                  htmlFor="nameOnlineLink"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "roboto",
                    marginBottom: "10px",
                  }}
                >
                  Add Image
                </FormLabel>

                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Select
                  <VisuallyHiddenInput
                    type="file"
                    accept=".png,.jpg,.jpeg,.webp"
                    onChange={(event) => {
                      handleImageChange(event);
                      setImage(event.target.files[0]);
                    }}
                    title="Image"
                  />
                </Button>
              </div>
            </Box>
            {/* Venue Map PDF File Upload */}
            {eventType === "In Person" && (
              <Box sx={{ display: "flex", width: "49%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <FormLabel
                    htmlFor="nameOnlineLink"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "roboto",
                      marginBottom: "10px",
                    }}
                  >
                    Add Venue Map PDF
                  </FormLabel>

                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    disabled={false}
                  >
                    Select
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf"
                      onChange={(event) => {
                        setVenueMapPDFImage(event.target.files[0]); // Main File
                        setVenueMapPDFImageURL(
                          URL.createObjectURL(event.target.files[0])
                        );
                        setVenueMapPDFImageName(event.target.files[0].name);
                      }}
                      title="Image"
                    />
                  </Button>
                </div>
                <div style={{ width: "100%", paddingLeft: "200px" }}>
                  {venueMapPDFImageName && <h5>{venueMapPDFImageName}</h5>}
                  {venueMapPDFImageURL && (
                    <a href={venueMapPDFImageURL} target="_blank">
                      Preview
                    </a>
                  )}
                </div>
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "98%" }}>
            <FormLabel
              htmlFor="text"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "roboto",
                marginBottom: "10px",
              }}
            >
              Description*
            </FormLabel>
            <TextareaAutosize
              id="text"
              aria-label="minimum height"
              minRows={10}
              required
              onChange={(e) => setEventDescription(e.target.value)}
            />
          </Box>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              // sx={{
              //   width: "250px",
              //   alignSelf: "center",
              //   marginTop: "15px",
              //   backgroundColor: "#D9D9D9",
              //   color: "white",
              //   padding: "10px",
              // }}
              style={{
                width: "250px",
                backgroundColor: "#ff6600",
                color: "white",
                padding: "10px",
                marginTop: "15px",
                // cursor: `${loading ? `progress` : `pointer`}`,
              }}
              type="submit"
              disabled={loading ? true : false}
            >
              {loading ? (
                <>
                  <span>Event Adding</span>
                  <CircularProgress
                    size={20}
                    style={{ marginLeft: "5px", color: "white" }}
                  />
                </>
              ) : (
                "Add Event"
              )}
            </Button>
          </div>
        </form>
        {/* </FormGroup> */}
      </Box>
    </>
  );
};

export default EventCreate;
