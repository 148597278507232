import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CosnumerProductViewModalForView({
  modalOpen,
  setModalopen,
  singleData,
}) {
  const dateFormat = (data) => {
    const date = new Date(data);

    // Format the date in your desired format
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  return (
    <React.Fragment>
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        // keepMounted
        onClose={() => setModalopen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Product View"}</DialogTitle>
        <DialogContent style={{ width: "100%" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ width: "100%" }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {/* {singleData &&
                  dataArray?.map((data) => ( */}
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Location:</Item>
                  <Item>
                    {/* {" "}
                    {singleData?.location?.locationName
                      ? singleData?.location?.locationName
                      : "Null"} */}
                    {singleData?.parentlocation
                      ? `${singleData?.parentlocation}/${singleData?.location?.locationName}`
                      : singleData?.location
                      ? singleData?.location?.locationName
                      : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Product:</Item>
                  <Item>
                    {" "}
                    {singleData?.productName ? singleData?.productName : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Model Number:</Item>
                  <Item>
                    {singleData?.modelNumber ? singleData?.modelNumber : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Manufacturer Organization:</Item>
                  <Item>
                    {singleData?.manufacturerName
                      ? singleData?.manufacturerName
                      : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Status:</Item>
                  <Item>
                    {singleData?.status ? singleData?.status : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Version:</Item>
                  <Item>
                    {singleData?.version ? singleData?.version : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Serial Number:</Item>
                  <Item>
                    {singleData?.serialNumber
                      ? singleData?.serialNumber
                      : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Category:</Item>
                  <Item>
                    {singleData?.category ? singleData?.category?.name : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Asset Id:</Item>
                  <Item>
                    {singleData?.assetIdTagNumber
                      ? singleData?.assetIdTagNumber
                      : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Purchase Date:</Item>
                  <Item>
                    {singleData?.purchaseDate &&
                      dateFormat(singleData?.purchaseDate)}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Purchase Price:</Item>
                  <Item>
                    {singleData?.purchasePrice
                      ? singleData?.purchasePrice
                      : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Purchase Price Type:</Item>
                  <Item>
                    {singleData?.purchasePriceType === "Select"
                      ? "Null"
                      : singleData?.purchasePriceType}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Vendor Organization:</Item>
                  <Item>
                    {singleData?.vendorOrganization
                      ? singleData?.vendorOrganization
                      : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Warranty End Date:</Item>
                  <Item>
                    {singleData?.warrantyEndDate &&
                      dateFormat(singleData?.warrantyEndDate)}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Warranty Start Date:</Item>
                  <Item>
                    {singleData?.warrantyStartDate &&
                      dateFormat(singleData?.warrantyStartDate)}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Active:</Item>
                  <Item>{singleData?.active === true ? "True" : "False"}</Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Consultant Organization:</Item>
                  <Item>
                    {singleData?.consultantOrganization
                      ? singleData?.consultantOrganization
                      : "Null"}
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Item>Integrator Organization:</Item>
                  <Item>
                    {singleData?.integratorOrganization
                      ? singleData?.integratorOrganization
                      : "Null"}
                  </Item>
                </Grid>

                {/* ))} */}
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setOpen(false)}>Disagree</Button> */}
          <Button onClick={() => setModalopen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
