import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { ImsProductUpdate, getIMSproductEditData } from "api/api";
import { useState } from "react";

import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",

  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  columnGap: "10px",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
}));
export default function ImsProductEditModal({
  setOpenModal,
  openModal,
  eventId,
  setUpdated,
  updated,
}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({
    id: "",
    manufacturerName: "",
    modelNumber: "",
    productName: "",
    serialNumber: "",
  });

  const handleClose = () => {
    setEventData({
      id: "",
      name: "",
      mode: "",
      category: "",
      privacy: "",
      description: "",
      start: "",
      end: "",
      contact: "",
      customeventCategory: "",
      website: "",
    });
    setOpenModal(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    await ImsProductUpdate(eventData)
      .then((res) => {
        setUpdated(!updated);
        toast.success("Event updated");
        handleClose();
      })
      .catch((err) => {
        toast.error("Something went wrong please try again!");
      });
  };

  const getEventDetailsById = async (eventId) => {
    setLoading(true);
    await getIMSproductEditData(eventId)
      .then((res) => {
        setData(res.data);
        setEventData({
          id: res.data?.id,
          manufacturerName: res.data?.manufacturerName,
          modelNumber: res.data?.modelNumber,
          productName: res.data?.productName,
          serialNumber: res.data?.serialNumber,
        });
      })
      .catch((err) => {});
    setLoading(false);
  };

  useEffect(() => {
    // getEventDetailsById(eventId);
  }, [eventId, openModal]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          component="form"
          autoComplete="off"
          onSubmit={(e) => handleEditSubmit(e)}
        >
          {!loading ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <h2>Edit Product </h2>
                <Typography
                  variant="h4"
                  id="user-detail-modal"
                  align="center"
                  color={"orange"}
                  onClick={() => handleClose()}
                  style={{ cursor: "pointer", color: "red", width: "auto" }}
                >
                  Close
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  //   height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Item>
                      <TextField
                        id="outlined-basic"
                        label="Manufacturer Name"
                        variant="outlined"
                        required
                        value={eventData?.manufacturerName}
                        onChange={(e) =>
                          setEventData({
                            ...eventData,
                            manufacturerName: e.target.value,
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <TextField
                        id="outlined-basic"
                        label="Model Number"
                        variant="outlined"
                        value={eventData?.modelNumber}
                        onChange={(e) =>
                          setEventData({
                            ...eventData,
                            modelNumber: e.target.value,
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Item>
                  </Grid>

                  <Grid item xs={6}>
                    <Item>
                      <TextField
                        id="outlined-basic"
                        label="Product Name"
                        variant="outlined"
                        value={eventData?.productName}
                        onChange={(e) =>
                          setEventData({
                            ...eventData,
                            productName: e.target.value,
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Item>
                  </Grid>

                  <Grid item xs={6}>
                    <Item>
                      <TextField
                        id="outlined-basic"
                        label="Serial Number"
                        value={eventData?.serialNumber}
                        onChange={(e) =>
                          setEventData({
                            ...eventData,
                            serialNumber: e.target.value,
                          })
                        }
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Item>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ marginTop: "20px" }}
                  // disabled
                >
                  update
                </Button>
              </div>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Loading.....</span>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}
