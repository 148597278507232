import React, { useState } from "react";
import Papa from "papaparse";
import {
  createMultipleOrganizationWithBlankUser,
  getAllCategory,
  getCsvCategoryList,
} from "api/api";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useEffect } from "react";

const CsvReaderData = () => {
  const [csvData, setCsvData] = useState([]);
  const [misMatchError, setMismatchError] = useState();
  const [total_no_of_data, setTotalNoOfData] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [categories,setCategories] = useState([]);
  
  const [headerData, setHeaderData] = useState([
    "organizationName",
    "city",
    "state",
    "Logo",
    "organizationLogoLink",
    "numberEmployee",
    "formationYear",
    "Makes",
    "Sells",
    "Integrates",
    "Repairs",
    "Consults",
    "Installs",
    "Rents",
  ]);
  const [csvFile, setCsvFile] = useState();
  // const sampleData = [
  //   {
  //     organizationName: "John Doe",
  //     city: "city",
  //     state: "state",
  //     Logo: "Logo",
  //     organizationLogoLink:
  //       "https://assets.2k.com/1a6ngf98576c/4wOVLHKVnz4AshkUWRTUEY/f58184233da1f259119b3a0186dcfd88/NBA2K-LOGO_2xk.png",
  //     numberEmployee: "1000",
  //     formationYear: "1999",
  //     Manufacturer: "Manufacturer",
  //     Reseller: "Reseller",
  //     Integrator: "Integrator",
  //     Service_Provider: "Service_Provider",
  //     Consultant: "Consultant",
  //   },
  // ];

  // const [response, setResponse] = useState({});

  const CategoryHandler = async () => {
    await getCsvCategoryList()
      .then((res) => {
        setCategoryData(res.data);
        console.log("category list data", res.data);
      })
      .catch((err) => {
        console.log("category list error", err);
      });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
    setTotalNoOfData();
    setMismatchError();

    Papa.parse(file, {
      complete: (result) => {
        // alert('parsing completed1')
        // 'result.data' contains the parsed data as an array of objects
        const parsedData = result.data;
        console.log("parse data", parsedData);
        if (parsedData[0].length > 13) {
          alert("Format mismatched, please check and retry!");
          return;
        }

        const checkHeadersFormat = parsedData[0]?.filter(
          (data) => !headerData.includes(data)
        );
        if (checkHeadersFormat.length > 0) {
          setMismatchError(checkHeadersFormat);
          return;
        }

        CategoryHandler();
        setTotalNoOfData(parsedData.length);
      },
    });
  };

  // Boolean return
  const validateTagWithCategories = async(data)=>{
    // console.log('validating ',data);

    const response = await getAllCategory();
    const categories = response?.data;
  
    if(categories?.length>0){
      
    }else{
      toast.error("Not found categories!");
      return false;
    }

    function getCategoryByName(name){
      for(const item of categories){
        if(item.name===name){
          console.log('category matched',name);
          return item;
        }
      }
      return null;
    }

    let minimumOneCategorySelected = false;

    for(const {Consults,Installs,Integrates,Makes,Rents,Repairs,Sells,city,formationYear,numberEmployee
,organizationLogoLink,organizationName,state} of data){

      // Consult tag's category validation
        for(const cat of Consults){
          const category = getCategoryByName(cat);
          if(!category){
            toast.error(`Invalid category provided - ${cat}`);
            return false;
          }

          if(category?.supportedTags.includes('Consults')){
            minimumOneCategorySelected = true;
          }else{
            toast.error(`Category ${cat} not supported for Consults tag.`);
            return false;
          }

        }

        for(const cat of Makes){
          const category = getCategoryByName(cat);
          if(!category){
            toast.error(`Invalid category provided - ${cat}`);
            return false;
          }

          if(category?.supportedTags.includes('Makes')){
            minimumOneCategorySelected = true;
          }else{
            toast.error(`Category ${cat} not supported for Makes tag.`);
            return false;
          }

        }

        for(const cat of Sells){
          const category = getCategoryByName(cat);
          if(!category){
            toast.error(`Invalid category provided - ${cat}`);
            return false;
          }

          if(category?.supportedTags.includes('Sells')){
            minimumOneCategorySelected = true;
          }else{
            toast.error(`Category ${cat} not supported for Sells tag.`);
            return false;
          }

        }

        for(const cat of Installs){
          const category = getCategoryByName(cat);
          if(!category){
            toast.error(`Invalid category provided - ${cat}`);
            return false;
          }

          if(category?.supportedTags.includes('Installs')){
            minimumOneCategorySelected = true;
          }else{
            toast.error(`Category ${cat} not supported for Installs tag.`);
            return false;
          }

        }

        for(const cat of Repairs){
          const category = getCategoryByName(cat);
          if(!category){
            toast.error(`Invalid category provided - ${cat}`);
            return false;
          }

          if(category?.supportedTags.includes('Repairs')){
            minimumOneCategorySelected = true;
          }else{
            toast.error(`Category ${cat} not supported for Repairs tag.`);
            return false;
          }

        }

        for(const cat of Integrates){
          const category = getCategoryByName(cat);
          if(!category){
            toast.error(`Invalid category provided - ${cat}`);
            return false;
          }

          if(category?.supportedTags.includes('Integrates')){
            minimumOneCategorySelected = true;
          }else{
            toast.error(`Category ${cat} not supported for Integrates tag.`);
            return false;
          }

        }

        for(const cat of Rents){
          const category = getCategoryByName(cat);
          if(!category){
            toast.error(`Invalid category provided - ${cat}`);
            return false;
          }

          if(category?.supportedTags.includes('Rents')){
            minimumOneCategorySelected = true;
          }else{
            toast.error(`Category ${cat} not supported for Rents tag.`);
            return false;
          }

        }

        if(!minimumOneCategorySelected){
          toast.error(`Select minimum 1 category for organization ${organizationName}.`);
          return false;
        }

        return true; // if all category validation is perfect with supported tags.

    }
  }

  const upload = async () => {
    if (!csvFile) {
      alert("Add file first");
      return;
    }
    setTotalNoOfData();
    Papa.parse(csvFile, {
      complete: async (result) => {
        // 'result.data' contains the parsed data as an array of objects
        const parsedData = result.data;
        let data = [];

        setCsvData(parsedData);

        // You can now use 'parsedData' in your component or pass it to a function, etc.

        // Consultant
        // Integrator  Manufacturer   Reseller  Service_Provider
        // "Makes",
        // "Sells",
        // "Integrates",
        // "Repairs",
        // "Consults",
        // "Installs",
        for (const element of parsedData) {
          const obj = {
            Consults: element.Consults
              ? element.Consults.split(", ")
              : [],
            Integrates: element.Integrates
              ? element.Integrates.split(", ")
              : [],
            Makes: element.Makes
              ? element.Makes.split(", ")
              : [],
            Sells: element.Sells ? element.Sells.split(", ") : [],
            Repairs: element.Repairs
              ? element.Repairs.split(", ")
              : [],
            Installs: element.Installs
              ? element.Installs.split(", ")
              : [],
            Rents:element.Rents ? element.Rents.split(", "):[],
            organizationName: element.organizationName,
            city: element.city,
            state: element.state,
            organizationLogoLink: element.organizationLogoLink,
            numberEmployee: isNaN(element.numberEmployee)
              ? 0
              : Number(element.numberEmployee),
            formationYear: isNaN(element.formationYear)
              ? 0
              : Number(element.formationYear),
          };

          data.push(obj);
        }

        const filteredData = [];
        const Consults = [];
        const Sells = [];
        const Makes = [];
        const Integrates = [];
        const Repairs = [];
        const Installs = [];
        const Rents = [];

        for (const element of data) {
          if (element.organizationName) {
            filteredData.push(element);
          }
          if (element.Consults.length > 0) {
            Consults.push(element.Consults);
          }
          if (element.Sells.length > 0) {
            Sells.push(element.Sells);
          }
          if (element.Makes.length > 0) {
            Makes.push(element.Makes);
          }
          if (element.Integrates.length > 0) {
            Integrates.push(element.Integrates);
          }
          if (element.Repairs.length > 0) {
            Repairs.push(element.Repairs);
          }
          if (element.Installs.length > 0) {
            Installs.push(element.Installs);
          }
          if (element.Rents.length > 0) {
            Rents.push(element.Installs);
          }
        }

        // Category validation
        if(!(await validateTagWithCategories(filteredData))){
          return;
        }

        // Minimum 1 category must be selected


        // console.log(filteredData);
        toast.success("Import Started...");
        // return;
        await createMultipleOrganizationWithBlankUser(filteredData)
          .then((response) => {
            toast.success("Data import success");
            // alert("Data import started in the background.");
            // setResponse(response.data)
          })
          .catch((err) => {
            toast.error("Error");
          });
      },
      header: true, // Assumes the first row of the CSV contains headers
    });
  };

  // useEffect(() => {
  //   CategoryHandler();
  // }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          // margin: "30px",
          flexDirection: "column",
        }}
      >
        {misMatchError && (
          <span
            style={{
              height: "auto",
              backgroundColor: "red",
              marginBottom: "20px",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              color: "white",
              fontSize: "15px",
              fontWeight: "bold",
              paddingLeft: "40px",
              paddingRight: "40px",
              padding: "5px",
            }}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-exclamation-circle"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M12 9v4" />
              <path d="M12 16v.01" />
            </svg>
            <span style={{ marginLeft: "5px" }}>Format Mismatched -</span>
            {misMatchError?.map((data, idx) => (
              <span style={{ marginLeft: "10px" }}>
                {data} {misMatchError.length !== idx + 1 ? "," : ""}
              </span>
            ))}
          </span>
        )}
        <div>
          <input type="file" onChange={handleFileUpload} accept=".csv" />

          <Button
            variant="contained"
            // onClick={(searcValueByuser) => HandleApplyFilter()}
            onClick={() => upload()}
            style={{
              backgroundColor:
                misMatchError || csvFile === undefined ? "grey" : "blue",
              color: "white",
            }}
            disabled={misMatchError || csvFile === undefined ? true : false}
          >
            Upload
          </Button>
        </div>
        {/* <button
        style={{ border: "none", padding: "10px", backgroundColor: "blue" }}
        onClick={upload}
      >
        Upload
      </button> */}

        {/* {
          response.totalRecords  ? <>
              {
                JSON.stringify(response)
              }            
          </>:''
      } */}

        {/* {csvData.length > 0 && (
        <div>
          <h2>Parsed Data</h2>
          <table>
            <thead>
              <tr>
                {Object.keys(csvData[0]).map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {csvData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )} */}
        {total_no_of_data && (
          <span style={{ marginTop: "20px" }}>
            Total No. of Data : {total_no_of_data-1}
          </span>
        )}
      </div>
    </>
  );
};

export default CsvReaderData;
