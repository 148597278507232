import { getIMSproduct, getIMSproductlookupData } from "api/api";
import React from "react";
import { useState } from "react";
import ImsProductEditModal from "ui-component/imsFlow/ImsProductEdit";
import ImsProductTable from "ui-component/imsFlow/ImsProductTable";
import ImsProductViewModal from "ui-component/imsFlow/ImsProductViewmodal";

export default function ImsProduct() {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setEditOpenModal] = useState(false);
  const [eventId, setImsProductId] = useState();
  const [updated, setUpdated] = useState(false);
  return (
    <div>
      <ImsProductTable
        getIMSproduct={getIMSproduct}
        getIMSproductlookupData={getIMSproductlookupData}
        setImsProductId={setImsProductId}
        setOpenModal={setOpenModal}
        openModal={openModal}
        setEditOpenModal={setEditOpenModal}
        setUpdated={setUpdated}
        updated={updated}
      />
      <ImsProductViewModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        eventId={eventId}
      />
      <ImsProductEditModal
        setOpenModal={setEditOpenModal}
        openModal={openEditModal}
        eventId={eventId}
        setUpdated={setUpdated}
        updated={updated}
      />
    </div>
  );
}
