import { Autocomplete, TextField } from "@mui/material";
import { SearchEvent, fetchOrgDeleteLogs, getFilterData } from "api/api";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const headers = [
  { label: "Event Name", key: "eventName" },
  { label: "Event Category", key: "eventCategory" },
  { label: "Event Description", key: "eventDescription" },
  { label: "Start Date", key: "startDateTime" },
  { label: "End Date", key: "endDateTime" },
  { label: "Timezone", key: "eventTimeZone" },
  { label: "Event Privacy", key: "eventPrivacy" },
  { label: "Event Mode", key: "eventMode" },
];

export default function OrgDeleteTable({
  setOpenModal,
  setEventId,
  setEditOpenModal,
  updated,
}) {
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);

  const [pending, setPending] = React.useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [deleteStatus, setDeleteStatus] = useState("all");
  const [periodSelect, setPeriodSelect] = useState("All");
  const [searcgOption, setSearchOption] = useState([]);
  const [searcValueByuser, setSearchValueByuser] = useState("");

  const SearchHandle = async (e) => {
    if (e !== 0) {
      await SearchEvent(e)
        .then((res) => {
          setSearchOption(res.data);
        })
        .catch((err) => {});
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleEventMode = (e) => {
    setDeleteStatus(e);
  };
  const handleEventPeriod = (e) => {
    setPeriodSelect(e);
  };

  const HandleApplyFilter = async () => {
    // const findFlag = searcgOption?.find((f) => f.name === searcValueByuser);
    console.log("searcValueByuser", searcValueByuser);
    setPending(true);
    await fetchOrgDeleteLogs(searcValueByuser, deleteStatus, perPage, page)
      .then((res) => {
        console.log("org delete Logs", res.data);
        setData(res.data.logs);
        setTotalRows(res.data.count);
      })
      .catch((err) => {});
    setPending(false);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const ITEM_HEIGHT = 30;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 150,
          //   marginTop:
        },
      },
    };

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", columnGap: "30px" }}>
          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={searcgOption}
            getOptionLabel={(option) => option.name}
            onInputChange={(e) => {
              SearchHandle(e.target.value);
            }}
            onChange={(e) => {
              setSearchValueByuser(e.target.outerText);
            }}
            style={{
              display: "flex",
              width: "400px",
              justifyContent: "flex-start",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by organization name  or event name"
              />
            )}
          /> */}
          <TextField
            id="outlined-basic"
            label="Search by organization name"
            variant="outlined"
            style={{
              display: "flex",
              width: "400px",
              justifyContent: "flex-start",
            }}
            onChange={(e) => {
              setSearchValueByuser(e.target.value);
            }}
          />

          <div style={{ display: "flex", columnGap: "20px" }}>
            <FormControl
              sx={{
                width: 150,
              }}
            >
              <InputLabel id="demo-multiple-name-label">
                Delete Status
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                label="Event Mode"
                // multiple
                required
                value={deleteStatus}
                onChange={(e) => handleEventMode(e.target.value)}
                MenuProps={MenuProps}
              >
                <MenuItem key={"Event Filter"} disabled>
                  {"Logs Filter"}
                </MenuItem>
                <MenuItem key={"all"} value={"all"}>
                  {"All"}
                </MenuItem>
                <MenuItem key={"success"} value={"success"}>
                  {"Success"}
                </MenuItem>
                <MenuItem key={"idle"} value={"idle"}>
                  {"Idle"}
                </MenuItem>
                <MenuItem key={"failed"} value={"failed"}>
                  {"Failed"}
                </MenuItem>
              </Select>
              {/*  */}
            </FormControl>
            {/* <FormControl
              sx={{
                width: 150,
              }}
            >
              <InputLabel id="demo-multiple-name-label">
                Event Period
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                label="Event Period"
                required
                value={periodSelect}
                onChange={(e) => handleEventPeriod(e.target.value)}
                MenuProps={MenuProps}
                placeholder="Status Filter"
              >
                <MenuItem key={"Status Filter"} disabled>
                  {"Status Filter"}
                </MenuItem>
                <MenuItem key={"All"} value={"All"}>
                  {"All"}
                </MenuItem>
                <MenuItem key={"Previous Event"} value={"Previous"}>
                  {"Previous Event"}
                </MenuItem>
                <MenuItem key={"Ongoing Event"} value={"Ongoing"}>
                  {"Ongoing Event"}
                </MenuItem>
                <MenuItem key={"Upcoming Event"} value={"Upcoming"}>
                  {"Upcoming Event"}
                </MenuItem>
              </Select>
            </FormControl> */}
          </div>
        </div>

        <div>
          <Button
            variant="contained"
            onClick={(searcValueByuser) => HandleApplyFilter()}
            style={{ backgroundColor: "rgb(255, 102, 0)", color: "white" }}
          >
            Apply filter
          </Button>
        </div>
      </div>
    );
  }, [deleteStatus, periodSelect, SearchHandle, searcValueByuser]);

  const handleDateTime = (value) => {
    // const date = new Date(value);
    // const date = moment.tz(value, moment.tz.guess());

    const date = moment(value);
    const formattedDate = date.format("DD-MMM-YY HH:mm");
    // const formattedDate = date.utc().format("DD-MMM-YY HH:mm");

    return formattedDate;
    // return moment(date).format("DD-MMM-YY HH:mm");
  };

  const actionsMemo = React.useMemo(() => {
    return (
      <CSVLink data={data} headers={headers} filename={"events"}>
        <Button
          variant="outlined"
          style={{
            padding: "5px 20px",
            // border: "none",
            backgroundColor: "",
          }}
        >
          Export Events
        </Button>
      </CSVLink>
    );
  }, []);

  useEffect(() => {
    setColumns([
      {
        name: "Organization Name",
        selector: (row) => row.organizationName,
        sortable: false,
      },
      {
        name: "Deleted Date",
        selector: (row) => handleDateTime(row.deletedDate),
        sortable: false,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
      },
      //   {
      //     name: "Timezone",
      //     selector: (row) => row.timeZone.timeZone,
      //     sortable: true,
      //   },

      //   {
      //     name: "Start Date",
      //     selector: (row) => handleDateTime(row.startDateTime),
      //     sortable: true,
      //   },
      //   {
      //     name: "End Date",
      //     selector: (row) => handleDateTime(row.endDateTime),
      //     sortable: true,
      //   },

      // {
      //   name: "Event Privacy",
      //   selector: (row) => row.eventPrivacy,
      //   sortable: true,
      // },

      //   {
      //     name: "Mode",
      //     selector: (row) => row.eventMode,
      //     sortable: true,
      //   },
      // {
      //   name: "View",
      //   cell: (row) => (
      //     <Button
      //       variant="outlined"
      //       onClick={() => {
      //         setOpenModal(true);
      //         setEventId(row.id);
      //       }}
      //     >
      //       View
      //     </Button>
      //   ),
      //   ignoreRowClick: true,
      //   allowOverflow: true,
      //   button: true,
      // },
      //   {
      //     name: "Edit",
      //     cell: (row) => (
      //       <Button
      //         variant="contained"
      //         onClick={() => {
      //           setEditOpenModal(true);
      //           setEventId(row.id);
      //         }}
      //       >
      //         Edit
      //       </Button>
      //     ),
      //     ignoreRowClick: true,
      //     allowOverflow: true,
      //     button: true,
      //   },
    ]);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    HandleApplyFilter();
  }, [page, perPage, updated]);

  return (
    <>
      <DataTable
        title={<p>{"Organization Delete Logs"}</p>}
        columns={columns}
        data={data}
        progressPending={pending}
        pagination
        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        // expandableRows
        // expandableRowsComponent={ExpandableRowComponent}
        subHeaderComponent={subHeaderComponentMemo}
        //   selectableRows
        persistTableHead
        fixedHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        // pointerOnHover
        // actions={actionsMemo}
        // onClick={() => console.log("row clicked")}
      />
    </>
  );
}
