import * as React from "react";
import { Pagination, SpeedDial } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { useState } from "react";
import { faqData, faqQuestionDelete } from "api/api";
import SearchSection from "layout/MainLayout/Header/SearchSection";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FaqModal from "ui-component/FaqModal";
import FaqDeleteQuestion from "./faqDelete";

export default function FaqComponent() {
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalPage, setTotalPage] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const [createUpdateModalOpen, setUpdateCreateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [questionData, setQuestionData] = useState([]);

  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    getFaqData();
  }, [page]);

  useEffect(() => {
    if (searchText.length < 1) {
      getFaqData();
    }
  }, [searchText]);

  const getFaqData = async () => {
    const res = await faqData(searchText, page, 5);
    if (res?.data.data && res.data.data) {
      setUserData(res?.data.data);
      setTotalPage(Math.ceil(res?.data.count / 5));
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const faqDetails = async () => {
    setIsButtonDisabled(true);
    if (searchText.length > 0 && !isButtonDisabled) {
      await faqData(searchText, page, 4)
        .then((res) => {
          setTotalPage(Math.ceil(res?.data.count / 4));
          setUserData(res?.data.data);
          setIsButtonDisabled(false);
        })
        .catch((err) => {
          setIsButtonDisabled(false);
        });
    }
    setIsButtonDisabled(false);
  };

  const handleModalOpen = (type, id, data) => {
    setModalType(type);
    setQuestionId(id);
    setQuestionData(data);
    setUpdateCreateModalOpen(true);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDeleteFaq = (deletefaqId) => {
    setDeleteId(deletefaqId);
    setDeleteModalOpen(true);
  };

  return (
    <>
      <SearchSection
        searchUser={faqDetails}
        setSearchText={setSearchText}
        searchText={searchText}
        isButtonDisabled={isButtonDisabled}
        setIsButtonDisabled={setIsButtonDisabled}
        getData={getFaqData}
        placeholder={"Search by Question"}
      />

      <br></br>
      <div style={{ width: "100%", height: "auto" }}>
        {userData?.map((data, id) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              rowGap: "10px",
            }}
          >
            <div style={{ width: "80%", rowGap: "10px", marginBottom: "10px" }}>
              <Accordion
                expanded={expanded === `panel${id + 1}`}
                onChange={handleAccordionChange(`panel${id + 1}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <p>{data?.question}</p>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#DDE5F6" }}>
                  <p>{data?.answer}</p>
                </AccordionDetails>
              </Accordion>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                width: "20%",
                columnGap: "10px",
              }}
            >
              <Button
                onClick={() => handleModalOpen("update", data?.id, data)}
                color="info"
              >
                Update
              </Button>
              <Button onClick={() => handleDeleteFaq(data?.id)} color="error">
                Delete
              </Button>
            </div>
          </div>
        ))}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <Pagination
            count={totalPage}
            color="primary"
            onChange={handleChange}
          />
        </div>
      </div>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{
          position: "absolute",
          bottom: "2%",
          right: "3%",
        }}
        onClick={() => handleModalOpen("create", "", [])}
        icon={<AddOutlinedIcon size={"small"} />}
        // onClick={() => alert("I
        // onClick={() => setCreateModal(true)}
      ></SpeedDial>
      <FaqModal
        setUpdateCreateModalOpen={setUpdateCreateModalOpen}
        createUpdateModalOpen={createUpdateModalOpen}
        width={"100%"}
        modalType={modalType}
        id={questionId}
        data={questionData}
        getFaqData={getFaqData}
      />
      <FaqDeleteQuestion
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        setPage={setPage}
        id={deleteId}
        getFaqData={getFaqData}
        setUserData={setUserData}
      />
    </>
  );
}
