import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// project imports
import NavItem from "../NavItem";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import { MENU_OPEN } from "store/actions";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const customization = useSelector((state) => state.customization);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  console.log("selected", selected);
  const handleClick = () => {
    setOpen(!open);
    console.log("menu id", menu);
    const id = menu.id;
    setSelected(!selected ? menu.id : null);

    dispatch({ type: MENU_OPEN, id });
    //   if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    // };
  };

  //
  let itemTarget = "_self";
  console.log("menu url", menu.url);
  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={menu?.url} target={itemTarget} />
    )),
  };

  //

  // useEffect(() => {
  //   if (document.location.pathname === "/dashboard") {
  //     dispatch({ type: MENU_OPEN, id: "default" });
  //   }
  //   if (document.location.pathname === "/dashboard/organization-list") {
  //     dispatch({ type: MENU_OPEN, id: "sample-page" });
  //   }
  //   if (document.location.pathname === "/dashboard/user-export") {
  //     dispatch({ type: MENU_OPEN, id: "User Export" });
  //   }
  //   if (document.location.pathname === "/dashboard/feature-vendor-list") {
  //     dispatch({ type: MENU_OPEN, id: "Feature Vendor" });
  //   }
  //   if (document.location.pathname === "/dashboard/category-management") {
  //     dispatch({ type: MENU_OPEN, id: "Category Management" });
  //   }
  //   if (document.location.pathname === "/dashboard/message-moderation") {
  //     dispatch({ type: MENU_OPEN, id: "Message Moderation" });
  //   }
  //   if (document.location.pathname === "/dashboard/connection-moderation") {
  //     dispatch({ type: MENU_OPEN, id: "Connection Moderation" });
  //   }
  //   if (document.location.pathname === "/dashboard/event/management") {
  //     dispatch({ type: MENU_OPEN, id: "Event Management" });
  //   }
  //   if (document.location.pathname === "/dashboard/client/management") {
  //     dispatch({ type: MENU_OPEN, id: "Client Management" });
  //   }
  //   if (document.location.pathname === "/dashboard/vendor/management") {
  //     dispatch({ type: MENU_OPEN, id: "Vendor Management" });
  //   }
  //   if (document.location.pathname === "/dashboard/consultant/management") {
  //     dispatch({ type: MENU_OPEN, id: "Consultant Management" });
  //   }
  //   if (document.location.pathname === "/dashboard/ims-flow-management") {
  //     dispatch({ type: MENU_OPEN, id: "IMS Flow Management" });
  //   }
  //   // const currentIndex = document.location.pathname
  //   //   .toString()
  //   //   .split("/")
  //   //   .findIndex((id) => id === item.id);
  //   // if (currentIndex > -1) {
  //   //   dispatch({ type: MENU_OPEN, id: item.id });
  //   // }
  //   // eslint-disable-next-line
  // }, []);

  //

  const checkOpenForParent = (child, id) => {
    child.forEach((item) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false);
    setSelected(null);
    if (menu.children) {
      menu.children.forEach((item) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        if (item.url === pathname) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children]);

  // menu collapse & item
  const menus = menu.children?.map((item) => {
    console.log("item", item);
    switch (item.type) {
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon
      strokeWidth={1.5}
      size="1.3rem"
      style={{ marginTop: "auto", marginBottom: "auto" }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  return (
    <>
      <ListItemButton
        // {...listItemProps}
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`,
        }}
        // selected={selected === menu.id}
        selected={customization.isOpen.findIndex((id) => id === menu.id) > -1}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: "auto", minWidth: !menu.icon ? 18 : 36 }}>
          {menuIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant={selected === menu.id ? "h5" : "body1"}
              color="inherit"
              sx={{ my: "auto" }}
            >
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        ) : (
          <IconChevronDown
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            position: "relative",
            "&:after": {
              content: "''",
              position: "absolute",
              left: "32px",
              top: 0,
              height: "100%",
              width: "1px",
              opacity: 1,
              background: theme.palette.primary.light,
            },
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
