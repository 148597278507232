import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import LoaderforRoster from "./LoaderforRoster";

export default function DataTable({
  data,
  setOpen,
  setIsValid,
  isValid,
  categoryAllData,
  setErrorCount,
  errorCount,
  setSingleProduct,
  uploadResponse,
  duplicateData,
  loading,
}) {
  const Validation = (data, id) => {
    if (
      (data?.name?.length > 2 &&
        data?.name?.length <= 50 &&
        data?.model?.length <= 50 &&
        data?.description?.length <= 2500 &&
        data?.summary?.length <= 100,
      data?.tags.length >= 1 &&
        categoryAllData?.filter((item) => item.includes(data?.category))
          .length > 0)
    ) {
      // const checkCategory = categoryAllData?.filter((item) =>
      //   item.includes(data.category)
      // );

      // if (isValid === true) {
      // setIsValid(false);
      // }
      // console.log("checkCategory", checkCategory);
      // if (checkCategory) {
      return true;
      // } else {
      //   if (isValid === true) {
      //     setIsValid(false);
      //   }
      //   console.log("error data inside if", data);
      //   return false;
      // }
    } else {
      setErrorCount((prev) => errorCount + 1);
    }
    if (isValid === true) {
      setIsValid(false);
    }
    console.log("error data", data);
    return false;
  };

  const handleModalOpen = (singleProductData) => {
    setSingleProduct(singleProductData);
    setOpen(true);
  };

  const DuplicateDataStatusHandle = (row) => {
    let status = "New"; // Initialize status as "Pending"
    for (const duplicate of duplicateData) {
      if (
        row.name === duplicate["Name/Model"].toString() &&
        row.modelNumber === duplicate["Version/Code"].toString() &&
        row.category === duplicate["category"]
      ) {
        status = "Existed";
        break;
      }
    }
    return status;
  };

  return (
    <>
      {loading ? (
        <LoaderforRoster />
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Name/Model</TableCell>
                <TableCell align="center">Version/Code</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">Status</TableCell>
                {uploadResponse?.length < 1 && (
                  <TableCell align="center">Valid / Error</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, id) => (
                <TableRow
                  key={id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {id + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <span style={{ marginTop: "15px" }}>{row.name}</span>
                  </TableCell>
                  <TableCell align="center">{row.modelNumber}</TableCell>
                  <TableCell align="center">{row.category}</TableCell>
                  <TableCell align="center">
                    {uploadResponse?.length > 0 ? (
                      uploadResponse[id]?.productUUID === row.productUUID && (
                        <span
                          style={{
                            color:
                              uploadResponse[id].status === "success"
                                ? "#2ee52e"
                                : uploadResponse[id].status === "updated"
                                ? "orange"
                                : "red",
                            fontWeight: "bolder",
                          }}
                        >
                          {uploadResponse[id].status}
                        </span>
                      )
                    ) : DuplicateDataStatusHandle(row) === "Existed" ? (
                      <span
                        style={{
                          color: "rgb(1, 67, 97)",
                          fontWeight: "bolder",
                        }}
                      >
                        {"Exist"}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#2ee52e",
                          fontWeight: "bolder",
                        }}
                      >
                        {"New"}
                      </span>
                    )}
                  </TableCell>

                  {uploadResponse?.length < 1 ? (
                    row.error === true ? (
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleModalOpen(row)}
                        >
                          View Error
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <span
                          style={{ color: "#2ee52e", fontWeight: "bolder" }}
                        >
                          Valid
                        </span>
                      </TableCell>
                    )
                  ) : (
                    ""
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
