import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
export default function DateAndTimePicker({
  label,
  setStartDateAndtime,
  start,
  end,
  setEndDateAndtime,
  editDisabled,
  defaultValue,
  disablePast,
  required,
  startDate,
  minStartDate,
  setMinStartDate,
}) {
  const [value, setValue] = React.useState();
  const [minDate, setMinDate] = useState();
  const HandleDateAndTime = (e, value) => {
    // setStartDateAndTime(dayjs(e));
    const utc = new Date(e).toJSON();
    // console.log("eee", new Date(e).toJSON().toLocaleString());

    //
    const dateString = e.$d;

    // console.log("dateString", dateString);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var localDateString = utc.toLocaleString("en-US", { timeZone: timeZone }); // Change 'America/New_York' to your local time zone
    // console.log("Local Time:", localDateString);

    // console.log("value", value);
    // console.log("original datestrinf", dateString);
    const dateObject = new Date(dateString).toISOString();

    // console.log("dateObject", dateObject);
    // Subtract the timezone offset to convert to UTC
    // const utcDate = new Date(
    //   dateObject.getTime() - dateObject.getTimezoneOffset() * 60000
    // );
    // console.log("utc", utcDate.toJSON());

    // const dateString = "Fri Dec 29 2023 10:00:00";
    // const date = moment(dateString).format();

    if (start) {
      setMinStartDate(dateString);
      setStartDateAndtime(dateString);
    }
    if (end) {
      setEndDateAndtime(dateString);
    }
  };
  console.log("startDate", startDate);

  useEffect(() => {
    setValue(dayjs(defaultValue));
    if (startDate) {
      setMinDate(dayjs(startDate));
    }
    // if (defaultValue && startDate) {
    //   setMinDate(dayjs(defaultValue));
    // }
  }, [defaultValue, startDate]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker"]}>
        <DateTimePicker
          disablePast={!required}
          disabled={editDisabled}
          value={value}
          minDate={minStartDate}
          // value={start || end ? null : value}
          onChange={() => HandleDateAndTime}
          label={label}

          // InputProps={{ inputProps: { type: "datetime-local" } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
