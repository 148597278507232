import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect } from "react";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";

export default function DatePickerOrg({
  editDisable,
  date,
  type,
  setEndValue,
  setStartValue,
  startvalue,
  endvalue,
}) {
  const [value, setValue] = React.useState();
  const [startDate, setStartDate] = React.useState();

  const handleDate = async (data) => {
    setValue(data);
    if (type === "start") {
      setStartValue(data);
    }
    if (type === "end") {
      setEndValue(data);
    }
  };
  useEffect(() => {
    if (type === "start") {
      setValue(dayjs(startvalue));
    }
    if (type === "end") {
      setValue(dayjs(endvalue));
      setStartDate(dayjs(startvalue));
    }
  }, [startvalue, endvalue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        //   label="Uncontrolled picker"
        disabled={editDisable}
        value={value}
        minDate={startDate}
        onChange={(newValue) => handleDate(newValue)}
      />
      {/* <DatePicker
          label="Controlled picker"
          value={value}
          onChange={(newValue) => setValue(newValue)}
        /> */}
    </LocalizationProvider>
  );
}
