import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { getproductEditData, productUpdate } from "api/api";
import { useState } from "react";

import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  //   height: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  //   columnGap: "10px",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
}));
export default function ProductEditModal({
  setOpenModal,
  openModal,
  eventId,
  setUpdated,
  updated,
  openEditModal,
  setEditOpenModal,
}) {
  const [data, setData] = useState();
  const [imageChange, setChangeImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({
    id: "",
    image: "",
    model: "",
    productname: "",
  });

  const [image, setImage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setEventData({ ...eventData, image: file });
    setChangeImage(true);
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage("");
    }
  };

  const handleClose = () => {
    setEventData({
      id: "",
      productname: "",
      model: "",
      image: "",
    });
    setChangeImage(false);
    setEditOpenModal(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await productUpdate(eventData, imageChange);
      setUpdated(!updated);
      toast.success("Product updated");
      handleClose();
    } catch (error) {
      toast.error("Something went wrong please try again!");
    }
  };

  const getEventDetailsById = async (eventId) => {
    setLoading(true);
    await getproductEditData(eventId)
      .then((res) => {
        setData(res.data);
        setEventData({
          id: res.data.productId,
          image: res.data?.productImage,
          productname: res.data?.productName,
          model: res.data?.productModel,
        });
        setImage(res.data?.productImage);
      })
      .catch((err) => {});
    setLoading(false);
  };

  useEffect(() => {
    // getEventDetailsById(eventId);
  }, [openEditModal]);

  return (
    <>
      <Modal
        open={openEditModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          component="form"
          autoComplete="off"
          onSubmit={(e) => handleEditSubmit(e)}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              variant="h4"
              id="user-detail-modal"
              align="center"
              color={"orange"}
              onClick={() => handleClose()}
              style={{ cursor: "pointer", color: "red", width: "auto" }}
            >
              Close
            </Typography>
          </div>
          {!loading ? (
            <>
              <div
                style={{
                  width: "100%",
                  //   height: "50vh",
                  display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  padding: "20px",
                  // flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    //   border: "1px solid orange",
                  }}
                >
                  {image && (
                    <img
                      src={image}
                      alt="previmg"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    />
                  )}

                  <Button
                    component="label"
                    variant="contained"
                    color="success"
                    style={{ marginTop: "10px", color: "white" }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Image
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </Button>
                </div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    // display: "flex",
                    //   justifyContent: "center",
                    //   flexDirection: "column",
                    width: "70%",
                  }}
                >
                  <Grid item xs={12}>
                    <Item>
                      <TextField
                        id="outlined-basic"
                        label="Product Name"
                        variant="outlined"
                        required
                        value={eventData?.productname}
                        onChange={(e) =>
                          setEventData({
                            ...eventData,
                            productname: e.target.value,
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item>
                      <TextField
                        id="outlined-basic"
                        label="Product Model"
                        variant="outlined"
                        value={eventData?.model}
                        required
                        onChange={(e) =>
                          setEventData({
                            ...eventData,
                            model: e.target.value,
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Item>
                  </Grid>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      style={{
                        marginTop: "30px",
                        color: "white",
                        width: "200px",
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </Grid>
              </div>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}
