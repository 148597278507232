import { Pagination } from "@mui/material";
import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { get_reported_message_data } from "api/api";
import moment from "moment/moment";
import Loader from "ui-component/Loader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MessageModeration = () => {
  const [reportedMessage, setReportedMessageList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPage, setTotalPage] = useState(1);
  const [loader, setLoader] = useState(false);

  const handleChangePagination = (e, value) => {
    setPage(value);
  };

  const getFeaturevendorlist = async () => {
    setLoader(true);
    await get_reported_message_data(page, pageSize)
      .then((res) => {
        setLoader(false);
        setReportedMessageList(res.data.reportMessageList);
        setTotalPage(Math.ceil(res.data.total_count / 5));
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    // getFeaturevendorlist();
  }, [page]);

  return (
    <div>
      {loader === true && <Loader />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Message</StyledTableCell>
              <StyledTableCell align="center">Sender</StyledTableCell>
              <StyledTableCell align="center">Reported By</StyledTableCell>
              <StyledTableCell align="center"> Reported Date</StyledTableCell>
              <StyledTableCell align="center">Sender Email</StyledTableCell>
              <StyledTableCell align="center">Reported Email</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportedMessage?.map((row, id) => (
              <StyledTableRow key={id}>
                <StyledTableCell
                  style={{ maxWidth: "300px", wordBreak: "break-word" }}
                >
                  {row.messageContent}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {row.MessageSentUser.first_name}{" "}
                  {row.MessageSentUser.last_name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.MessageReportedUser.first_name}{" "}
                  {row.MessageReportedUser.last_name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(row.createdAt).format("DD/MM/YY")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.MessageSentUser.email}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.MessageReportedUser.email}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Pagination
          count={totalPage}
          color="primary"
          onChange={handleChangePagination}
        />
      </div>
    </div>
  );
};

export default MessageModeration;
