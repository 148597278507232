import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DatePickerValue from "./DatePicker";
import { Box, FormControl, MenuItem, Select, TextField } from "@mui/material";
import DatePickerOrg from "./EditDateTime";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function FaqInputFieldComponent({
  organizationData,
  setQuestion,
  setAnswer,
  setPriority,
  question,
  answer,
  priority,
  //   setOrgType,
  //   orgType,
  width,
  modalType,
}) {
  return (
    <>
      <Box
        sx={{
          width: width ? width : 500,
          maxWidth: "100%",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <FormControl fullWidth style={{ width: "100%" }}>
          <label
            style={{
              marginLeft: "5px",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            Question
          </label>
          <TextField
            fullWidth
            required
            label={"Question"}
            id="fullWidth"
            style={{ marginTop: "10px" }}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </FormControl>
        <FormControl fullWidth style={{ width: "100%" }}>
          <label
            style={{
              marginLeft: "5px",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            Answer
          </label>
          <TextField
            fullWidth
            label={"Answer"}
            id="fullWidth"
            required
            style={{ marginTop: "10px" }}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginTop: "20px", width: "100%" }}>
          <label
            htmlFor="demo-simple-select"
            style={{ marginLeft: "5px", fontSize: "15px", fontWeight: "bold" }}
          >
            Priority
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={priority}
            required
            style={{ marginTop: "10px", width: "100%" }}
            // disabled={editDisable ? true : false}
            onChange={(e) => setPriority(e.target.value)}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => (
              <MenuItem value={count}>{count}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
