import React from "react";
import * as XLSX from "xlsx";
import DataViewtable from "./DataViewtable";
import { Alert, Button } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";
import { useState } from "react";
import ProductTable from "./ProductTable";
import {
  getProductCategoryForProducer,
  importconsumerProduct,
  importconsumerProductValidation,
} from "api/api";
import { useEffect } from "react";
import ProductErrorViewModal from "./ProductErrorModal";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router";
import CosnumerProductViewModal from "./ProductViewModal";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export default function ProductImport({}) {
  const [missingHeadersData, setErrorheaderlist] = useState([]);
  const [categoryData, setCategory] = useState([]);

  const params = useParams();

  const [isAllValid, setIsAllvalid] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [open, setModalOpen] = useState(false);
  const [viewModalOpen, setModalOpenView] = useState(false);
  const [chunkSize, setchunkSize] = useState(10);

  const [rowData, setRowData] = useState([]);
  // error message
  const [errorMessage, setErrorMessage] = useState("");
  //
  // const [rowData, set] = useState([]);

  const [validData, setValidData] = useState([]);

  // loader
  const [loading, setLoading] = useState(false);

  const handleFileupload = (e) => {
    setLoading(true);
    setErrorheaderlist([]);
    setIsAllvalid(false);
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const parsedData = XLSX.utils.sheet_to_json(sheet);

      //

      let headers = [];
      let allvalidData = [];

      for (let key in sheet) {
        if (key[0] === "!") continue;
        // parse out the column, row, and value
        const row = parseInt(key.substring(1));
        const value = sheet[key].v;

        // Store the header values
        if (row === 1) {
          headers.push(value);
        }
      }

      const defaultHeaders = [
        "ProductCategory",
        "Location",
        "Manufacturer",
        "ProductName",
        "Version",
        "ModelNumber",
        "SerialNumber",
        "PurchaseDate",
        "WarrentyStartDate",
        "WarrentyEndDate",
        // "Active",
        "Status",
        "PurchasePriceType",
        "PurchasePrice",
        "AssetTagNumber",
        "ConsultantOrganization",
        "IntegratorOrgnization",
        "VendorOrganization",
      ];

      const missingHeaders = defaultHeaders.filter(
        (header) => !headers.includes(header)
      );

      if (missingHeaders.length === 0) {
        for (const data of parsedData) {
          let errorList = [];
          let error = false;
          let categoryId = "";

          for (let i = 0; i < categoryData.length; i++) {
            if (categoryData[i].name === data.ProductCategory) {
              categoryId = categoryData[i].id;
            }
          }
          if (!categoryId) {
            errorList.push("Category not matched");
            error = true;
          }
          if (data?.PurchasePrice) {
            if (typeof data.PurchasePrice === "string") {
              errorList.push("Purchase Price should be number");
              error = true;
            }
          }

          if (!data.Location) {
            errorList.push("Location required");
            error = true;
          }
          if (!data.Manufacturer) {
            errorList.push("Manufacturer required");
            error = true;
          }
          if (!data.ProductName) {
            errorList.push("Product Name required");
            error = true;
          }
          if (!data.ModelNumber) {
            // errorList.push("Model Number required");
            // error = true;
          }
          if (!data.SerialNumber) {
            // errorList.push("Serial Number required");
            // error = true;
          }
          if (
            data.Status === "Select" ||
            data.Status === "select" ||
            data.Status === "" ||
            data.Status === undefined
          ) {
            errorList.push("Status required");
            error = true;
          }

          const dateFormatter = (date) => {
            let parts = date?.split("-");
            let formattedDate = new Date(parts[2], parts[1] - 1, parts[0]);

            // let dates = new Date(date);
            let options = {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            };
            let formattedDatefinal = formattedDate.toISOString(
              "en-US",
              options
            );

            return formattedDatefinal;
          };

          function getRosterProductStatusByDisplayName(displayStatus) {
            const rosterProductStatus = {
              new: "New",
              inUse: "inUse",
              outForService: "outForService",
              inStorage: "inStorage",
              removed: "removed",
            };

            if (displayStatus === "New") {
              return rosterProductStatus.new;
            } else if (displayStatus === "In Use") {
              return rosterProductStatus.inUse;
            } else if (displayStatus === "Out for Service") {
              return rosterProductStatus.outForService;
            } else if (displayStatus === "In Storage") {
              return rosterProductStatus.inStorage;
            } else if (displayStatus === "Removed") {
              return rosterProductStatus.removed;
            } else {
              return rosterProductStatus.new;
            }
          }

          allvalidData.push({
            categoryId: categoryId,
            category: data.ProductCategory,
            location: data.Location?.trim(),
            manufacturerOrganization: data.Manufacturer,
            productName: data.ProductName,
            productType: data.ProductType,
            version: data.Version,
            modelNumber: data.ModelNumber,
            serialNumber: data.SerialNumber,
            purchaseDate: dateFormatter(data.PurchaseDate),
            warrantyStartDate: dateFormatter(data.WarrentyStartDate),
            warrantyEndDate: dateFormatter(data.WarrentyEndDate),
            // active:
            //   data.Active === "Select"
            //     ? false
            //     : data.Active === "Yes"
            //     ? true
            //     : false,
            status: data.Status,
            purchasePriceType:
              data.PurchasePriceType === "Select" ? "" : data.PurchasePriceType,
            purchasePrice: data.PurchasePrice,
            assetId: data.AssetTagNumber,
            consultantOrganization: data.ConsultantOrganization,
            integratorOrganization: data.IntegratorOrgnization,
            vendorOrganization: data.VendorOrganization,
            errorList: errorList,
            error: error,
          });
        }

        const checkAllvalid = allvalidData?.filter(
          (data) => data.error === true
        );
        if (checkAllvalid.length === 0) {
          setIsAllvalid(true);
        }

        setValidData(allvalidData);
      } else {
        setErrorheaderlist(missingHeaders);
      }
    };
    setLoading(false);
  };

  const fileSubmit = async (chunkSize) => {
    // console.log(validData);
    // return;
    setSubmit(true);
    // setProgress(0);

    const totalChunks = Math.ceil(validData?.length / chunkSize);
    const responses = [];

    let chunksSent = 0; // Variable to track the number of chunks sent successfully

    // Split data into chunks and send each chunk to the server
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = (i + 1) * chunkSize;
      const chunk = validData.slice(start, end);

      try {
        // Send chunk to the server using Axios
        const response = await importconsumerProduct(params.id, chunk);
        responses.push(response.data);
      } catch (error) {
        toast.error(error);
        // Handle error if request fails
        // console.error("Error sending chunk:", error.message);
        // responses.push({ error: error.message });
      }
    }

    if (responses.length > 0) {
      toast.success("Consumer Product Import Success");
    } else {
      toast.error("Consumer Product Import failed please try again.");
    }

    setSubmit(false);
    // setResponse(responses.flat(1));
    return responses;
  };

  const handlevalidation = async () => {
    try {
      const response = await importconsumerProductValidation(
        params.id,
        validData
      );
      fileSubmit(chunkSize);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  const getProductCategory = async () => {
    try {
      const response = await getProductCategoryForProducer();

      let categoryData = [];
      for (const category of response.data) {
        categoryData.push({
          name: category.name,
          id: category.id,
        });
      }
      setCategory(categoryData);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getProductCategory();
  }, [!categoryData]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          padding: "30px",
          rowGap: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            rowGap: "10px",
            columnGap: "10px",
          }}
        >
          {validData?.length === 0 || !isAllValid ? (
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              style={{ width: "20%" }}
              color="info"
            >
              Upload Product file
              <VisuallyHiddenInput
                type="file"
                accept=".xls,.xlsx,.csv"
                onChange={handleFileupload}
              />
            </Button>
          ) : !submit && isAllValid ? (
            <>
              <Button
                component="label1"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<RestartAltIcon />}
                style={{ width: "20%" }}
                color="error"
                onClick={() => {
                  setValidData([]);
                  setIsAllvalid(false);
                  setErrorMessage("");
                }}
              >
                Reset
              </Button>
              {!errorMessage && (
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  style={{ width: "20%" }}
                  color="success"
                  onClick={() => handlevalidation()}
                >
                  Submit
                </Button>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        {errorMessage && (
          <div style={{ marginTop: "10px" }}>
            <Alert severity="error">{errorMessage}</Alert>
          </div>
        )}

        {missingHeadersData.length > 0 && (
          <div style={{ marginTop: "10px" }}>
            <Alert severity="error">
              Headers missing{" "}
              {missingHeadersData?.map((error) => (
                <strong>{error} ,</strong>
              ))}
              please correct and submit again.
            </Alert>
          </div>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        {/* <h4>Total : {rawDataCount}</h4>
            <div style={{ display: "flex", width: "40%", columnGap: "10px" }}>
              {duplicateData?.length > 0 && (
                <>
                  <h4 style={{ color: "#ff6666" }}>
                    Already Exists : {duplicateData?.length}
                  </h4>
                  <h4 style={{ color: "#3fec3f" }}>
                    New : {rawDataCount - duplicateData?.length}
                  </h4>
                </>
              )}
            </div> */}
      </div>

      <ProductTable
        data={validData}
        setRowData={setRowData}
        setModalOpen={setModalOpen}
        setModalOpenView={setModalOpenView}
        loading={loading}
      />

      <ProductErrorViewModal
        open={open}
        setOpen={setModalOpen}
        singleProductData={rowData}
      />
      <CosnumerProductViewModal
        modalOpen={viewModalOpen}
        setModalopen={setModalOpenView}
        singleData={rowData}
      />
    </div>
  );
}
