import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const OrganizationCreate = () => {
  const [activeUser, setActiveUser] = useState(
    "Create Organization with 0 User"
  );

  const data = [
    "Create Organization with 0 User",
    "Create Organization with 1 User",
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "50px",
        }}
      >
        {data.map((ele, idx) => (
          <Box
            key={idx}
            sx={{
              color: activeUser === ele ? "blue" : "gray",
              borderBottom: activeUser === ele ? "3px solid blue" : "",
              fontSize: "18px",
              fontFamily: "roboto",
              paddingBottom: "8px",
              cursor: "pointer",
            }}
            onClick={() => setActiveUser(ele)}
          >
            {ele}
          </Box>
        ))}
      </Box>
      {activeUser === "Create Organization with 0 User" ||
      activeUser === "Create Organization with 1 User" ? (
        <form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="fName"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                First Name*
              </FormLabel>
              <TextField
                id="fName"
                variant="outlined"
                sx={{ marginBottom: "15px" }}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="lName"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Last Name*
              </FormLabel>
              <TextField
                id="lName"
                variant="outlined"
                sx={{ marginBottom: "15px" }}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="number"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Mobile Number*
              </FormLabel>
              <TextField
                id="number"
                variant="outlined"
                sx={{ marginBottom: "15px" }}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="place"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Native Place*
              </FormLabel>
              <TextField
                id="place"
                variant="outlined"
                sx={{ marginBottom: "15px" }}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="org"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Select your Organization*
              </FormLabel>
              <Select
                sx={{ marginBottom: "15px" }}
                labelId="org"
                id="catgry"
                value={10}
                label="aaaaaa"
                // onChange={handleChange}
              >
                <MenuItem value={10}>ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="ort"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Select your Organization Type*
              </FormLabel>
              <Select
                sx={{ marginBottom: "15px" }}
                labelId="ort"
                id="catgry"
                value={10}
                label="aaaaaa"
                // onChange={handleChange}
              >
                <MenuItem value={10}>ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="job"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Job Title*
              </FormLabel>
              <TextField
                id="job"
                variant="outlined"
                sx={{ marginBottom: "15px" }}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="email"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Email*
              </FormLabel>
              <TextField
                id="email"
                variant="outlined"
                sx={{ marginBottom: "15px" }}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="dist"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                District Type*
              </FormLabel>
              <Select
                sx={{ marginBottom: "15px" }}
                labelId="dist"
                id="catgry"
                value={10}
                label="aaaaaa"
                // onChange={handleChange}
              >
                <MenuItem value={10}>ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "49%" }}
            >
              <FormLabel
                htmlFor="tz"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "roboto",
                  marginBottom: "10px",
                }}
              >
                Time Zone*
              </FormLabel>
              <Select
                sx={{ marginBottom: "15px" }}
                labelId="tz"
                id="catgry"
                value={10}
                label="aaaaaa"
                // onChange={handleChange}
              >
                <MenuItem value={10}>ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Box>
          </Box>
        </form>
      ) : (
        ""
      )}
    </>
  );
};

export default OrganizationCreate;
