import * as React from "react";
import Box from "@mui/material/Box";
import { Alert, FormControl, TextField } from "@mui/material";
import {
  check_connection,
  disconnect_connection,
  search_organization_data,
  update_connection,
} from "api/api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ConnectionModerationConnectionListShown from "./ConnectionListShow";
import ListConnectionModeration from "ui-component/List";
import Loader from "ui-component/Loader";
import ConnectionListPage from "./ConnectionListPage";

export default function ConnectionModeration() {
  const [organizationIdOne, setOrganizationIdOne] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [error, setError] = React.useState("");
  const [InvalidError, setInvalidError] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [dataOne, setDataOne] = React.useState([]);
  const [secondData, setSecondData] = React.useState([]);
  const [searchValueOne, setSearchValueOne] = React.useState("");
  const [searchValueTwo, setSearchValueTwo] = React.useState("");
  const [selectValueOne, setSelectValueOne] = React.useState("");
  const [selectValueTwo, setSelectValueTwo] = React.useState("");

  const [labelOne, setLabelOne] = React.useState("");
  const [labelTwo, setLabelTwo] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [page, setPage] = React.useState(1);

  const handleCheckConnection = async () => {
    if (selectValueOne && selectValueTwo) {
      setLoader(true);
      await check_connection(
        selectValueOne.organizationId,
        selectValueTwo.organizationId
      )
        .then((res) => {
          setLoader(false);

          setStatus(res.data);

          setError(res.data.status);
        })
        .catch((err) => {
          setLoader(false);
          if (err.response.data.statusCode === 400) {
            setInvalidError(err.response.data.message);
          }
        });
    }
  };

  const handleConnect = async (orgIdOne, orgIdTwo) => {
    setLoading(true);
    await update_connection(orgIdOne, orgIdTwo)
      .then((res) => {
        setLoading(false);
        handleCheckConnection();
        toast.success("Connected", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleDisconnect = async () => {
    setLoading(true);
    await disconnect_connection(
      selectValueOne.organizationId,
      selectValueTwo.organizationId
    )
      .then((res) => {
        // setLoading(false);
        handleCheckConnection();
        toast.success("Disconnected", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {});
    setLoading(false);
  };

  const handleSearchOrganizationOne = async (search_value) => {
    setLoading(true);
    await search_organization_data(search_value)
      .then((res) => {
        setLoading(false);
        setDataOne(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleSearchOrganizationTwo = async (search_value) => {
    setLoading(true);
    await search_organization_data(search_value)
      .then((res) => {
        setLoading(false);
        setSecondData(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValueOne.length > 0) {
        handleSearchOrganizationOne(searchValueOne);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValueOne]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValueTwo.length > 0) {
        handleSearchOrganizationTwo(searchValueTwo);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValueTwo]);

  // useEffect(() => {
  //   getUserEmail();
  // }, []);

  useEffect(() => {
    handleCheckConnection();
  }, [selectValueOne, selectValueTwo]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100%",
        // backgroundColor: "lightcoral",
        flexDirection: "column",
      }}
    >
      {loading && <Loader />}
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <div style={{ marginBottom: "30px" }}>
          {/* {error === "CANNOT_CONNECT" && (
            <Alert severity="error">
              {"Cannot connect this both organization."}
            </Alert>
          )} */}
          {InvalidError && <Alert severity="error">{InvalidError}</Alert>}
          {/* {status === "ALREADY_CONNECTED" && (
            <Alert severity="info">Already connected</Alert>
          )} */}
          {/* {status === "CAN_CONNECT" && (
            <Alert severity="info">
              {"Click on connect button to connect"}
            </Alert>
          )} */}
        </div>
        {/*  */}
        <Box
          sx={{
            // maxWidth: 200,
            minWidth: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <FormControl style={{ width: "45%" }}>
            <TextField
              id="outlined-basic"
              label="Search by Organization Name"
              variant="outlined"
              value={labelOne}
              onChange={(e) => {
                setStatus("");
                setSelectValueOne("");
                setLabelOne(e.target.value);
                setSearchValueOne(e.target.value);
              }}
            />
            {dataOne.length > 0 && !selectValueOne && (
              <ListConnectionModeration
                type="first"
                data={dataOne}
                setLabelOne={setLabelOne}
                clearData={setDataOne}
                setSelectValueOne={setSelectValueOne}
                // loading={loading}
                // setDataOne={setDataOne}
                // setSelectValueOne={setSelectValueOne}
                // selectValueOne={selectValueOne}
                // setLabelOne={setLabelOne}
                // //
                // setValue={setValue}
                // setType={setType}
                // setSearchValue={setSearchValueOne}
                // handleCheckConnection={handleCheckConnection}
              />
            )}
          </FormControl>

          <FormControl style={{ width: "45%", position: "relative" }}>
            <TextField
              id="outlined-basic"
              label="Search by Organization Name"
              variant="outlined"
              value={labelTwo}
              onChange={(e) => {
                setStatus("");
                setSelectValueTwo("");
                setLabelTwo(e.target.value);
                setSearchValueTwo(e.target.value);
              }}
            />
            {secondData.length > 0 && !selectValueTwo && (
              <ListConnectionModeration
                loading={loading}
                data={secondData}
                setLabelTwo={setLabelTwo}
                clearData={setSecondData}
                setSelectValueTwo={setSelectValueTwo}
                // setLabelTwo={setLabelTwo}
                // selectValueTwo={selectValueTwo}

                // setSelectValueTwo={setSelectValueTwo}
                // setSecondData={setSecondData}
                // setSecondValue={setSecondValue}
                // setSecondSelectValue={setSecondSelectValue}
                // setType={setType}
                // setValueSecond={setValueSecond}
                // handleCheckConnection={handleCheckConnection}
                type="second"
              />
            )}
          </FormControl>
        </Box>
      </Box>
      {!InvalidError && selectValueOne && selectValueTwo && (
        <ConnectionListPage
          orgOne={selectValueOne}
          OrgTwo={selectValueTwo}
          searchValueOne={searchValueOne}
          searchValueTwo={searchValueTwo}
        />
      )}

      {status && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            flexDirection: "column",
            marginTop: "40px",
          }}
        >
          {status.code === 1 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "50%",
                marginBottom: "40px",
              }}
            >
              <div style={{ width: "60%", color: "#2EB94DF2" }}>
                <span>
                  Yes, You can Establish Connection between these two
                  organizations.
                </span>
              </div>

              <div
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <button
                  style={{
                    padding: "8px 13px",
                    border: "none",
                    backgroundColor: "rgb(255, 102, 0)",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  disabled={loading === true ? true : false}
                  onClick={() => {
                    handleConnect(
                      selectValueOne?.organizationId,
                      selectValueTwo?.organizationId
                    );
                  }}
                >
                  connect
                </button>
              </div>
            </div>
          )}

          {status.code === 2 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div style={{ width: "60%", color: "#2EB94DF2" }}>
                <span>
                  Already Connected these both organizations, click on
                  Disconnect to disconnect connection.
                </span>
              </div>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <button
                  style={{
                    padding: "8px 13px",
                    border: "none",
                    backgroundColor: "red",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleDisconnect(
                      selectValueOne?.organizationId,
                      selectValueTwo?.organizationId
                    )
                  }
                >
                  Disconnect
                </button>
              </div>
            </div>
          )}
          {status.code === 3 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginBottom: "40px",
                color: "#F24A25",
              }}
            >
              <span>{status.message}</span>
            </div>
          )}
          {status.code === 4 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginBottom: "40px",
                color: "#F24A25",
              }}
            >
              <span>{status.message}</span>
            </div>
          )}
          {status.code === 5 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginBottom: "40px",
                color: "#F24A25",
              }}
            >
              <span>{status.message}</span>
            </div>
          )}
        </div>
      )}
      {organizationIdOne && (
        <div style={{ width: "100%", height: "85%" }}>
          <ConnectionModerationConnectionListShown
            organizationId={organizationIdOne}
            page={page}
            setPage={setPage}
          />
        </div>
      )}
    </div>
  );
}
