import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { Avatar, Button, Skeleton } from "@mui/material";

import { useState } from "react";
import ConnectionList from "ui-component/ConnectionsList";
const ConnectionListPage = ({
  orgOne,
  OrgTwo,
  searchValueOne,
  searchValueTwo,
}) => {
  const [modalOne, setConnectionModalOne] = useState(false);
  const [modalTwo, setConnectionModal] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-around",
        marginTop: "5%",
        // height: "100%",
      }}
    >
      {/* org one section */}

      <div
        style={{
          width: "45%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "20px 5px",
          border: "2px solid white",
          height: "100%",
        }}
      >
        {orgOne ? (
          <>
            <div style={{ width: "85%" }}>
              <List sx={{ width: "100%" }}>
                <ListItem
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", color: "#F24A25" }}>
                    {"Email"}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      fontSize: "10px",
                      columnGap: "10px",
                      // wordBreak: "break-word",
                      position: "relative",
                    }}
                  >
                    <ListItemText
                      primary={orgOne?.email}
                      style={{ marginLeft: "10px", wordBreak: "break-all" }}
                    />
                    <div
                      style={
                        {
                          // position: "absolute",
                          // top: "0px",
                          // right: "-30px",
                        }
                      }
                    >
                      {orgOne?.verified === true ? (
                        <span style={{ color: "green" }}>verified</span>
                      ) : (
                        <span style={{ color: "red" }}>unverified</span>
                      )}
                    </div>
                  </div>
                </ListItem>
                <ListItem
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", color: "#F24A25" }}>{"Role"}</div>
                  <div style={{ width: "100%" }}>
                    <ListItemText
                      primary={orgOne?.role}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", color: "#F24A25" }}>
                    {"Org Name"}
                  </div>
                  <div style={{ width: "100%" }}>
                    <ListItemText
                      primary={orgOne?.organizationName}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", color: "#F24A25" }}>{"Type"}</div>
                  <div style={{ width: "100%" }}>
                    <ListItemText
                      primary={orgOne?.type}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </ListItem>
              </List>
            </div>
            <div
              style={{
                width: "25%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // border: "1px solid blue",
                height: "100%",
              }}
            >
              {/* <span>Verified</span> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {orgOne?.image ? (
                  <img src={orgOne?.image} alt="org" style={{ width: "90%" }} />
                ) : (
                  <Avatar sizes="Large" />
                )}
                <div style={{ marginTop: "20px" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ fontSize: "12px", width: "80px" }}
                    onClick={() => setConnectionModalOne(true)}
                  >
                    Connections
                  </Button>
                </div>
              </div>
            </div>
            <ConnectionList
              connectionsModal={modalOne}
              setConnectionModal={setConnectionModalOne}
              organizationId={orgOne?.organizationId}
              random={Math.random()}
            />
          </>
        ) : (
          <Skeleton animation="wave" />
        )}
      </div>

      {/* org two section */}

      <div
        style={{
          width: "45%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "20px 5px",
          border: "2px solid white",
          height: "100%",
        }}
      >
        {OrgTwo ? (
          <>
            <div style={{ width: "85%" }}>
              <List sx={{ width: "100%" }}>
                <ListItem
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", color: "#F24A25" }}>
                    {"Email"}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      fontSize: "10px",
                      columnGap: "10px",
                    }}
                  >
                    <ListItemText
                      primary={OrgTwo?.email}
                      style={{ marginLeft: "10px", wordBreak: "break-all" }}
                    />
                    <div>
                      {OrgTwo?.verified === true ? (
                        <span style={{ color: "green" }}>verified</span>
                      ) : (
                        <span style={{ color: "red" }}>unverified</span>
                      )}
                    </div>
                  </div>
                </ListItem>
                <ListItem
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", color: "#F24A25" }}>{"Role"}</div>
                  <div style={{ width: "100%" }}>
                    <ListItemText
                      primary={OrgTwo?.role}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", color: "#F24A25" }}>
                    {"Org Name"}
                  </div>
                  <div style={{ width: "100%" }}>
                    <ListItemText
                      primary={OrgTwo?.organizationName}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </ListItem>
                <ListItem
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "30%", color: "#F24A25" }}>{"Type"}</div>
                  <div style={{ width: "100%" }}>
                    <ListItemText
                      primary={OrgTwo?.type}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </ListItem>
              </List>
            </div>
            <div
              style={{
                width: "25%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {OrgTwo?.image ? (
                  <img src={OrgTwo?.image} alt="org" style={{ width: "90%" }} />
                ) : (
                  <Avatar
                    sizes="Large"
                    style={{ width: "90%", height: "80px" }}
                  />
                )}
                <div style={{ marginTop: "20px" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ fontSize: "12px", width: "80px" }}
                    onClick={() => setConnectionModal(true)}
                  >
                    Connections
                  </Button>
                </div>
              </div>
            </div>
            <ConnectionList
              connectionsModal={modalTwo}
              setConnectionModal={setConnectionModal}
              organizationId={OrgTwo?.organizationId}
              random={Math.random()}
            />
          </>
        ) : (
          <Skeleton animation="wave" />
        )}
        {/*  */}
      </div>
    </div>
  );
};

export default ConnectionListPage;
