import { Autocomplete, TextField } from "@mui/material";
import { getproduct, getproductlookupData } from "api/api";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";

const headers = [
  { label: "Event Name", key: "eventName" },
  { label: "Event Category", key: "eventCategory" },
  { label: "Event Description", key: "eventDescription" },
  { label: "Start Date", key: "startDateTime" },
  { label: "End Date", key: "endDateTime" },
  { label: "Timezone", key: "eventTimeZone" },
  { label: "Event Privacy", key: "eventPrivacy" },
  { label: "Event Mode", key: "eventMode" },
];

export default function ProductTable({
  setOpenModal,
  setEventId,
  setEditOpenModal,
  setDeleteOpenModal,
  updated,
}) {
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);

  const [pending, setPending] = React.useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [searcgOption, setSearchOption] = useState([]);
  const [searcValueByuser, setSearchValueByuser] = useState("");

  const SearchHandle = async (e) => {
    if (e !== 0) {
      await getproductlookupData(e)
        .then((res) => {
          setSearchOption(res.data);
        })
        .catch((err) => {});
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const HandleApplyFilter = async () => {
    const findFlag = searcgOption?.find((f) => f.name === searcValueByuser);

    try {
      setPending(true);
      const res = await getproduct(findFlag, perPage, page);
      setData(res.data.data);
      setTotalRows(res.data.counter);
    } catch (err) {
    } finally {
      setPending(false);
    }
  };

  //   const subHeaderComponentMemo = React.useMemo(() => {
  //     const handleClear = () => {
  //       if (filterText) {
  //         setResetPaginationToggle(!resetPaginationToggle);
  //         setFilterText("");
  //       }
  //     };

  //     return (
  //       <FilterComponent
  //         onFilter={(e) => setFilterText(e.target.value)}
  //         onClear={handleClear}
  //         filterText={filterText}
  //       />
  //     );
  //   }, [filterText, resetPaginationToggle]);
  const subHeaderComponentMemo = React.useMemo(() => {
    // const handleClear = () => {
    //   if (filterText) {
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     setFilterText("");
    //   }
    // };

    return (
      <div
        style={
          {
            //   width: "100%",
            //   display: "flex",
            //   //   justifyContent: "space-between",
            //   alignItems: "center",
          }
        }
      >
        <div
          style={{ display: "flex", columnGap: "30px", alignItems: "center" }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={searcgOption}
            getOptionLabel={(option) => option.name}
            onInputChange={(e) => {
              SearchHandle(e.target.value);
            }}
            onChange={(e) => {
              setSearchValueByuser(e.target.outerText);
            }}
            // sx={{ width: 400 }}
            style={{
              display: "flex",
              width: "400px",
              justifyContent: "flex-start",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by product name, category & organization name "
              />
            )}
          />

          <Button
            variant="contained"
            // onClick={(searcValueByuser) => HandleApplyFilter()}
            style={{ backgroundColor: "rgb(255, 102, 0)", color: "white" }}
          >
            Search
          </Button>
        </div>
      </div>
    );
  }, [SearchHandle, searcValueByuser]);

  const handleDateTime = (value) => {
    // const date = new Date(value);
    // const date = moment.tz(value, moment.tz.guess());

    const date = moment(value);
    const formattedDate = date.utc().format("DD-MMM-YY HH:mm");

    return formattedDate;
    // return moment(date).format("DD-MMM-YY HH:mm");
  };

  const actionsMemo = React.useMemo(() => {
    return (
      <CSVLink data={data} headers={headers} filename={"events"}>
        <Button
          variant="outlined"
          style={{
            padding: "5px 20px",
            // border: "none",
            backgroundColor: "",
          }}
          // disabled={
          //   loader ? true : exportData.length > 0 ? false : true
          // }
        >
          Export Events
        </Button>
      </CSVLink>
    );
  }, []);

  const ImageShow = (url) => {
    return (
      <div style={{ padding: "5px" }}>
        <img
          src={url}
          alt="product"
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />
      </div>
    );
  };

  useEffect(() => {
    setColumns([
      {
        name: "Product image",
        selector: (row) => ImageShow(row.productImage),
        sortable: true,
      },
      {
        name: "Product name",
        selector: (row) => row.productName,
        sortable: true,
      },

      {
        name: "Category",
        selector: (row) => row.productCategory,
        sortable: true,
      },

      {
        name: "Created",
        selector: (row) => handleDateTime(row.createdAt),
        sortable: true,
      },

      {
        name: "Model",
        selector: (row) => row.productModel,
        sortable: true,
      },

      {
        name: "View",
        cell: (row) => (
          <Button
            variant="outlined"
            onClick={() => {
              setOpenModal(true);
              setEventId(row.productId);
            }}
          >
            View
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: "Edit",
        cell: (row) => (
          <Button
            variant="contained"
            onClick={() => {
              setEditOpenModal(true);
              setEventId(row.productId);
            }}
          >
            Edit
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: "Delete",
        cell: (row) => (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setDeleteOpenModal(true);
              setEventId(row.productId);
            }}
          >
            Delete
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    // HandleApplyFilter();
  }, [page, perPage, updated]);

  return (
    <>
      <DataTable
        title={<p>{"Product Management (In Progress)"}</p>}
        columns={columns}
        data={data}
        progressPending={pending}
        pagination
        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        fixedHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover
        // actions={actionsMemo}
      />
    </>
  );
}
