import React from "react";
import { useState } from "react";
import UnverifiedProducerTable from "ui-component/UnverifiedProducer/Table";
import UnverifiedViewModal from "ui-component/UnverifiedProducer/UnverifiedViewModal";
import VerifyAccept from "ui-component/UnverifiedProducer/VerifyAccept";

export default function UnverifiedProducerIndex() {
  const [openModal, setOpenModal] = useState(false);
  const [unverifiedId, setUnverifiedId] = useState();
  const [updated, setUpdated] = useState(false);
  const [openVerifyModal, setVerifyModal] = useState(false);
  return (
    <div>
      <UnverifiedProducerTable
        openModal={openModal}
        setOpenModal={setOpenModal}
        updated={updated}
        setUnverifiedId={setUnverifiedId}
        setVerifyModal={setVerifyModal}
      />

      <UnverifiedViewModal
        unverifiedId={unverifiedId}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <VerifyAccept
        openVerifyModal={openVerifyModal}
        setVerifyModal={setVerifyModal}
        id={unverifiedId}
        updated={updated}
        setUpdated={setUpdated}
      />
    </div>
  );
}
