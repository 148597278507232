import React from "react";
import { useState } from "react";
import ImsEditModal from "ui-component/imsFlow/EditImsModal";
import ImsViewModal from "ui-component/imsFlow/ImsVeiwDataModal";
import ImsTable from "ui-component/imsFlow/table";

export default function ImsFlow() {
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [update, setUpdated] = useState(false);
  const [id, setEventId] = useState();

  return (
    <div>
      <ImsTable
        setOpenModal={setViewModal}
        openModal={viewModal}
        setEventId={setEventId}
        setEditModal={setEditModal}
        setUpdated={setUpdated}
        update={update}
      />

      <ImsViewModal
        setOpenModal={setViewModal}
        openModal={viewModal}
        eventId={id}
      />

      <ImsEditModal
        setOpenModal={setEditModal}
        openModal={editModal}
        eventId={id}
        setUpdated={setUpdated}
        update={update}
      />
    </div>
  );
}
