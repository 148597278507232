import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { productDelete } from "api/api";
import { toast } from "react-toastify";

export default function ProductDeleteDialog({
  openDeleteModal,
  setDeleteOpenModal,
  eventId,
  setUpdated,
  updated,
}) {
  const handleClose = () => {
    setDeleteOpenModal(false);
  };
  const handleDelete = async () => {
    // setDeleteOpenModal(false);
    try {
      await productDelete(eventId);
      toast.success("Product Deleted");
      setUpdated(!updated);
      handleClose();
    } catch (error) {
      toast.error("Please try again!");
    }
  };

  return (
    <div>
      <Dialog
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Product"}</DialogTitle>
        <DialogContent style={{ width: "400px" }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleDelete} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
