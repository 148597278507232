import React from "react";
import NewswirePostTable from "ui-component/NewsirePost/table";

const NewswirePostManage = () => {
  return (
    <div>
      <NewswirePostTable />
    </div>
  );
};

export default NewswirePostManage;
