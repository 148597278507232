import { export_data } from "api/api";
import React, { useEffect } from "react";
import { useState } from "react";
import Loader from "ui-component/Loader";
import CsvReaderData from "./import-2";
import OrgainzationImportTable from "ui-component/OrgainzationImport/Table";

const ExportImport = () => {
  const [High_School, setHigh_School] = useState(false);
  const [College, setCollege] = useState(false);
  const [Team, setTeam] = useState(false);
  const [Venue, setVenue] = useState(false);
  const [Consultant, setConsultant] = useState(false);
  const [Integrator, setIntegrator] = useState(false);
  const [Manufacturer, setManufacturer] = useState(false);
  const [Reseller, setReseller] = useState(false);
  const [client, setClient] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [loader, setLoader] = useState(false);

  const getExportData = async () => {
    setLoader(true);
    await export_data(
      client,
      vendor,
      High_School,
      College,
      Team,
      Venue,
      Consultant,
      Integrator,
      Manufacturer,
      Reseller
    )
      .then((res) => {
        setLoader(false);
        setExportData(res.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    // getExportData();
  }, [
    client,
    vendor,
    High_School,
    College,
    Team,
    Venue,
    Consultant,
    Integrator,
    Manufacturer,
    Reseller,
  ]);

  return (
    <>
      <div
        style={{
          width: "100%",
          // height: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {loader === true && <Loader />}

        <div
          style={{
            width: "100%",
            //   marginTop: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            // backgroundColor: "aliceblue",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <h2 style={{ fontFamily: "Roboto,sans-serif", fontWeight: "bold" }}>
            Import Organization
          </h2>
          <div style={{ marginBottom: "20px", width: "100%" }}>
            <CsvReaderData />
          </div>
        </div>
      </div>
      <OrgainzationImportTable />
    </>
  );
};

export default ExportImport;
