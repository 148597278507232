import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Divider, List, ListItem, ListItemText } from "@mui/material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { BASE_URL } from "utils/constant";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageUploadModal({
  openImageupload,
  setOpenImageUpload,
  imageuploadResponse,
  setImageuploadResponse,
  clearData,
  setSubmit,
  submit,
}) {
  const [imageList, setImageList] = useState([]);
  const [acceptedImage, setAcceptedfile] = useState(0);
  const [rejectedImage, setRejectedfile] = useState(0);

  const [progresses, setProgresses] = useState([]);
  const [completedCount, setCompletedCount] = useState(0);

  // dropzone

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    // Do something with the acceptedFiles
    console.log(acceptedFiles);
    setRejectedfile(fileRejections?.length);
    setAcceptedfile(acceptedFiles?.length);
    setImageList(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noKeyboard: true, // Disable keyboard interaction
    noClick: true, // Disable clicking to open file dialog
  });

  const handleFileChange = async () => {
    const totalFiles = imageList?.length;

    const imageUploadSuccessData = [];
    setSubmit(true);
    // Use Promise.all to await all fetch requests
    const res = await Promise.all(
      imageList.map(async (file, index) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const uploadResponse = await fetch(BASE_URL + "fileUpload/upload", {
            method: "POST",
            body: formData,
            // Enable uploading progress tracking
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              progresses[index] = progress; // Update progress for this index
              setProgresses([...progresses]); // Update the progress state
            },
          });
          if (uploadResponse.ok) {
            const data = await uploadResponse.json(); // Await the response.json() promise
            setCompletedCount((prevCount) => prevCount + 1);
            // console.log(
            //   `File ${index + 1}/${totalFiles} uploaded successfully`
            // );

            imageUploadSuccessData.push({
              fileName: file.name, // Use file.name instead of file.fileName
              url: data.filepath,
            });
          } else {
            console.error(`Upload of file ${index + 1}/${totalFiles} failed`);
          }
        } catch (error) {
          console.error(
            `Error uploading file ${index + 1}/${totalFiles}:`,
            error
          );
        }
      })
    );
    setImageuploadResponse(imageUploadSuccessData);
  };

  useEffect(() => {
    setAcceptedfile(0);
    setRejectedfile(0);
    setImageList([]);
  }, [clearData]);

  return (
    <React.Fragment>
      <Dialog
        open={openImageupload}
        TransitionComponent={Transition}
        // keepMounted
        onClose={() => {
          setAcceptedfile(0);
          setRejectedfile(0);
          setImageList([]);
          setOpenImageUpload(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Image upload"}</DialogTitle>
        <DialogContent style={{ width: "100%" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ width: "100%" }}
          >
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {imageList?.length < 1 ? (
                  <>
                    <div style={{ width: "50%" }}>
                      <div
                        {...getRootProps()}
                        style={{
                          border: "2px dashed #aaa",
                          padding: "20px",
                          textAlign: "center",
                        }}
                      >
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop Image folder here</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        style={{ width: "60%" }}
                        color="info"
                        onClick={() => handleFileChange()}
                      >
                        {submit
                          ? "uploading..."
                          : "Submit Image's & Product sheet"}
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div>
                <span style={{ color: "green" }}>Accepted Image :</span>
                {acceptedImage}
              </div>
              <div style={{ marginTop: "10px" }}>
                <span style={{ color: "red" }}>Rejected Image :</span>{" "}
                {rejectedImage}
              </div>
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setOpen(false)}>Disagree</Button> */}
          <Button
            onClick={() => {
              setAcceptedfile(0);
              setRejectedfile(0);
              setImageList([]);
              setOpenImageUpload(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
