import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import CategoryEditReducer from "./CategoryManagementEditReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  categoryEditData: CategoryEditReducer,
});

export default reducer;
