import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { fetchAllConsumerProduct } from "api/api";
import CosnumerProductViewModal from "./ProductViewModal";
import CosnumerProductViewModalForView from "./ProductviewModalForView";
import Loader from "ui-component/Loader";

const defaultHeaders = [
  "No",
  "Product Category",
  "Location",
  "Manufacturer",
  "Product Name",
  // "Version",
  // "Model Number",
  // "Serial Number",
  // "Purchase Date",
  // "Warrenty Start Date",
  // "Warrenty End Date",
  // "Active",
  // "Status",
  // "Purchase Price Type",
  // "Purchase Price",
  // "Asset-Tag Number",
  // "Consultant Organization",
  // "Integrator Orgnization",
  // "Vendor Organization",
  // "Valid/Error",
  "View product",
];

export default function ConsumerProductViewTable() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalcount, setTotalCount] = useState(0);
  const [consumerProductData, setConsumerProduct] = useState([]);
  const [modalOpen, setModalOpenForView] = useState(false);
  const [singleData, setSingleProductData] = useState([]);

  const params = useParams();

  const [loader, setLoader] = useState(false);

  const handleModalOpen = (rowdata) => {
    setSingleProductData(rowdata);
    setModalOpenForView(true);
  };

  const dateformatter = (date) => {
    let dates = new Date(date);
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let formattedDate = dates.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  const getConsumerProduct = async () => {
    setLoader(true);
    try {
      const response = await fetchAllConsumerProduct(
        params?.id,
        page - 1,
        pageSize
      );
      setConsumerProduct(response.data.consumerProductData);
      setTotalCount(Math.ceil(response.data.counter / pageSize));
    } catch (error) {}
    setLoader(false);
  };

  const handlePageChange = (event, pageData) => {
    setPage(pageData);
  };

  useEffect(() => {
    getConsumerProduct();
  }, [params?.id, page]);

  return (
    <>
      {loader && <Loader />}
      <>
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {defaultHeaders.map((header, id) => (
                  <TableCell key={id}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {consumerProductData?.map((row, id) => (
                <TableRow
                  key={id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {id + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.category?.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.parentlocation}/{row.location?.locationName}
                  </TableCell>
                  <TableCell align="center">{row.manufacturerName}</TableCell>
                  <TableCell align="center">{row.productName}</TableCell>

                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleModalOpen(row)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <Pagination
            count={totalcount}
            color="primary"
            onChange={handlePageChange}
          />
        </div>

        <CosnumerProductViewModalForView
          modalOpen={modalOpen}
          setModalopen={setModalOpenForView}
          singleData={singleData}
        />
      </>
    </>
  );
}
