import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Alert, Input, InputLabel, Skeleton } from "@mui/material";
import { useEffect } from "react";
import {
  fetchMessageSettingData,
  updateGlobalMessageInitiateLimitApi,
} from "api/api";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 400,
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 50,
  p: 4,
};

export default function SettingIndex() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(false);
  const [newLimitUpdateError, setNewLimitUpdateError] = useState(false);
  const [newLimitUpdateErrorMessage, setNewLimitUpdateErrorMessage] =
    useState("");

  const [limitCounter, setLimitCounter] = useState(0);
  const [newLimit, setNewLimit] = useState(0);

  async function fetchData() {
    setLoading(true);
    const result = await fetchMessageSettingData();
    setLimitCounter(result.data.defaultGlobalMessageInitiateCounter);
    setLoading(false);
  }

  async function updateGlobalMessageInitiateLimit(newLimit) {
    try {
      const result = await updateGlobalMessageInitiateLimitApi(newLimit);
      console.log(result.data);
      setLimitCounter(newLimit);
      setNewLimitUpdateError(false);
      handleClose();
      alert("Updated Successfully");
    } catch (error) {
      setNewLimitUpdateError(true);
      setNewLimitUpdateErrorMessage(error.response.data.message);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {}, [limitCounter]);

  if (loading) {
    return (
      <>
        <Skeleton variant="rounded" width={"25%"} height={100} />
      </>
    );
  }

  function handleSubmit() {
    const value = document.getElementById("newLimit").value;
    if (!value) {
      alert("Enter New Limit!");
      return;
    }
    if (!(value >= 0 && value <= 100)) {
      alert("New Limit should between 0 to 100");
      return;
    }
    updateGlobalMessageInitiateLimit(value);
  }

  return (
    <>
      <Box
        display={"flex"}
        gap={"15px"}
        flexDirection={"column"}
        width={"25%"}
        height={"auto"}
        padding={"10px"}
        bgcolor={"white"}
        borderRadius={"5px"}
      >
        <Box>
          <Typography variant="h5" color={"InfoText"} textAlign={"center"}>
            {" "}
            Default Global Message Initiate Counter -{" "}
            <span style={{ color: "blue" }}>{limitCounter}</span>
          </Typography>
        </Box>
        <Box>
          <Typography
            textAlign={"center"}
            onClick={handleOpen}
            style={{ color: "red", cursor: "pointer", background: "" }}
          >
            Update
          </Typography>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h2"
            color={"gray"}
            textAlign={"center"}
            component="h2"
          >
            Update Global Message Initiate Limit
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box
              textAlign={"center"}
              paddingY={"20px"}
              display={"flex"}
              justifyContent={"center"}
              gap={3}
              alignItems={"center"}
            >
              <InputLabel> Enter Limit:</InputLabel>
              <Input
                id="newLimit"
                placeholder="limit number like 5 or 10"
              ></Input>
            </Box>
            <Box textAlign={"center"}>
              <Button onClick={handleSubmit}>Submit</Button>
            </Box>
            {newLimitUpdateError ? (
              <Alert severity="error">{newLimitUpdateErrorMessage}</Alert>
            ) : (
              <></>
            )}
          </Box>
          <Box>
            <Typography textAlign={"center"} paddingY={"10px"}>
              Current Limit is {limitCounter} for Producer To Consumer Message
              Initiate.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
