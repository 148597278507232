import { SpeedDial } from "@mui/material";
import React from "react";
import { useState } from "react";
import ProductDeleteDialog from "ui-component/ProductManagement/ProductDelete";
import ProductEditModal from "ui-component/ProductManagement/ProductEditModal";
import ProductViewModal from "ui-component/ProductManagement/ProductViewModal";
import ProductTable from "ui-component/ProductManagement/table";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ProductCreate from "ui-component/ProductManagement/ProductCreateModal";

export default function ProductManagement() {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setEditOpenModal] = useState(false);
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [eventId, setEventId] = useState();

  const [openCreateModal, setCreateModal] = useState(false);

  return (
    <div>
      <ProductTable
        setOpenModal={setOpenModal}
        setEventId={setEventId}
        setEditOpenModal={setEditOpenModal}
        setDeleteOpenModal={setDeleteOpenModal}
        updated={updated}
        setUpdated={setUpdated}
      />
      <ProductViewModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        eventId={eventId}
      />
      <ProductEditModal
        openEditModal={openEditModal}
        setEditOpenModal={setEditOpenModal}
        eventId={eventId}
        updated={updated}
        setUpdated={setUpdated}
      />
      <ProductDeleteDialog
        openDeleteModal={openDeleteModal}
        setDeleteOpenModal={setDeleteOpenModal}
        eventId={eventId}
        updated={updated}
        setUpdated={setUpdated}
      />
      <ProductCreate
        openCreateModal={openCreateModal}
        setCreateModal={setCreateModal}
        eventId={eventId}
        updated={updated}
        setUpdated={setUpdated}
      />

      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{
          position: "absolute",
          bottom: "2%",
          right: "3%",
        }}
        icon={<AddOutlinedIcon size={"small"} />}
        onClick={() => alert("In progress")}
        // onClick={() => setCreateModal(true)}
      ></SpeedDial>
    </div>
  );
}
