import React from "react";
import ErrorLogsTable from "ui-component/ErrorLogs/Table";

export default function ErrorLogs() {
  return (
    <div>
      <ErrorLogsTable />
    </div>
  );
}
