import React from "react";
import DataViewtable from "./DataViewtable";
import { ToastContainer } from "react-toastify";
import { Box, styled } from "@mui/system";
import { Button, Tab, Tabs } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LocationImport from "./LocationImport";
import ProductImport from "./ProductImport";
import LocationViewtable from "./LocationView";
import ConsumerProductViewTable from "./viewProductTable";
import CosnumerProductViewModal from "./ProductViewModal";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function RosterImportcomponent() {
  const [submit, setSubmit] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const [data, setData] = React.useState([]);
  const [singleData, setSingleData] = React.useState([]);
  const [modalOpen, setModalopen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs value={value} onChange={handleChange} left>
            <Tab label="Import Location" />
            <Tab label="Import Product" />
            <Tab label="View Location" />
            <Tab label="View Product" />
          </Tabs>
        </Box>

        {value === 0 ? (
          <>
            <LocationImport setData={setData} />
          </>
        ) : value === 1 ? (
          <>
            <ProductImport />
          </>
        ) : value === 2 ? (
          <>
            <div style={{ marginTop: "10px" }}>
              <LocationViewtable />
            </div>
          </>
        ) : value === 3 ? (
          <>
            <ConsumerProductViewTable
              setModalOpen={setModalopen}
              setSingleData={setSingleData}
            />
          </>
        ) : (
          ""
        )}
        {/* <ProductViewModal
        open={open}
        setOpen={setOpen}
        singleProductData={singleProduct}
      /> */}

        <CosnumerProductViewModal
          modalOpen={modalOpen}
          setModalopen={setModalopen}
          singleData={singleData}
        />
      </div>
    </div>
  );
}
