import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DatePickerValue from "./DatePicker";
import { Box, FormControl, MenuItem, Select, TextField } from "@mui/material";
import DatePickerOrg from "./EditDateTime";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Featuretable({
  organizationData,
  editDisable,
  setEditDisable,
  setStartValue,
  startvalue,
  setEndValue,
  endvalue,
  setOrgType,
  orgType,
  width,
}) {
  console.log("organizationData", organizationData);
  return (
    <>
      {/* <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table"> */}
      {/* <TableHead>
          <TableRow>
            <StyledTableCell>Organization Name</StyledTableCell>
            <StyledTableCell align="center">Type</StyledTableCell>
            <StyledTableCell align="center">Start Date</StyledTableCell>
            <StyledTableCell align="center">End Date</StyledTableCell>
          </TableRow>
        </TableHead> */}
      {/* <TableBody
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StyledTableRow
            key={organizationData.organizationName}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <StyledTableCell component="th" scope="row">
              {organizationData.organizationName}
            </StyledTableCell>
            <StyledTableCell align="center">
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={orgType}
                  style={{ marginTop: "5px", width: "100%" }}
                  disabled={editDisable ? true : false}
                  onChange={(e) => setOrgType(e.target.value)}
                >
                  <MenuItem value={"featuredOrganization"}>
                    featuredOrganization
                  </MenuItem>
                  <MenuItem value={"categorySponsor"}>categorySponsor</MenuItem>
                </Select>
              </FormControl>
            </StyledTableCell>
            <StyledTableCell align="center">
              <DatePickerOrg
                editDisable={editDisable}
                date={organizationData?.startDate}
                type="start"
                startvalue={startvalue}
                setStartValue={setStartValue}
              />
            </StyledTableCell>
            <StyledTableCell align="center">
              <DatePickerOrg
                editDisable={editDisable}
                date={organizationData?.endDate}
                type="end"
                endvalue={endvalue}
                startvalue={startvalue}
                setEndValue={setEndValue}
              />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody> */}
      {/* </Table>
    </TableContainer> */}

      <Box
        sx={{
          width: width ? width : 500,
          maxWidth: "100%",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <FormControl fullWidth style={{ width: "100%" }}>
          <label
            // htmlFor="demo-simple-select"
            style={{
              marginLeft: "5px",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            Organization Name
          </label>
          <TextField
            fullWidth
            label={organizationData.organizationName}
            id="fullWidth"
            disabled
            style={{ marginTop: "10px" }}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginTop: "20px", width: "100%" }}>
          <label
            htmlFor="demo-simple-select"
            style={{ marginLeft: "5px", fontSize: "15px", fontWeight: "bold" }}
          >
            User Type
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={orgType}
            required
            style={{ marginTop: "10px", width: "100%" }}
            // disabled={editDisable ? true : false}
            onChange={(e) => setOrgType(e.target.value)}
          >
            <MenuItem value={"featuredOrganization"}>
              featuredOrganization
            </MenuItem>
            <MenuItem value={"categorySponsor"}>categorySponsor</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: "20px", width: "100%" }}>
          <label
            htmlFor="demo-simple-select"
            style={{ marginLeft: "5px", fontSize: "15px", fontWeight: "bold" }}
          >
            Start Date
          </label>
          <DatePickerOrg
            // editDisable={editDisable}
            date={organizationData?.startDate}
            type="start"
            startvalue={startvalue}
            setStartValue={setStartValue}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginTop: "20px", width: "100%" }}>
          <label
            htmlFor="demo-simple-select"
            style={{ marginLeft: "5px", fontSize: "15px", fontWeight: "bold" }}
          >
            End Date
          </label>
          <DatePickerOrg
            // editDisable={editDisable}
            date={organizationData?.endDate}
            type="end"
            endvalue={endvalue}
            startvalue={startvalue}
            setEndValue={setEndValue}
          />
        </FormControl>
      </Box>
    </>
  );
}
